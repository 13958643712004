<nz-card class="card-wrapper" nzTitle="编码器简单测试" >
    <table class="signal-table">
        <tbody>
            <tr>
                <th>轴</th>
                <th>顺时针</th>
                <th>逆时针</th>
                <th>信号</th>
            </tr>
            <tr>
                <td>0</td>
                <td [ngStyle]="{'color':faceItems[0].isClockwiseSignalWell?'green':'red'}">
                    {{faceItems[0].isClockwiseSignalWell?"良好":""}}</td>
                <td [ngStyle]="{'color':faceItems[0].isCounterSignalWell?'green':'red'}">
                    {{faceItems[0].isCounterSignalWell?"良好":""}}</td>
                <td
                    [ngStyle]="{'color':faceItems[0].isClockwiseSignalWell&&faceItems[0].isCounterSignalWell?'green':'red'}">
                    {{faceItems[0].isClockwiseSignalWell&&faceItems[0].isCounterSignalWell===true?"良好":(faceItems[0].isClockwiseSignalWell&&faceItems[0].isCounterSignalWell===false?"不良":'未检测')}}</td>
            </tr>
            <tr>
                <td>1</td>
                <td [ngStyle]="{'color':faceItems[1].isClockwiseSignalWell?'green':'red'}">
                    {{faceItems[1].isClockwiseSignalWell?"良好":""}}</td>
                <td [ngStyle]="{'color':faceItems[1].isCounterSignalWell?'green':'red'}">
                    {{faceItems[1].isCounterSignalWell?"良好":""}}</td>
                <td
                    [ngStyle]="{'color':faceItems[1].isClockwiseSignalWell&&faceItems[1].isCounterSignalWell?'green':'red'}">
                    {{faceItems[1].isClockwiseSignalWell&&faceItems[1].isCounterSignalWell===true?"良好":(faceItems[1].isClockwiseSignalWell&&faceItems[1].isCounterSignalWell===false?"不良":'未检测')}}</td>
            </tr>
            <tr>
                <td>2</td>
                <td [ngStyle]="{'color':faceItems[2].isClockwiseSignalWell?'green':'red'}">
                    {{faceItems[2].isClockwiseSignalWell?"良好":""}}</td>
                <td [ngStyle]="{'color':faceItems[2].isCounterSignalWell?'green':'red'}">
                    {{faceItems[2].isCounterSignalWell?"良好":""}}</td>
                <td
                    [ngStyle]="{'color':faceItems[2].isClockwiseSignalWell&&faceItems[2].isCounterSignalWell?'green':'red'}">
                    {{faceItems[2].isClockwiseSignalWell&&faceItems[2].isCounterSignalWell===true?"良好":(faceItems[2].isClockwiseSignalWell&&faceItems[2].isCounterSignalWell===false?"不良":'未检测')}}</td>
            </tr>
            <tr>
                <td>3</td>
                <td [ngStyle]="{'color':faceItems[3].isClockwiseSignalWell?'green':'red'}">
                    {{faceItems[3].isClockwiseSignalWell?"良好":""}}</td>
                <td [ngStyle]="{'color':faceItems[3].isCounterSignalWell?'green':'red'}">
                    {{faceItems[3].isCounterSignalWell?"良好":""}}</td>
                <td
                    [ngStyle]="{'color':faceItems[3].isClockwiseSignalWell&&faceItems[3].isCounterSignalWell?'green':'red'}">
                    {{faceItems[3].isClockwiseSignalWell&&faceItems[3].isCounterSignalWell===true?"良好":(faceItems[3].isClockwiseSignalWell&&faceItems[3].isCounterSignalWell===false?"不良":'未检测')}}</td>
            </tr>
            <tr>
                <td>4</td>
                <td [ngStyle]="{'color':faceItems[4].isClockwiseSignalWell?'green':'red'}">
                    {{faceItems[4].isClockwiseSignalWell?"良好":""}}</td>
                <td [ngStyle]="{'color':faceItems[4].isCounterSignalWell?'green':'red'}">
                    {{faceItems[4].isCounterSignalWell?"良好":""}}</td>
                <td
                    [ngStyle]="{'color':faceItems[4].isClockwiseSignalWell&&faceItems[4].isCounterSignalWell?'green':'red'}">
                    {{faceItems[4].isClockwiseSignalWell&&faceItems[4].isCounterSignalWell===true?"良好":(faceItems[4].isClockwiseSignalWell&&faceItems[4].isCounterSignalWell===false?"不良":'未检测')}}</td>
            </tr>
            <tr>
                <td>5</td>
                <td [ngStyle]="{'color':faceItems[5].isClockwiseSignalWell?'green':'red'}">
                    {{faceItems[5].isClockwiseSignalWell?"良好":""}}</td>
                <td [ngStyle]="{'color':faceItems[5].isCounterSignalWell?'green':'red'}">
                    {{faceItems[5].isCounterSignalWell?"良好":""}}</td>
                <td
                    [ngStyle]="{'color':faceItems[5].isClockwiseSignalWell&&faceItems[5].isCounterSignalWell?'green':'red'}">
                    {{faceItems[5].isClockwiseSignalWell&&faceItems[5].isCounterSignalWell===true?"良好":(faceItems[5].isClockwiseSignalWell&&faceItems[5].isCounterSignalWell===false?"不良":'未检测')}}</td>
            </tr>
        </tbody>
    </table>
    <div class="item" *ngIf='checkResult'>检查结果: <span class="ok">合格</span></div>
    <div class="item" *ngIf='checkResult===false'>检查结果: <span class="not-ok">不合格</span></div>
    <div class="item" *ngIf='checkResult===null'>检查结果: <span>未检测</span></div>
    <!-- <div class="end-line"><button nz-button nzType="primary" (click)="axisSignalService.endRotateCheck()"
            class="end-line-button">结束检测</button></div> -->
</nz-card>
<ng-template #startCheckBtn>
    <button nz-button nzType="primary" (click)="axisSignalService.startRotateCheck()">开始检测</button>
</ng-template>