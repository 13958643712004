import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConnectionService } from 'src/app/services/connection.service';
import { InspectionDynamicItem } from '../../class/inspection-dynamic-item';
import { InspRes } from '../../services/ble-inspection-item.service';

import { OperatorAttitudeService } from '../../services/operator-attitude.service'

@Component({
  selector: 'app-attitude-item-inspection',
  templateUrl: './attitude-item-inspection.component.html',
  styleUrls: ['./attitude-item-inspection.component.scss']
})
export class AttitudeItemInspectionComponent implements OnInit, OnDestroy {
  constructor(
    public operatorAttitudeService: OperatorAttitudeService,
    public connectionService: ConnectionService,
  ) { }
  // public attitudeItem: InspectionDynamicItem
  private readySubscription: Subscription

  ngOnInit() {
    this.readySubscription = this.operatorAttitudeService.getReadySubject().subscribe((ready) => {
      if (!ready) {
        this.operatorAttitudeService.clearInspectionItem()
      }else{
        this.operatorAttitudeService.initTodo()
      }
    })
  }
  public get attitudeItem() {
    return this.operatorAttitudeService.attitudeItem
  }

  ngOnDestroy() {
    this.operatorAttitudeService.destroyTodo()
    if(this.readySubscription) this.readySubscription.unsubscribe()
    
  }

}
