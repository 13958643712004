<div class="page-wrapper">
  <div class="header">
    <div class="ble-connect">
      <div class="connect-state" [ngClass]="{'red':!connected,'green':connected}"></div>
      MHC-<input nz-input [(ngModel)]="cubeId" style="width: 200px;color:black" />
      <button *ngIf="!connected" class="connect-btn" nz-button nzType="primary" (click)="onScanBtnClick()">搜索</button>
      <button *ngIf="connected" class="connect-btn" nz-button nzType="primary" (click)="onDisconnect()">断开连接</button>
      <button *ngIf="connected&&(platform==='flow'||signUpService.workPosition==='forth')" class="connect-btn" nz-button nzType="primary"
              (click)="onInstallFinish(true)">完成装配</button>
      <button *ngIf="connected&&signUpService.workPosition==='first'" class="connect-btn" nz-button nzType="primary" (click)="print()">打印标签</button>
              
    </div>
    <div *ngIf="platform==='alone'" class="flow-labels">
      <span class="label" [ngClass]="{'selected-label':signUpService.workPosition==='first'}"
        (click)="changeWorkPos('first')">工位1</span>
      <span class="label" [ngClass]="{'selected-label':signUpService.workPosition==='second'}"
        (click)="changeWorkPos('second')">工位2</span>
      <span class="label" [ngClass]="{'selected-label':signUpService.workPosition==='third'}"
        (click)="changeWorkPos('third')">工位3</span>
      <span class="label" [ngClass]="{'selected-label':signUpService.workPosition==='forth'}"
        (click)="changeWorkPos('forth')">工位4</span>
    </div>
    <div class="role-selector">
      权限测试：
      <!-- <button *ngIf="!signUpService.isSignIned" nz-button nzType="primary" (click)="signIn()">登录</button> -->
      <button *ngIf="signUpService.isSignIned" nz-button nzType="primary" (click)="signOut()">注销</button>
      <nz-select [(ngModel)]="platform" nzAllowClear nzPlaceHolder="选择装配方式" style="width: 200px;">
        <nz-option nzValue="flow" nzLabel="流水线"></nz-option>
        <nz-option nzValue="alone" nzLabel="全部装配"></nz-option>
      </nz-select>
      <nz-select *ngIf='platform==="flow"' [(ngModel)]="user" (ngModelChange)="changeWorkService()" nzAllowClear nzPlaceHolder="选择工作位" style="width: 200px;">
        <nz-option nzValue="first" nzLabel="工作位1"></nz-option>
        <nz-option nzValue="second" nzLabel="工作位2"></nz-option>
        <nz-option nzValue="third" nzLabel="工作位3"></nz-option>
        <nz-option nzValue="forth" nzLabel="工作位4"></nz-option>
      </nz-select>
      <!-- <nz-select *ngIf='platform==="plat2"' [(ngModel)]="user" (ngModelChange)="changeWorkPos()" nzAllowClear nzPlaceHolder="选择工作位" style="width: 200px;">
        <nz-option nzValue="forth" nzLabel="工作位4"></nz-option>
      </nz-select> -->
    </div>
  </div>
  <div class="container">
    <nz-spin [nzSpinning]="isUploading" [nzTip]="spinTip">
      <app-work-position-inspection></app-work-position-inspection>
    </nz-spin>
  </div>
  <app-sign-in></app-sign-in>

</div>