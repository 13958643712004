import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Apollo } from 'apollo-angular';
import { GET_OAD_BY_VERSION, GET_LOWEST_AVL_OAD, GET_LATEST_AVL_OAD } from '../api-graphQL/oad.gql';
import { GET_CHECK_CUBE_RES } from '../api-graphQL/identity.gql';
import { USER_LOGIN_BY_PWD, GET_CONNECTION } from '../api-graphQL/authority.gql';
import { ApolloService } from './apollo.service';
import { AttitudeData } from '../../libs/sk-protocol-v2'
import { CUBE_CHECK } from '../api-graphQL/produce.gql';
import { FetchResult } from 'apollo-boost';
import { Mutation, LoginByPwdInput, LoginVo, CubeCheckInput } from 'src/generated/graphql';
import { map, tap } from 'rxjs/operators';

export interface Axis {
  currRotateFace: number;
  beforecoderErrorCount?: number;
  aftercoderErrorCount?: number;
  beforeaxisInterfereCount?: number;
  afteraxisInterfereCount?: number;
}

export interface Attitude {
  attBefore: AttitudeData;
  attAfter: AttitudeData
}

export interface CheckCubeIdInput {
  mgcId: string;
  serial: string;
}

export interface IdSerial {
  mgcId: string;
  currSerial: string;
}

export interface OadVersion {
  major: number;  //主版本号
  minor: number;  //次版本号
  patch: number;  //每次小升级加1
  type: string;   //固件类型
}

export interface Oad {
  id: number;  //id
  major: number;  //主版本号
  minor: number;  //次版本号
  patch: number;  //每次小升级加1
  type: string;  //固件类型
  path: string;  //固件路径
  isAvailable: boolean;  //是否可用
  lowestAvailable: boolean;  //是否为最低可用版本
  latestAvailable: boolean;  //是否为最新可用版本
  hashCode: string;  //SHA256编码
}

@Injectable({
  providedIn: 'root'
})
export class GqlCubeService {

  constructor(
    private config: ConfigService,
    private apollo: Apollo,
    private apolloService: ApolloService
  ) { }

  public async sendCubeCheckResult(cubeCheckInput: CubeCheckInput): Promise<boolean> {
    return await this.apollo.mutate<Mutation>({
        mutation: CUBE_CHECK,
        variables: {
            cubeCheckInput
        }
    }).pipe(
        map(res => res.data.cubeCheck)
    ).toPromise();
}

  // 与服务器建立会话，在apollo请求头上加上Auth字段。
  async getSession(token?: string): Promise<string> {
    if (token) {
      return await this.apolloService.resetApollo(token) ? token : '';
    }
    const res = (await this.apollo.query<any>({
      query: GET_CONNECTION,
      fetchPolicy: 'network-only'
    }).toPromise()).data.connection
    console.log(res);
    return res;
  }

  public async checkCubeIdGQL(checkCubeIdInput: CheckCubeIdInput) {
    let res
    try {
      res = await this.apollo.mutate({
        mutation: GET_CHECK_CUBE_RES,
        variables: {
          mgcId: checkCubeIdInput.mgcId,
          serial: checkCubeIdInput.serial
        }
      }).toPromise()
      return res.data.checkCubeId

    } catch (err) {
      return null
    }
  }

  public async getOadByVersionGQL(currentVersion: OadVersion) {
    let res
    try {
      res = await this.apollo.query({
        query: GET_OAD_BY_VERSION,
        variables: {
          type: currentVersion.type,
          major: currentVersion.major,
          minor: currentVersion.minor,
          patch: currentVersion.patch,
        }
      }).toPromise()
      return res.data.cubeFirmware.firmwareByVersion
    } catch (err) {
      return null
    }
  }

  public async getLowestAvlOadGQL(major: number, type: string) {
    let res
    try {
      res = await this.apollo.query({
        query: GET_LOWEST_AVL_OAD,
        variables: {
          major: major,
          type: type
        }
      }).toPromise()
      return res.data.cubeFirmware.lowestAvlFirmware
    } catch (err) {
      return null
    }
  }

  public async getLatestAvlOadGQL(major: number, type: string) {
    let res
    try {
      res = await this.apollo.query({
        query: GET_LATEST_AVL_OAD,
        variables: {
          major: major,
          type: type
        }
      }).toPromise()
      return res.data.cubeFirmware.latestAvlFirmware
    } catch (err) {
      return null
    }
  }

}
