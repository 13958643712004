import { Component, OnInit } from '@angular/core';
import { InspectionBaseItem } from 'src/app/class/inspection-base-item';
import {OperatorFilterService} from '../../services/operator-filter.service'

@Component({
  selector: 'app-filter-item-inspection',
  templateUrl: './filter-item-inspection.component.html',
  styleUrls: ['./filter-item-inspection.component.scss']
})
export class FilterItemInspectionComponent implements OnInit {

  constructor(
    public operatorFilterService:OperatorFilterService
  ) { }

  //初始化滤波算法检查项
  get filterItem(): InspectionBaseItem{
    return this.operatorFilterService.filterItem
  }

  ngOnInit() {  }

 
}
