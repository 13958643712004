import { Injectable } from '@angular/core';
import { CalibrationParam } from 'src/libs/sk-protocol-v2';
import { InspectionStaticItem } from '../class/inspection-static-item';
import { BleInspectionItemService } from './ble-inspection-item.service';
import { BleCurrentStateService } from './ble-current-state.service';
import { BleCommandService } from './ble-command.service';


interface InspectResult {
  result: boolean,
  description: string
}


@Injectable({
  providedIn: 'root'
})
export class OperatorSensorsService {


  constructor(private bleInspectionItemService: BleInspectionItemService,
    private bleCommandService: BleCommandService,
    private bleCurrentStateService: BleCurrentStateService) { }

  //九轴参数检查项
  public sensorsItem: InspectionStaticItem = this.bleInspectionItemService.sensorsItem
  public sensor: string
  public async inspect() {
    this.sensorsItem.isInspecting = true
    const { result, description } = await this.inspectSensors()
    this.sensorsItem.isInspected = true
    this.sensorsItem.isInspecting = false
    this.sensorsItem.inspectionResult = result
    this.sensorsItem.description = description
  }

  public inspectSensors(): Promise<InspectResult> {
    return new Promise<InspectResult>(async (resolve, reject) => {
      const accelerometerParam = this.bleCurrentStateService.accelerometerParam
      const gyroscopeParam = this.bleCurrentStateService.gyroscopeParam
      const magnetometerParam = this.bleCurrentStateService.magnetometerParam


      await this.bleCommandService.setSensorsCalibrationParam({
        accelerometerParam: CalibrationParam.fromString(accelerometerParam.toString()),
        gyroscopeParam: CalibrationParam.fromString(gyroscopeParam.toString()),
        magnetometerParam: CalibrationParam.fromString(magnetometerParam.toString())
      })
      this.sensor = accelerometerParam.toString()
      let result = true
      let description = "合格，已校准九轴参数"
      resolve({ result, description })
    })
  }

  public clearInspectionItem() {
    this.bleInspectionItemService.sensorsItem.isInspected = false
    this.bleInspectionItemService.sensorsItem.inspectionResult = null
    this.bleInspectionItemService.sensorsItem.description = null
    this.sensorsItem = this.bleInspectionItemService.sensorsItem
  }
}
