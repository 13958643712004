import { ApolloError } from 'apollo-client';

export class Exception {
    errno: number;
    message: string;
}

export class Result<T> {
    exception: Exception;
    data: T;
}

//  将从Apollo的query或watchQuery经过pipe之后的Promise获取其值并对其进行错误处理
// 正常情况下, exception为null, 出现异常分为业务异常和服务器内部错误, 前者errno为非负数, 后者errno为-1
export async function getResult<T>(promise: Promise<T>): Promise<Result<T>> {
    const res: {exception: Exception, data: T} = {exception: null, data: null};
    await promise.then((value: T) => {
        res.data = value;
    }).catch((err: ApolloError) => {
        console.log(err);
        if (!err.graphQLErrors[0] || !err.graphQLErrors[0].extensions.exception || !err.graphQLErrors[0].extensions.exception.errno) {
            console.log('服务器发生内部错误：', err.message);
            res.exception = {errno: -1, message: '服务器发生内部错误：' + err.message} as Exception;
        } else {
            res.exception = err.graphQLErrors[0].extensions.exception as Exception;
        }
    });
    return res;
}

