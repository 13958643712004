import { Injectable } from '@angular/core';

// import { InMemoryCache, NormalizedCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { Storage } from '@ionic/storage';
import { setContext } from 'apollo-link-context';
import { InMemoryCache, ApolloClient, NormalizedCacheObject,ApolloLink } from 'apollo-boost'
import { createHttpLink } from 'apollo-link-http';
import { IN_MEMORY_CACHE, LINK } from '../graphql.module';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class ApolloService {
  private _apollo: ApolloClient<NormalizedCacheObject>;
  private _cache: InMemoryCache;
  private _authLink: ApolloLink;
  private _link: ApolloLink;
  private _token: string;

  constructor(
    public storage: Storage,
    public apolloRoot: Apollo
  ) {
    this.initService();
  }
  /**
   * 初始化apollo客户端
   */
  private async  initService() {
    this._cache = IN_MEMORY_CACHE
    this._link = LINK;
    this._token = await this.storage.get('MhcAuthToken');
  }
  private createAuthLink(token?: string) {
    if (token) {
      this._token = token;
    }
    this._authLink = setContext(async (_, { headers }) => {
      // get the authentication token from local storage if it exists
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          Authorization: this._token ? this._token : ''
        }
      }
    });
  }
  public createApollo(token?: string): ApolloClient<NormalizedCacheObject> {
    if (token) {
      this.storage.set('MhcAuthToken', token).then(res => {
        console.log('MhcAuthToken设置完成，', res)
      });
      this.createAuthLink(token);
    }
    const apollo = new ApolloClient({
      link: this._authLink.concat(this._link),
      cache: this._cache

    })
    return apollo;
  }

  public get apollo(): ApolloClient<NormalizedCacheObject> {
    if (this._apollo) {
      return this._apollo;
    } else {
      return this.createApollo();
    }
  }
  public getAuthLink(token: string): ApolloLink {
    this.createAuthLink(token);
    return this._authLink.concat(this._link);
  }
  public async resetApollo(token?: string): Promise<boolean> {
    // 重设apollo,请求头上加上headers信息
    if (token) {
      this.apolloRoot.removeClient()
      this.apolloRoot.setClient(this.createApollo(token))
      return true;
    } else if (this._token) {
      this.apolloRoot.removeClient()
      this.apolloRoot.setClient(this.createApollo(this._token))
      return true;
    } else {
      return false;
    }


  }
}