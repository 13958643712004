<div class="work-position-wrapper">
    <div nz-row [nzGutter]="24" style="width: 50%;">
        <div nz-col class="gutter-row" [nzSpan]="12">
            <div class="inner-box">
                <app-ble-signal-inspection></app-ble-signal-inspection>
            </div>
            <div class="inner-box">
                <app-oad-item-inspection></app-oad-item-inspection>
            </div>
            <div class="inner-box" *ngIf='signUpService.workPosition==="forth"'>
                <app-pinmap-item-inspection></app-pinmap-item-inspection>
            </div>
            <div class="inner-box" *ngIf='signUpService.workPosition==="forth"'>
                <app-identity-item-inspection></app-identity-item-inspection>
            </div>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="12">
            <div class="inner-box">
                <app-battery-inspection></app-battery-inspection>
            </div>
            <div class="inner-box">
                <app-attitude-item-inspection></app-attitude-item-inspection>
            </div>
            <div class="inner-box" *ngIf='signUpService.workPosition==="forth"'>
                <app-cstate-item-inspection></app-cstate-item-inspection>
            </div>
            <div class="inner-box" *ngIf='signUpService.workPosition==="forth"'>
                <app-filter-item-inspection></app-filter-item-inspection>
            </div>
            <div class="inner-box" *ngIf='signUpService.workPosition==="forth"'>
                <app-sensors-item-inspection></app-sensors-item-inspection>
            </div>
        </div>
        
        <div *ngIf='signUpService.workPosition==="first"' nz-col class="gutter-row" [nzSpan]="8"
            style="display: flex;justify-content: center; align-items: center;margin-left: 30px;">
            <div class="inner-box">
                <iframe id="print-iframe"></iframe>
            </div>
        </div>
    </div>
    <div nz-row [nzGutter]="24" style="width: 50%;">
        <div *ngIf='signUpService.workPosition==="first"||signUpService.workPosition==="second"' nz-col
            class="gutter-row" [nzSpan]="24">
            <div class="inner-box">
                <app-axis-signal-inspection></app-axis-signal-inspection>
            </div>
        </div>
        <div *ngIf='signUpService.workPosition==="third"||signUpService.workPosition==="forth"' nz-col
            class="gutter-row" [nzSpan]="24">
            <div class="inner-box">
                <app-axis-item-inspection [coderMode]="coderMode"></app-axis-item-inspection>
            </div>
        </div>
    </div>
</div>