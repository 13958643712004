import { Injectable } from '@angular/core';
import { CubeState, Color } from '../../libs/cube-state';
import { InspectionStaticItem } from '../class/inspection-static-item';
import { BleCommandService } from './ble-command.service';
import { BleInspectionItemService } from './ble-inspection-item.service';
import { CubeStateService } from './cube-state.service';

interface InspectResult {
  result: boolean,
  description: string
}

const ORIGIN_CUBE: CubeState = [
  [Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y],
  [Color.O, Color.O, Color.O, Color.O, Color.O, Color.O, Color.O, Color.O, Color.O],
  [Color.B, Color.B, Color.B, Color.B, Color.B, Color.B, Color.B, Color.B, Color.B],
  [Color.R, Color.R, Color.R, Color.R, Color.R, Color.R, Color.R, Color.R, Color.R],
  [Color.G, Color.G, Color.G, Color.G, Color.G, Color.G, Color.G, Color.G, Color.G],
  [Color.W, Color.W, Color.W, Color.W, Color.W, Color.W, Color.W, Color.W, Color.W]
]

const PAUSE = [0, 0, 0, 0, 0, 0]

@Injectable({
  providedIn: 'root'
})
export class OperatorCstateService {

  constructor(private bleInspectionItemService: BleInspectionItemService,
    private bleCommandService: BleCommandService,
    private cubeStateService: CubeStateService) { }

  public cstateItem: InspectionStaticItem = this.bleInspectionItemService.cstateItem
  public cubeState

  public async inspect() {
    this.cstateItem.isInspecting = true
    const { result, description } = await this.inspectCstate()
    this.cstateItem.isInspected = true
    this.cstateItem.isInspecting = false
    this.cstateItem.inspectionResult = result
    this.cstateItem.description = description
  }

  public async inspectCstate(): Promise<InspectResult> {
    let result
    let description
    try {
      console.log('start to inspect cstate')
      await this.bleCommandService.setCubeState({
        state: ORIGIN_CUBE,
        pause: PAUSE
      })
      await this.cubeStateService.setCubeState(ORIGIN_CUBE)
      const { state } = await this.bleCommandService.getCubeState()
      this.cubeState = state.toString()
      console.log(this.cubeState)
      if (state.toString() === ORIGIN_CUBE.toString()) {
        result = true
        description = "已初始化逻辑魔方姿态！"
      } else {
        result = false
        description = `初始化逻辑魔方姿态失败!`
      }
    } catch (err) {
      result = false
      description = `初始化逻辑魔方姿态失败！`
    }
    return { result, description }
  }

  public clearInspectionItem() {
    this.bleInspectionItemService.cstateItem.isInspected = false
    this.bleInspectionItemService.cstateItem.inspectionResult = null
    this.bleInspectionItemService.cstateItem.description = null
    this.cstateItem = this.bleInspectionItemService.cstateItem;
  }
}
