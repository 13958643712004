import { Injectable } from '@angular/core';
import { CubeState, Color } from 'src/libs/cube-state';
import { BleStateService } from './ble-state.service';
import { BleCommandService } from './ble-command.service';
import { Info, CubeNotPassReason } from 'src/generated/graphql';
import { CubeRotateService } from './cube-rotate.service';
import { ConnectionService } from './connection.service';
import { BleInspectionItemService } from './ble-inspection-item.service';
import { Subject, Subscription } from 'rxjs';
import { OperatorOadService } from './operator-oad.service'
import { BleCurrentStateService } from './ble-current-state.service';
import { GqlCubeService } from './gql-cube.service';
import { SignUpService } from './sign-up.service';
import { CubeMagneticVersion } from '../../generated/graphql';
import { OperatorAxisService } from './operator-axis.service';
import { AlertController } from '@ionic/angular';
import { WorkPositionService } from './work-position.service';
import { NzModalService } from 'ng-zorro-antd/modal';

export const SLEEP_STATE: number = 2
export const ORIGIN_CUBE: CubeState = [
  [Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y],
  [Color.O, Color.O, Color.O, Color.O, Color.O, Color.O, Color.O, Color.O, Color.O],
  [Color.B, Color.B, Color.B, Color.B, Color.B, Color.B, Color.B, Color.B, Color.B],
  [Color.R, Color.R, Color.R, Color.R, Color.R, Color.R, Color.R, Color.R, Color.R],
  [Color.G, Color.G, Color.G, Color.G, Color.G, Color.G, Color.G, Color.G, Color.G],
  [Color.W, Color.W, Color.W, Color.W, Color.W, Color.W, Color.W, Color.W, Color.W]
]

@Injectable({
  providedIn: 'root'
})
export class ResultService {

  constructor(
    private bleStateService: BleStateService,
    private bleCommandService: BleCommandService,
    private cubeRotateService: CubeRotateService,
    private connectionService: ConnectionService,
    private bleInspectionItemService: BleInspectionItemService,
    private operationOtaService: OperatorOadService,
    private bleCurrentStateService: BleCurrentStateService,
    private gqlCubeService: GqlCubeService,
    private signUpService: SignUpService,
    private operatorAxisService: OperatorAxisService,
    // private alertController: AlertController,
    public workPositionService: WorkPositionService,
    private alertModal: NzModalService,
    ) { }
  //视图魔方与手中的魔方是否一致
  public inspRes: boolean = null
  //魔方是否为还原态
  public recover: boolean
  public isInspected: boolean = false
  //倒计时定时器和倒计时开始时间
  public timer: NodeJS.Timer
  public seconds: number = 3
  //是否为主动断开连接
  public subjectiveDisconnect = false;
  private readySubscription: Subscription;
  private _disconnect$: Subject<boolean> = new Subject<boolean>();
  private otaingSubscription: Subscription
  private disconnectSubscription: Subscription
  public finishSubject = new Subject<any>()

  public get disconnect$() {
    return this._disconnect$;
  }
  public getFinishSubject() {
    return this.finishSubject.asObservable()
  }

  destroyTodo() {
    this.disconnectSubscription.unsubscribe()
    this.otaingSubscription.unsubscribe()
  }

  initTodo() {
    this.subjectiveDisconnect = false;
    this.disconnectSubscription = this.connectionService.disconnect$.subscribe((state) => {
      this.subjectiveDisconnect = state;
    })
    this.otaingSubscription = this.operationOtaService.getOtaingSubject().subscribe((state) => {
      this.subjectiveDisconnect = state;
    })
    this.readySubscription = this.bleStateService.ready$.subscribe((ready: boolean) => {
      if (!ready) {  //如果魔方断开连接，清空变量
        if (!this.isInspected && !this.subjectiveDisconnect) {
          this.bleInspectionItemService.setInfoItem('reason', CubeNotPassReason.Disconnection);
          this.upLoadUMR(false, this.bleInspectionItemService.getInfoItem());
          this._disconnect$.next(false);
        }
        this.inspRes = null
        this.recover = null
        this.isInspected = false
        this.seconds = 3
        this.clearState();
        this.cubeRotateService.resetCube()
        this.bleInspectionItemService.clearInfoItem()
        if (this.readySubscription) this.readySubscription.unsubscribe();
        if (this.timer) clearInterval(this.timer)
      }
    })
    console.log('inspRes = ' + this.inspRes);
  }

  //上传用户id 魔方mgcid 和 结果 以及检测内容
  public async upLoadUMR(result: boolean, errorInfo: Info) {
    console.log('上传检测结果：', errorInfo)
    let mgcId = this.bleCurrentStateService.pid.split(' ').reverse().join('')
    let userId = this.signUpService.userId
    let workPositionId;
    if (this.signUpService.workPosition === 'first') workPositionId = 1;
    else if (this.signUpService.workPosition === 'second') workPositionId = 2;
    else if (this.signUpService.workPosition === 'third') workPositionId = 3;
    else if (this.signUpService.workPosition === 'forth') workPositionId = 4;
    // let version = this.cubeVersionService.isNormal() ? CubeMagneticVersion.Normal : CubeMagneticVersion.Magnetic;
    let upload = { userId: userId, cubeId: mgcId, workPositionId: workPositionId, isCubeQualified: result, info: errorInfo, cubeVersion: CubeMagneticVersion.Normal };
    console.log('上传信息：', upload);
    this.gqlCubeService.sendCubeCheckResult(upload).then(res => {
      console.log(res)
      this.operatorAxisService.error = [];//上传后清空上次数据，否则必须刷新
    })
  }


  public async endCheck(info: Info) {
    this.isInspected = true
    if (this.signUpService.workPosition === 'forth'||this.signUpService.workPosition==='third') await this.checkCubeState(info)
    else await this.checkCubeQuality(info)
  }

  //检查不一致的事件处理函数
  public async doInConsistent(info: Info) {
    this.inspRes = false
    this.isInspected = true
    try {
      await this.disConnect(false, info);
    } catch (err) {
      console.log(err)
    }
  }

  public clearState() {
    this.inspRes = null
    this.recover = null
    this.seconds = 3
    this.workPositionService.clearAll()
    if (this.timer) clearInterval(this.timer)
  }

  //重新选择的事件处理函数
  public fullClearState() {
    this.clearState()
    this.isInspected = false
  }
  public checkResult(info: Info) {
    this.inspRes = !(info.reason.length);
  }
  //检查非3D状态的魔方是否合格
  public async checkCubeQuality(info: Info) {
    if (info.reason.length) {
      this.inspRes = false
      await this.disConnect(false, info);
    }
    else {
      await this.doNormalUpload(info)
    }
  }
  //检查魔方是否为还原态
  public async checkCubeState(info: Info) {
    if (!this.cubeRotateService.cube.isOrigin() || !this.cubeRotateService.cube.isCube()) {
      this.bleInspectionItemService.setInfoItem('reason', CubeNotPassReason.Disaccord);
      this.bleInspectionItemService.clearInfoItem();
      this.inspRes = false;
      this.alertModal.error({
        nzTitle: '检测不合格',
        nzContent: '该魔方为不良品。检测已终止',
        nzOkText: '确认',
        nzOnOk: async () => {
          await this.doInConsistent(info);
        },
        nzCancelDisabled:true
      })
      // const alert = await this.alertController.create({
      //   header: '检测不合格',
      //   message: '该魔方为不良品。检测已终止',
      //   buttons: [{
      //     text: '确认',
      //     handler: () => {
      //       this.doInConsistent(info);
      //     }
      //   }]
      // });
      // await alert.present();
    } else if (info.reason.length) {
      this.inspRes = false
      await this.disConnect(false, info);
    }
    else {
      // await this.disConnect(true, info)
      await this.doNormalUpload(info)
    }
  }
  //只上传数据不断开连接
  private async doNormalUpload(info:Info){
    this.inspRes = true
    this.subjectiveDisconnect = true
    this.recover = true
    await this.cubeRotateService.resetCube()
    this.clearState()
    await this.upLoadUMR(true, info)
    this.finishSubject.next()
  }

  // 立即断开连接
  public async intermediateDisconnect(result: boolean, info: Info) {
    this.subjectiveDisconnect = false;
    await this.cubeRotateService.resetCube()
    this.clearState()
    // this.upLoadUMR(result, info)
    // alert('检测魔方有异常不能入库')
    this.disconnect$.next(true);
    await this.connectionService.disconnect();
  }

  private async sleep(ts: number) {
    return new Promise((resolve) => (
      setTimeout(resolve, ts)
    ))
  }
  // 延时断开连接
  public async disConnect(result: boolean, info: Info) {
    await this.sleep(1000)
    await this.intermediateDisconnect(result, info)
  }
}
