<nz-card class="card-wrapper" nzTitle="oad检查" [nzExtra]="reinspectBtn">
    <nz-progress [nzPercent]="otaProgressValue"></nz-progress>
    <nz-divider></nz-divider>
    <div class="check-item"><span>oad版本号:</span><span>{{oadItem.currentState}}</span></div>
    <nz-divider></nz-divider>
    <p class='check-result' *ngIf='oadItem.inspectionResult'>检查结果: <span class="ok">合格</span></p>
    <p class='check-result' *ngIf='oadItem.inspectionResult===false&&!autoUpgrading'>检查结果: <span class="not-ok">不合格</span></p>
    <p class='check-result' *ngIf='oadItem.inspectionResult===false&&autoUpgrading'>检查结果: <span>升级中</span></p>
    <p class='check-result' *ngIf='oadItem.inspectionResult===null'>检查结果: <span>未检测</span></p>
</nz-card>
<ng-template #reinspectBtn>
    <button *ngIf="oadItem.inspectionResult===false" nz-button nzType="primary"
        (click)="downloadOAD()">手动升级</button>
</ng-template>