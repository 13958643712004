<nz-card class="card-wrapper" nzTitle="ID与序列号合法性">
  <div class="list">
    <div class="check-item"><span>魔方ID:</span><span>{{idSerial.mgcId}}</span></div>
    <nz-divider></nz-divider>
    <div class="check-item"><span>序列号:</span><span>{{idSerial.currSerial}}</span></div>
    <nz-divider></nz-divider>
    <div class="check-item" *ngIf='identityItem.inspectionResult'>检查结果: <span class="ok">合格</span></div>
    <div class="check-item" *ngIf='identityItem.inspectionResult===false'>检查结果: <span class="not-ok">不合格</span></div>
    <div class="check-item" *ngIf='identityItem.inspectionResult===null'>检查结果: <span>未检测</span></div>
    <nz-divider></nz-divider>
    <div class="check-item"><span>其他:</span><span>{{identityItem.description}}</span></div>
  </div>
</nz-card>