import { Component, OnInit } from '@angular/core';
import { InspectionStaticItem } from '../../class/inspection-static-item';
import {OperatorCstateService} from '../../services/operator-cstate.service'

@Component({
  selector: 'app-cstate-item-inspection',
  templateUrl: './cstate-item-inspection.component.html',
  styleUrls: ['./cstate-item-inspection.component.scss']
})
export class CstateItemInspectionComponent implements OnInit {

  constructor(
    private operatorCstateService: OperatorCstateService
  ) { }
  get cstateItem(): InspectionStaticItem{
    return this.operatorCstateService.cstateItem
  }
  
  async ngOnInit() {}

}
