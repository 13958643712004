
import { Injectable } from '@angular/core';

import { InspectionStaticItem } from '../class/inspection-static-item';
import { BleInspectionItemService } from './ble-inspection-item.service';
import { BleCurrentStateService } from './ble-current-state.service';
import { GqlCubeService,IdSerial } from './gql-cube.service';


@Injectable({
  providedIn: 'root'
})
export class OperatorIdentifyService {
  constructor(private bleInspectionItemService: BleInspectionItemService,
    private bleCurrentStateService: BleCurrentStateService,
    private staticService: GqlCubeService) { }

  public identityItem: InspectionStaticItem = this.bleInspectionItemService.identityItem

  public idSerial: IdSerial = {
    mgcId: "未知",
    currSerial: "未知"
  }

  public async inspect() {
    this.identityItem.isInspecting = true
    this.idSerial.mgcId = this.bleCurrentStateService.pid.split(' ').reverse().join('')
    this.idSerial.currSerial = this.bleCurrentStateService.serial.split(' ').reverse().join('')
    const checkRes = await this.staticService.checkCubeIdGQL({ mgcId: this.idSerial.mgcId, serial: this.idSerial.currSerial })

    if (checkRes) {
      this.identityItem.inspectionResult = checkRes
      this.identityItem.description = "魔方ID与序列号合法"
    } else {
      this.identityItem.inspectionResult = false
      this.identityItem.description = "魔方ID不存在或ID与序列号不匹配"
    }
    this.identityItem.isInspected = true
    this.identityItem.isInspecting = false
  }
  public clearInspectionItem() {
    this.bleInspectionItemService.identityItem.isInspected = false
    this.bleInspectionItemService.identityItem.inspectionResult = null
    this.bleInspectionItemService.identityItem.description = null
    this.identityItem = this.bleInspectionItemService.identityItem
  }
}
