import { Injectable } from '@angular/core';
import { BleStateService } from './ble-state.service';
import { BleCurrentStateService } from './ble-current-state.service';

@Injectable({
  providedIn: 'root'
})
export class CubeInformationService {

  constructor(
    private bleStateService: BleStateService,
    private bleCurrentStateService: BleCurrentStateService,) { }
  
    //连接状态
  public connectedState: boolean=false
  public connected:boolean
  //设备硬件信息
  public pid: string
  public serial: string
  public major: number
  public minor: number
  public patch: number
  public bootCount: number

  //设备剩余电量
  public percentage: number

  get version(){
    return this.major+'.'+this.minor+'.'+this.patch
  }

  get connectedName(){
    if(this.connectedState && this.bleStateService.connectedDevice)
      return "已连接("+this.bleStateService.connectedDevice.name+")"
    else 
      return ''
  }

  public initTodo(){
    this.getDeviceInfo()
    this.bleStateService.ready$.subscribe((val)=>{
      this.getDeviceInfo()
    })
  }

  public async getDeviceInfo() {
    if (this.bleStateService.connected) {
      this.connectedState = true
      this.pid = this.bleCurrentStateService.pid
      this.serial = this.bleCurrentStateService.serial
      this.major = this.bleCurrentStateService.major
      this.minor = this.bleCurrentStateService.minor
      this.patch = this.bleCurrentStateService.patch
      this.bootCount = this.bleCurrentStateService.bootCount
      this.percentage = this.bleCurrentStateService.percentage
    } else {
      this.connectedState = false
      this.clearDeviceInfo()
    }
    
  }
  public clearDeviceInfo() {
    this.pid = null
    this.serial = null
    this.major = null
    this.minor = null
    this.patch = null
    this.bootCount = null
    this.percentage = null
  }
}

