import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BleInspectionItemService } from 'src/app/services/ble-inspection-item.service';
import { ResultService } from 'src/app/services/result.service';
import { SignUpService } from 'src/app/services/sign-up.service';
import { WorkPositionService } from 'src/app/services/work-position.service';
import { CubeNotPassReason, Info } from 'src/generated/graphql';
import { CODERMODE } from '../axis-item-inspection/axis-item-inspection.component';

@Component({
  selector: 'app-work-position-inspection',
  templateUrl: './work-position-inspection.component.html',
  styleUrls: ['./work-position-inspection.component.less']
})
export class WorkPositionInspectionComponent implements OnInit {

  constructor(
    public signUpService: SignUpService,
    public workPositionService: WorkPositionService,
    public resultService: ResultService,
    private bleInspectionItemService: BleInspectionItemService,
    private message: NzMessageService,
    ) { }
  private info: Info

  ngOnInit(): void {
    
    this.workPositionService.initTodo()
  }
  public get coderMode(): CODERMODE {
    if (this.signUpService.workPosition === 'third') return 'period'
    else if (this.signUpService.workPosition === 'forth') return 'integrate'
  }

}
