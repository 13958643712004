import { Component, OnInit } from '@angular/core';
import { BleStateService } from 'src/app/services/ble-state.service';

@Component({
  selector: 'app-ble-signal-inspection',
  templateUrl: './ble-signal-inspection.component.html',
  styleUrls: ['./ble-signal-inspection.component.less']
})
export class BleSignalInspectionComponent implements OnInit {

  constructor(
    public bleStateService: BleStateService,
  ) {
  }

  ngOnInit(): void {
  }

}
