import { Axis } from "./cube-state";

export enum RotateDirection {
  ANTICLOCKWISE = -1,     // 转动方向为 逆针时
  NONE = 0,               // 未转动
  CLOCKWISE = 1,          // 转动方向为 顺时针
}
export interface Move {
  axis: Axis,
  rotateDirection: RotateDirection,
  moveStage: MoveStage
}
export enum MoveStage {
  START = 0,
  MOVING = 1,
  END = 2
}

export type CubeCommand =
  'U' | 'U\'' | 'U2' |
  'L' | 'L\'' | 'L2' |
  'F' | 'F\'' | 'F2' |
  'R' | 'R\'' | 'R2' |
  'B' | 'B\'' | 'B2' |
  'D' | 'D\'' | 'D2'
export type CubeCommandX = CubeCommand |
  'U2\'' | 'u' | 'u\'' | 'u2' | 'u2\'' | 'M' | 'M\'' | 'M2' | 'M2\'' |
  'L2\'' | 'l' | 'l\'' | 'l2' | 'l2\'' | 'E' | 'E\'' | 'E2' | 'E2\'' |
  'F2\'' | 'f' | 'f\'' | 'f2' | 'f2\'' | 'S' | 'S\'' | 'S2' | 'S2\'' |
  'R2\'' | 'r' | 'r\'' | 'r2' | 'r2\'' | 'x' | 'x\'' | 'x2' | 'x2\'' |
  'B2\'' | 'b' | 'b\'' | 'b2' | 'b2\'' | 'y' | 'y\'' | 'y2' | 'y2\'' |
  'D2\'' | 'd' | 'd\'' | 'd2' | 'd2\'' | 'z' | 'z\'' | 'z2' | 'z2\'' | undefined
export function reverseAndFlipCommands(cmds: Array<CubeCommand>): Array<CubeCommand> {
  if (cmds.length === 0) {
    return cmds;
  }
  return cmds.map(i => {
    if (i[i.length - 1] === '\'') {
      return i.slice(0, i.length - 1) as CubeCommand
    } else if (i[i.length - 1] === '2') {
      return i as CubeCommand
    } else {
      return i + '\'' as CubeCommand
    }
  }).reverse()
}

export function normalizeCubeCommands(cmds: string): Array<CubeCommand> {
  return cmds.trim().split(' ').filter(i => i != '') as Array<CubeCommand>
}