import gql from 'graphql-tag';

export const GET_OAD_BY_VERSION = gql`
query ($major: Int!, $minor: Int!, $patch: Int!, $type: String!) {
  cubeFirmware {
    firmwareByVersion(major: $major, minor: $minor, patch: $patch, type: $type) {
      id
      major
      minor
      patch
      type
      path
      isAvailable
      lowestAvailable
      latestAvailable
      hashCode
    }
  }
}
`

export const GET_LOWEST_AVL_OAD = gql`
query ($major: Int!, $type: String!) {
  cubeFirmware {
    lowestAvlFirmware(major: $major, type: $type) {
      id
      major
      minor
      patch
      type
      path
      isAvailable
      lowestAvailable
      latestAvailable
      hashCode      
    }
  }
}
`

export const GET_LATEST_AVL_OAD = gql`
query ($major: Int!, $type: String!) {
  cubeFirmware {
    latestAvlFirmware(major: $major, type: $type) {
      id
      major
      minor
      patch
      type
      path
      isAvailable
      lowestAvailable
      latestAvailable
      hashCode
    }
  }
}
`