import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { ConfigService } from './services/config.service';
import { ApolloLink, ApolloClient ,InMemoryCache } from 'apollo-boost';

export let LINK: ApolloLink;
export const IN_MEMORY_CACHE: InMemoryCache = new InMemoryCache();


export function createApollo(httpLink: HttpLink) {
  const config = new ConfigService();
  LINK = httpLink.create({ uri:config.path + '/graphql', withCredentials: true });
  return {
    link: LINK,
    cache: IN_MEMORY_CACHE,
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
