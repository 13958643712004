import { Component, OnInit } from '@angular/core';
import { InspectionBaseItem } from 'src/app/class/inspection-base-item';
import {OperatorSensorsService} from '../../services/operator-sensors.service'

@Component({
  selector: 'app-sensors-item-inspection',
  templateUrl: './sensors-item-inspection.component.html',
  styleUrls: ['./sensors-item-inspection.component.scss']
})
export class SensorsItemInspectionComponent implements OnInit {

  constructor(
    public operatorSensorsService :OperatorSensorsService
  ) { }

  //九轴参数检查项
  get sensorsItem(): InspectionBaseItem{
    return this.operatorSensorsService.sensorsItem
  }

  ngOnInit() {
  }
}
