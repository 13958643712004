import { Injectable } from '@angular/core';
import { InspectionDynamicItem } from '../class/inspection-dynamic-item';
import { BleInspectionItemService, InspStep } from './ble-inspection-item.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject } from 'rxjs';
import { BleCommandService } from './ble-command.service';
import { BleCurrentStateService } from './ble-current-state.service';
import { BleStateService } from './ble-state.service';
import { CubeRotateService, MoveDataDetail } from './cube-rotate.service';
import { CubeState, Color, Cube } from 'src/libs/cube-state';
import { Subscription } from 'rxjs';
import { Info, CubeNotPassReason } from '../../generated/graphql';
import { Axis } from '../../generated/graphql';
import { AlertController } from '@ionic/angular';
import { ConnectionService } from './connection.service';
// import { WorkPositionService } from './work-position.service';
import { CODERMODE } from '../components/axis-item-inspection/axis-item-inspection.component';
import { NzModalService } from 'ng-zorro-antd/modal';


const SLEEP_STATE = 2;

const ORIGIN_CUBE: CubeState = [
  [Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y],
  [Color.O, Color.O, Color.O, Color.O, Color.O, Color.O, Color.O, Color.O, Color.O],
  [Color.B, Color.B, Color.B, Color.B, Color.B, Color.B, Color.B, Color.B, Color.B],
  [Color.R, Color.R, Color.R, Color.R, Color.R, Color.R, Color.R, Color.R, Color.R],
  [Color.G, Color.G, Color.G, Color.G, Color.G, Color.G, Color.G, Color.G, Color.G],
  [Color.W, Color.W, Color.W, Color.W, Color.W, Color.W, Color.W, Color.W, Color.W]
];

const AXIS_COLOR_MAP = ['黄', '橘', '蓝', '红', '绿', '白'];

@Injectable({
  providedIn: 'root'
})
export class OperatorAxisService {
  constructor(
    private bleInspectionItemService: BleInspectionItemService,
    private bleCommandService: BleCommandService,
    private bleCurrentStateService: BleCurrentStateService,
    private bleStateService: BleStateService,
    private message: NzMessageService,
    private cubeRotateService: CubeRotateService,
    // private alertController: AlertController,
    private connectionService: ConnectionService,
    // private workFirstService: WorkPositionService,
    private alertModal: NzModalService,
    // private checkModeService: CheckModeService
  ) {
    this.info = {};
    this.info.reason = [];
    this.info.moveData = [];
  }

  public info: Info;

  public faceItems: Array<InspectionDynamicItem> = new Array<InspectionDynamicItem>();
  //上一步旋转的face, 上一步旋转的方向
  public lastRotateFace: number = 0;
  public lastRotateDirection: number = 1;  //1代表顺时针,-1代表逆时针
  public lastCubeState: CubeState = this.cubeRotateService.cube.originCube;
  //已检查的项目个数
  public hasInspectedItems: number = 0;

  public finalResult: boolean;
  public result: boolean;

  public error: Axis[] = [];

  public modal;
  public hasAlert: boolean = false;
  public rotate = new Array<MoveDataDetail>();
  public allowInspect: boolean = true;
  public needClearAngle: boolean = false;
  public count = 0;
  public circleCount = 0;
  public clockwiseCount = 0;
  public counterClockwiseCount = 0;
  public globalAngle = 0;
  public isInspecting = 0;
  public isInspectingLock = false;
  public isInspectingDoubleLock = false;    //上面两个变量的锁，防止检测结果未出之前后面的旋转将它们清空
  public clockResult: Array<boolean> = [null, null, null, null, null, null]; //顺时针检测结果
  public counterWiseResult: Array<boolean> = [null, null, null, null, null, null]; //逆时针检测结果
  public clockwiseFinish: boolean = false;
  public counterClockwiseFinish: boolean = false;
  public isReset: boolean = false; //是否重置了魔方

  //是否为主动断开
  public subjectiveDisconnect = false;

  //滤波器参数
  public coderErrorCount: number;
  public axisInterfereCount: number;
  public rotateParams$Subscription: Subscription;
  public readySubject = new Subject<any>();
  public readySubscription: Subscription;

  public disconnectSubscription: Subject<void> = new Subject<void>();

  // 检测模式（装配前/后）
  // public prevMode: boolean = false;

  public currStep;

  //标准脉冲数
  public maxPulseCount: number = 132;
  //当前旋转数
  public currRotateCount: number = 1;
  //严格检测旋转角度/10:阶段测试旋转1圈，完整测试旋转3圈
  public standardAngle = 108;
  public standardAngleDivide = 10.8
  private coderMode: CODERMODE

  getReadySubject() {
    return this.readySubject.asObservable();
  }

  initTodo(coderMode: CODERMODE) {
    console.log('编码器模式', coderMode)
    this.coderMode = coderMode
    if (coderMode === 'period') {
      this.standardAngle = 36
      this.standardAngleDivide = 3.6
    } else if (coderMode === 'integrate') {
      this.standardAngle = 108
      this.standardAngleDivide = 10.8
    }
    this.initInspectionItem();  //初始化6个侧面轴检查项
    this.clearInspectionItem();
    this.bleStateService.ready$.subscribe((ready: boolean) => {
      if (!ready) {  //若断开连接，清空检查项的内容
        this.clearInspectionItem();
        this.cubeRotateService.resetCube();
        this.readySubject.next(null)
      }
      console.log('轴立面获取axis', this.bleCurrentStateService.axisInterfereCount)
      this.coderErrorCount = this.bleCurrentStateService.coderErrorCount;
      this.axisInterfereCount = this.bleCurrentStateService.axisInterfereCount;
    });
    this.rotateParams$Subscription = this.cubeRotateService.rotate$.subscribe(rotateParams => {
      console.log('旋转订阅', this.allowInspect)
      let currRotateFace = rotateParams.move.axis
      let currRotateCircle = rotateParams.raw.circle
      //一个面的一个方向检测完成后不再检测这个方向
      if (currRotateFace === this.lastRotateFace) {
        if ((this.clockResult[currRotateFace] && currRotateCircle > 0) || (this.counterWiseResult[currRotateFace] && currRotateCircle < 0)) {
          // this.allowInspect=false
        } else{
          this.allowInspect=true
        }
      } else {
        this.allowInspect = true
      }
      if (this.allowInspect) {
        this.inspect(rotateParams.move.axis, rotateParams.raw.circle, this.cubeRotateService.cube.cubeState);
        this.rotate.push(rotateParams);
      }
    });

  }

  //初始化待检测项目信息
  public initInspectionItem() {
    this.faceItems = [
      this.bleInspectionItemService.faceItem0,
      this.bleInspectionItemService.faceItem1,
      this.bleInspectionItemService.faceItem2,
      this.bleInspectionItemService.faceItem3,
      this.bleInspectionItemService.faceItem4,
      this.bleInspectionItemService.faceItem5
    ];
    // this.readySubject.next(false);
  }

  public clearInspectionItem() {
    for (let i = 0; i < 6; i++) {
      this.faceItems[i].isInspected = false;
      this.faceItems[i].inspectionResult = null;
      this.faceItems[i].description = '';
      this.faceItems[i].ClockwiseAngle = 0;
      this.faceItems[i].CounterclockwiseAngle = 0;
    }
    this.clearCount();
    this.clockResult = [null, null, null, null, null, null];
    this.counterWiseResult = [null, null, null, null, null, null];
    this.lastRotateFace = 0;
    this.lastRotateDirection = 1;
    this.hasInspectedItems = 0;
    this.globalAngle = 0;
    // this.readySubject.next(false);
    this.finalResult = false;
    this.info = {};
    this.info.reason = [];
    this.info.moveData = [];
    if (this.rotateParams$Subscription) {
      this.rotateParams$Subscription.unsubscribe();
    }
  }
  // get workPosService() {
  //   return this.workFirstService;
  // }

  async persentAlert() {
    this.hasAlert = true;
    this.allowInspect = false;
    this.alertModal.warning({
      nzTitle: '状态确认',
      nzContent: '在转动下一个面之前，请将魔方还原',
      nzOkText: '我已还原，继续检测',
      nzOnOk: async () => {
        await this.onResetCube();
      },
      nzCancelText: '我无法还原该魔方',
      nzOnCancel: async () => {
        await this.onNoResetCube();
      }
    })
  }

  async dismiss() {
    this.hasAlert = false;
  }

  clear() {
    this.clearCount();
    this.clearIsInspecting();
    this.clearInspectionItem();
    // this.workPosService.clearAll();
    this.disconnectSubscription.next();
    this.error = [];//下次检查前清空数据
  }

  async presentEndInspAlert() {
    this.alertModal.warning({
      nzTitle: '终止检测',
      nzContent: '请将该魔方送至主管。检测已终止。',
      nzOkText: '确认',
      nzOnOk: () => {
        this.globalAngle = 0;
        this.clear();
        this.allowInspect = true;
      },
      nzCancelDisabled: true,
    })

  }

  async presentNotResetAlert() {
    this.alertModal.confirm({
      nzTitle: '确认复原',
      nzContent: '请确认手中魔方是否已复原。',
      nzOkText: '确认已复原',
      nzOnOk: async () => {
        if (this.cubeRotateService.cube.isOrigin()) {
          this.allowInspect = true;
          this.dismiss();
          return;
        } else {
          let raw = [];
          for (let moveDataDetail of this.rotate) {
            raw.push(moveDataDetail.raw);
          }

          //避免多次上传信息，后添加Disaccord
          if (this.info.axis && this.info.axis.length) {
            this.bleInspectionItemService.setInfoItem('reason', CubeNotPassReason.Axis);
            this.bleInspectionItemService.setInfoItem('axis', this.info.axis);
          }

          this.bleInspectionItemService.setInfoItem('moveData', raw);
          this.bleInspectionItemService.setInfoItem('reason', CubeNotPassReason.Disaccord);

          await this.cubeRotateService.resetCube();
          this.bleInspectionItemService.clearInfoItem();
          this.allowInspect = true;
          this.globalAngle = 0;
          this.clear();
          this.alertModal.warning({
            nzTitle: '检测不合格',
            nzContent: '该魔方为不良品。检测已终止。',
            nzOkText: '确认',
            nzOnOk: () => {
              this.connectionService.disconnect();
              this.dismiss();
            },
            nzCancelDisabled: true,
          })
        }
      },
      nzCancelText: '还未复原',
      nzOnCancel: async () => {
        await this.persentAlert();
        // await this.dismiss();
      }
    })
  }

  public async onNoResetCube() {
    try {
      this.dismiss();
      this.presentEndInspAlert();
      await this.cubeRotateService.resetCube();
      this.clear();
      this.connectionService.disconnect();
    } catch (err) {
      console.log(err);
    }
  }

  public async onResetCube() {
    if (this.cubeRotateService.cube.isOrigin()) {
      this.allowInspect = true;
      this.isReset = true;
      this.clearCount();
      this.reinspectItem(this.lastRotateFace)
      this.dismiss();
    } else {
      this.presentNotResetAlert();
    }
  }

  public async checkCubeState(state) {
    if (!this.hasAlert && !this.cubeRotateService.cube.isOrigin()) {
      await this.persentAlert();
      // await this.dismiss();
    } else {
      return;
    }
  }

  public clearIsInspecting() {
    this.isInspectingLock = false;
    this.isInspecting = 0;
    this.clockwiseFinish = false;
    this.counterClockwiseFinish = false;
  }

  public clearCount() {
    this.counterClockwiseCount = 0;
    this.clockwiseCount = 0;
    this.circleCount = 0;
    this.count = 0;
    this.globalAngle = 0;
  }

  public async inspect(currRotateFace: number, currRotateCircle: number, currState: CubeState) {
    if (this.faceItems[currRotateFace].isInspected) {
      return;
    }

    this.faceItems[currRotateFace].isInspecting = true;
    //判断当前旋转轴是否与上次旋转轴相同
    //如果与上次旋转的轴不是同一个轴, 则判断之前的轴是否已经检查完成,如果检查完成,不做处理,如果没检查完,需要重新旋转
    if (this.lastRotateFace != currRotateFace) {
      if (this.coderMode === 'integrate') {
        this.circleCount = 0;
        this.globalAngle = 0;
        this.faceItems[this.lastRotateFace].isInspecting = false;
        if (!this.faceItems[this.lastRotateFace].isInspected) {
          await this.checkCubeState(this.lastCubeState);
          this.faceItems[this.lastRotateFace].ClockwiseAngle = 0;
          this.faceItems[this.lastRotateFace].CounterclockwiseAngle = 0;
          this.clockResult[this.lastRotateFace] = null;
          this.counterWiseResult[this.lastRotateFace] = null;
          this.clockwiseFinish = false;
          this.counterClockwiseFinish = false;
          this.isInspectingLock = false;
          this.currRotateCount = 1;
        }
        //魔方重置后清空当前轴的旋转数据
        //重置后阻止继续修改轴数据
        if (this.isReset) {
          if (!this.faceItems[currRotateFace].isInspected) {
            this.faceItems[currRotateFace].ClockwiseAngle = 0;
            this.faceItems[currRotateFace].CounterclockwiseAngle = 0;
            this.currRotateCount = 1;
          }
          this.isReset = false;
          return;
        }
      } else {//工作位3换轴检测，选择最贴近的位置
        if(!this.counterWiseResult[this.lastRotateFace]&&!this.clockResult[this.lastRotateFace]){//如果两个方向都没有检测过，重新检测该轴
          this.reinspectItem(this.lastRotateFace)
        }
        if(this.counterWiseResult[this.lastRotateFace]){//逆时针检测完成才选择贴近位置
          if (this.faceItems[this.lastRotateFace].ClockwiseAngle > 18) {//超过180度，贴近360，否则贴近0
            this.clearCount();
            this.faceItems[this.lastRotateFace].isInspected = true;
            this.faceItems[this.lastRotateFace].inspectionResult = true;
            this.faceItems[this.lastRotateFace].ClockwiseAngle = this.standardAngle;
            this.faceItems[this.lastRotateFace].CounterclockwiseAngle = this.standardAngle;
            this.clockResult[this.lastRotateFace]=true;
            this.counterWiseResult[this.lastRotateFace]=true;
            this.isInspectingLock=false
          }else{
            this.reinspectItem(this.lastRotateFace)
          }
        }
        if(this.clockResult[this.lastRotateFace]){//顺时针检测完成才选择贴近位置
          if(this.faceItems[this.lastRotateFace].CounterclockwiseAngle > 18){
            this.clearCount();
            this.faceItems[this.lastRotateFace].isInspected = true;
            this.faceItems[this.lastRotateFace].inspectionResult = true;
            this.faceItems[this.lastRotateFace].ClockwiseAngle = this.standardAngle;
            this.faceItems[this.lastRotateFace].CounterclockwiseAngle = this.standardAngle;
            this.clockResult[this.lastRotateFace]=true;
            this.counterWiseResult[this.lastRotateFace]=true;
            this.isInspectingLock=false
          }else{
            this.reinspectItem(this.lastRotateFace)
          }
        }
      }

    console.log('this.globalAngle = ' + this.globalAngle, 'this.circleCount = ' + this.circleCount,currRotateCircle);
  }

    let currRotateDirection = currRotateCircle > 0 ? 1 : -1;

    //根据全局转动变量更新显示值
    //全局转动变量在0-360度之间，根据同侧是否已检测更新同侧或异侧显示值和全局转动变量
    //全局转动变量大于360度，只更新全局转动变量
    this.globalAngle += currRotateCircle / this.standardAngleDivide;
    // this.globalAngle += currRotateCircle / 10.8;
    if (Math.abs(this.circleCount) <= this.standardAngle) {
      //更新正在检测的方向和该变量的锁
      //只有一个方向完成检测之后，或者全局转动变量回零的时候，才能解锁，这里才可以更换转动方向
      if (currRotateDirection === 1) {
        this.clockwiseCount++;
        if (this.clockResult[currRotateFace] === null && !this.isInspectingLock) {
          this.isInspectingLock = true;
          this.isInspecting = 1;
        }
      } else if (currRotateDirection === -1) {
        this.counterClockwiseCount++;
        if (this.counterWiseResult[currRotateFace] === null && !this.isInspectingLock) {
          this.isInspectingLock = true;
          this.isInspecting = -1;
        }
      }
      this.circleCount += currRotateDirection;
      if (Math.abs(this.globalAngle) <= 360) {
        this.count++;
      }

      // 先确认是否检测完毕，并设置相关变量
      if (this.circleCount === -this.standardAngle) {
        this.counterClockwiseFinish = true;
        this.faceItems[currRotateFace].CounterclockwiseAngle = this.standardAngle;
      } else if (this.circleCount === this.standardAngle) {
        this.clockwiseFinish = true;
        this.faceItems[currRotateFace].ClockwiseAngle = this.standardAngle;
      }

      //根据正在检测的方向更新显示值（以下的逻辑更新的都是显示值）
      //对于每个方向，分为两种情况：相反方向已经检测或者相反方向还没有检测，以下以顺时针为例
      //对于顺时针，当逆时针已经检测过的时候，globalAngle小于0，由-360度增加到0，更新显示值为360+globalAngle
      //但如果该值大于0（什么情况？），则设为它本身
      //如果逆时针还没检测，globalAngle大于0，设为它本身。如果该值小于0，应该更新逆时针方向（值由大于0变为小于0的时候，
      //方向锁定已经解除，此时方向应该是逆时针，走else if分支
      if (this.isInspecting === 1 && !this.clockwiseFinish) {
        //正在检测顺时针
        if (this.clockResult[currRotateFace] === null)
        //this.faceItems[currRotateFace].ClockwiseAngle = this.globalAngle > 0 ? this.globalAngle : 0
        {
          this.faceItems[currRotateFace].ClockwiseAngle = this.circleCount > 0 ? this.circleCount : 0;
        } else
        //this.faceItems[currRotateFace].ClockwiseAngle = this.globalAngle < 0 ? 360 + this.globalAngle : this.globalAngle
        {
          this.faceItems[currRotateFace].ClockwiseAngle = this.circleCount < 0 ? this.standardAngle + this.circleCount : this.circleCount;
        }
      } else if (this.isInspecting === -1 && !this.counterClockwiseFinish) {
        //正在检测逆时针
        if (this.counterWiseResult[currRotateFace] === null)
        //this.faceItems[currRotateFace].CounterclockwiseAngle = this.globalAngle < 0 ? -this.globalAngle : 0
        {
          this.faceItems[currRotateFace].CounterclockwiseAngle = this.circleCount < 0 ? -this.circleCount : 0;
        } else
        //this.faceItems[currRotateFace].CounterclockwiseAngle = this.globalAngle > 0 ? 360 - this.globalAngle : -this.globalAngle
        {
          this.faceItems[currRotateFace].CounterclockwiseAngle = this.circleCount > 0 ? this.standardAngle - this.circleCount : -this.circleCount;
        }
      }
    }

    //正负脉冲和为36时将执行下面的语句块
    //检测进度依赖脉冲和，而显示出的进度依赖的是转动角度，二者根据设备不同可能产生差异
    // console.log('circleCount = ' + this.circleCount, 'isInspected = ' + this.faceItems[currRotateFace].isInspected, 'globalAngle = ' + this.globalAngle);
    if (Math.abs(this.circleCount) === this.standardAngle &&
      !this.faceItems[currRotateFace].isInspected) {
      this.isInspectingDoubleLock = true;
      let count = this.count;
      this.clearCount();
      //判断该轴是否合格, 如果转动过程中coderErrorCount改变，直接判定为不合格，如果axisInterfereCount改变，提示用户重新检查
      const { coderErrorCount, axisInterfereCount } = await this.bleCommandService.getCoderFilterParam();
      let result = this.inspectAxis(currRotateFace, coderErrorCount, axisInterfereCount, count);
      //分别设置顺时针和逆时针的flag，设置描述，以及清空检测用到的公共变量
      if (this.isInspecting === 1) {
        // console.log('clockResult[' + currRotateFace + '] set ' + result);
        this.clockResult[currRotateFace] = result;
        this.faceItems[currRotateFace].description += '顺时针逆向脉冲数/总脉冲数：' + (this.clockwiseCount) + '/' + count + '。';
      } else if (this.isInspecting === -1) {
        this.faceItems[currRotateFace].description += '逆时针逆向脉冲数/总脉冲数：' + (this.counterClockwiseCount) + '/' + count + '。';
        this.counterWiseResult[currRotateFace] = result;
      }
      this.clearIsInspecting();

      //解除锁定
      this.isInspectingDoubleLock = false;

      //某个方向检测过了，旋转另外一个方向才继续检测
      // if (this.clockResult[currRotateFace] !== null || this.counterWiseResult[currRotateFace] !== null) {
      //   console.log('一个方向有了,not allow')
      //   this.allowInspect = false
      // }
      //两个方向都检测过了，设置该面的flag，并更新显示值
      if (this.clockResult[currRotateFace] !== null && this.counterWiseResult[currRotateFace] !== null) {
        this.faceItems[currRotateFace].isInspected = true;
        this.faceItems[currRotateFace].inspectionResult = this.clockResult[currRotateFace] && this.counterWiseResult[currRotateFace];
        this.faceItems[currRotateFace].ClockwiseAngle = this.standardAngle;
        this.faceItems[currRotateFace].CounterclockwiseAngle = this.standardAngle;
      }

      //检查六个面是否都完成检测，如果不是，就直接返回继续监听旋转
      for (let item of this.faceItems) {
        if (!item.isInspected) {
          // this.readySubject.next(true);
          return;
        }
      }

      // 六个面都完成检测，该步骤完成，准备进入结果页面
      console.log('finish inspection');
      this.allowInspect = true;
      this.finalResult = true;
      this.result = true;
      for (let item of this.faceItems) {
        if (!item.isInspected) {
          this.finalResult = false;
          return;
        }
        if (!item.inspectionResult) {
          this.result = false;
        }
      }
      // 无论是否通过，将转动记录保存
      const raw = [];
      raw.push(...this.rotate.map(item => item.raw));
      console.log('旋转完成设置信息')
      this.bleInspectionItemService.setInfoItem('moveData', raw);
      if (!this.result) {
        this.bleInspectionItemService.setInfoItem('axis', this.error);
        this.bleInspectionItemService.setInfoItem('reason', CubeNotPassReason.Axis);
      }

      // this.readySubject.next(true);

      // this.clearCount();
      // this.clearIsInspecting();
      // this.clearInspectionItem();
      return;
    }

    //角度回零时，清除当前的检测标志
    //为防止该步骤在设置已检测flag之前清空isInspecting状态，导致无法设置flag
    //在上面设置已检测的if语句进入之后会立刻给这两个变量加锁，这里只有没有锁的时候才能清空
    //如果有锁，则要等到锁解除之后，代理给以后的旋转事件进行清空
    if (this.globalAngle === 0) {
      this.needClearAngle = true;
    }

    if (this.needClearAngle && !this.isInspectingDoubleLock) {
      this.isInspectingLock = false;
      this.isInspecting = 0;
      this.clearCount();
      this.needClearAngle = false;
    }

    // this.readySubject.next(true);
    this.lastRotateFace = currRotateFace;
    this.lastRotateDirection = currRotateDirection;
    this.lastCubeState = currState;
  }

  public inspectAxis(currRotateFace: number, coderErrorCount: number, axisInterfereCount: number, count: number) {
    // coderErrorCount 不能发生变化，并且正负脉冲和为 36 时脉冲次数不能超过 40
    // 即每 10 次脉冲中最多有一次异号脉冲
    let result = true;//检测结果
    if (coderErrorCount != this.coderErrorCount) {
      console.log('coderErrorCount发生了变化', coderErrorCount, this.coderErrorCount);
      this.faceItems[currRotateFace].inspectionResult = false;
      this.faceItems[currRotateFace].description += 'coderErrorCount发生了变化。';
      this.error.push({
        'currRotateFace': currRotateFace,
        'beforecoderErrorCount': this.coderErrorCount,
        'aftercoderErrorCount': coderErrorCount
      });
      result = false;
    }
    if (count > this.maxPulseCount) {
      this.faceItems[currRotateFace].inspectionResult = false;
      this.faceItems[currRotateFace].description += '旋转一周总脉冲数超过44。';
      this.error.push({
        'currRotateFace': currRotateFace,
        'allPulseCount': count
      });
      result = false;
    }
    if (axisInterfereCount != this.axisInterfereCount) {
      console.log('检查axis', axisInterfereCount, this.axisInterfereCount)
      this.faceItems[currRotateFace].isInspected = true;
      this.faceItems[currRotateFace].inspectionResult = false;
      this.message.create('warning', '该轴疑似不良品，建议重新检查！');
      this.error.push({
        'currRotateFace': currRotateFace,
        'beforeaxisInterfereCount': this.axisInterfereCount,
        'afteraxisInterfereCount': axisInterfereCount
      });
      result = false;
    }
    this.info.axis = this.error;
    this.coderErrorCount = coderErrorCount;
    this.axisInterfereCount = axisInterfereCount;

    return result;
  }

  //重新检查某一个轴
  public reinspectItem(face: number) {
    if (!this.bleStateService.connectedDevice) {
      this.message.info('未检测到已连接的魔方，请连接！');
    } else {
      this.faceItems[face].isInspected = false;
      this.faceItems[face].inspectionResult = null;
      this.faceItems[face].description = '';
      this.faceItems[face].ClockwiseAngle = 0;
      this.faceItems[face].CounterclockwiseAngle = 0;
      this.clockResult[face] = null;
      this.counterWiseResult[face] = null;
      this.globalAngle = 0;
      this.clearCount();
      this.clearIsInspecting();
      this.info = {};
      this.info.reason = [];
      this.info.moveData = [];
      // this.readySubject.next(false);
    }
  }

  //重新检查所有轴
  public reinspectAll() {
    if (!this.bleStateService.connectedDevice) {
      this.message.info('未检测到已连接的魔方，请连接！');
    } else {
      this.clearInspectionItem();
      this.readySubject.next(true)
    }
  }

  destroyTodo() {
    if (this.rotateParams$Subscription) {
      this.rotateParams$Subscription.unsubscribe();
    }
    if (this.readySubscription) {
      this.readySubscription.unsubscribe();
    }
  }
}
