
import { Injectable } from '@angular/core';
import { filter, map, tap, pairwise, sampleTime } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { InspectionDynamicItem } from '../class/inspection-dynamic-item';
import { BleInspectionItemService, InspRes, InspDynStep } from './ble-inspection-item.service';
import { BleStateService } from './ble-state.service';
import { AttitudeService } from './attitude.service';
import { AttitudeData } from '../../libs/sk-protocol-v2'
import { Info, CubeNotPassReason } from '../../generated/graphql';
import { ConnectionService } from './connection.service';

@Injectable({
  providedIn: 'root'
})
export class OperatorAttitudeService {
  constructor(
    private bleInspectionItemService: BleInspectionItemService,
    private bleStateService: BleStateService,
    private ahrsService: AttitudeService,
    private connectionService: ConnectionService,
  ) {
    this.info = {};
    this.info.reason = [];
  }
  public info: Info

  public attitudeItem: InspectionDynamicItem = this.bleInspectionItemService.attitudeItem
  public attitude
  public prevMode: boolean;
  //魔方姿态订阅
  public attitude$Subscription: Subscription
  // public connectReady: boolean = false
  public readySubject = new Subject<any>();
  public readySubscription: Subscription
  getReadySubject() {
    return this.readySubject.asObservable()
  }

  public initTodo() {
    this.bleStateService.ready$.subscribe((ready: boolean) => {
      if (!ready) {  //如果断开连接，则清空当前姿态检查项的内容。为防止万一，取消订阅魔方姿态
        this.clearInspectionItem()
        if (this.attitude$Subscription) this.attitude$Subscription.unsubscribe()
        this.readySubject.next(null)
      }
    })
    this.attitude$Subscription = this.ahrsService.attitude$.pipe(
      sampleTime(1000),
      pairwise(),
    ).subscribe(async (i) => {
      this.attitude = i
      await this.inspect(i[0], i[1])
      this.attitude$Subscription.unsubscribe()
    })
  }

  public async inspect(attBefore: AttitudeData, attAfter: AttitudeData) {
    this.attitudeItem.isInspecting = true
    const { result, description } = await this.inspectAttitude(attBefore, attAfter)
    this.attitudeItem.isInspected = true
    this.attitudeItem.isInspecting = false
    this.attitudeItem.inspectionResult = result
    this.attitudeItem.description = description
  }
  public inspectAttitude(attBefore: AttitudeData, attAfter: AttitudeData) {
    let result
    let description

    if (attBefore.x != attAfter.x && attBefore.y != attAfter.y && attBefore.z != attAfter.z && attBefore.w != attAfter.w) {
      result = true
      description = "姿态检测合格!"
    } else {
      result = false
      let error = { 'attBefore': attBefore, 'attAfter': attAfter }
      this.bleInspectionItemService.setInfoItem('attitude', error)
      this.bleInspectionItemService.setInfoItem('reason', CubeNotPassReason.Attitude)
      description = "姿态检测不合格!"
    }
    return ({ result, description })
  }
  public clearInspectionItem() {
    this.bleInspectionItemService.attitudeItem.isInspected = null
    this.bleInspectionItemService.attitudeItem.inspectionResult = null
    this.bleInspectionItemService.attitudeItem.description = ''
    this.attitudeItem = this.bleInspectionItemService.attitudeItem
    this.info = {}
    this.info.reason = [];
  }
  destroyTodo() {
    if (this.attitude$Subscription) {
      this.attitude$Subscription.unsubscribe()
    }
    if (this.readySubscription)
      this.readySubscription.unsubscribe()
  }
}
