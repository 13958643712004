<nz-modal [(nzVisible)]="!signUpService.isSignIned" nzCentered nzTitle="登录" nzOkText="登录" (nzOnOk)="signUp()"
  (nzOnCancel)="cancel()">
  <ng-container *nzModalContent>
    <nz-spin [nzSpinning]="isSigning">
      <div class="sign-in-wrapper">
        <nz-form-item>
          <nz-form-control nzErrorTip="输入账号" style="margin: 16px;">
            <nz-input-group nzPrefixIcon="user">
              <input type="text" nz-input [(ngModel)]="userName" placeholder="输入账号" nzBorderless />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="输入密码" style="margin: 16px;">
            <nz-input-group nzPrefixIcon="lock">
              <input type="password" nz-input [(ngModel)]="password" placeholder="输入密码" nzBorderless />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="signUpService.hasLoginError" style="margin: 16px;">
          {{signUpService.errorMsg}}
        </nz-form-item>
        <div *nzModalFooter>
          <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'"
            (click)="signUp()">登录</button>
        </div>
      </div>
    </nz-spin>
  </ng-container>
</nz-modal>