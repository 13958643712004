import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BleStateService, BleDeviceInfo } from './ble-state.service';
import { environment } from 'src/environments/environment';
import { BleBrowserService } from './ble-browser.service';
import { Buffer } from 'buffer'
import { Debug } from 'src/libs/debug';
import { bufferToHex, sleep } from 'src/libs/utils';
import { DfuProgress } from 'src/libs/nrf-dfu';
import { Platform } from '@ionic/angular';

export interface IBle {
  checkPermission(): Promise<{ isEnabled: boolean, hasPermission: boolean, isLocationEnabled: boolean }>
  requestPermission(): Promise<{ isEnabled: boolean, hasPermission: boolean, isLocationEnabled: boolean }>
  startScan(startWith: string): Promise<Observable<BleDeviceInfo>>
  stopScan(): Promise<void>
  connect(deviceId: string, enableDebug: boolean): Promise<Observable<Buffer>>
  command$: Observable<Buffer>
  rotate$: Observable<Buffer>
  attitude$: Observable<Buffer>
  disconnect(deviceId: string): Promise<void>
  send(buff: Buffer): Promise<void>
  otaProgress$: Observable<DfuProgress>
  ota(otaFileBuffer: Buffer, mtu: number, prn: number): Promise<void>
  requestMtu(mtuSize?: number): Promise<number>;
}


@Injectable({
  providedIn: 'root'
})
export class BleService implements IBle {
  private scanningTimer: number;
  private flushTimer: number;
  private ble: IBle
  constructor(
    private bleStateService: BleStateService,
    platform: Platform,
    bleBrowserService: BleBrowserService
  ) {
      this.ble = bleBrowserService
  }

  public async checkPermission(): Promise<{ isEnabled: boolean, hasPermission: boolean, isLocationEnabled: boolean }> {
    return this.ble.checkPermission()
  }

  public async requestPermission(): Promise<{ isEnabled: boolean, hasPermission: boolean, isLocationEnabled: boolean }> {


    return this.ble.requestPermission()
  }

  public async startScan(startWith: string, scanTime: number = 10000): Promise<Observable<BleDeviceInfo>> {
    this.scanningTimer = setTimeout(() => {
      this.stopScan()
    }, scanTime) as any as number

    this.flushTimer = setInterval(() => {
      this.bleStateService.flush++
    }, 1000) as any as number

    return await this.ble.startScan(startWith)
  }

  public stopScan(): Promise<void> {
    clearTimeout(this.scanningTimer)
    clearInterval(this.flushTimer)
    return this.ble.stopScan()
  }

  public async connect(deviceId: string, enableDebug: boolean): Promise<Observable<Buffer>> {
    return this.ble.connect(deviceId, enableDebug)
  }

  public get command$(): Observable<Buffer> {
    return this.ble.command$
  }
  public get rotate$(): Observable<Buffer> {
    return this.ble.rotate$
  }
  public get attitude$(): Observable<Buffer> {
    return this.ble.attitude$
  }
  /**
   * 返回是否已经连接设备
   */
  public get connected(): boolean {
    return this.bleStateService.connected
  }

  public async disconnect(deviceId: string): Promise<void> {
    // 提前保存好上一次连接的信息
    this.bleStateService.prevConnectedDevice = this.bleStateService.connectedDevice
    return this.ble.disconnect(deviceId)
  }

  public async send(buff: Buffer): Promise<void> {
    Debug.send(bufferToHex(buff))
    return this.ble.send(buff)
  }

  public get otaProgress$(): Observable<DfuProgress> {
    return this.ble.otaProgress$
  }
  public async ota(otaFileBuffer: Buffer, mtu: number, prn: number): Promise<void> {
    return this.ble.ota(otaFileBuffer, mtu, prn)
  }
  public async requestMtu(mtuSize?: number): Promise<number> {
    return this.ble.requestMtu(mtuSize)
  }
}
