import { Component, OnInit } from '@angular/core';
import { InspectionBaseItem } from 'src/app/class/inspection-base-item';
import { OperatorVoltageService } from 'src/app/services/operator-voltage.service';

@Component({
  selector: 'app-battery-inspection',
  templateUrl: './battery-inspection.component.html',
  styleUrls: ['./battery-inspection.component.less']
})
export class BatteryInspectionComponent implements OnInit {

  constructor(
    private operatorVoltageService:OperatorVoltageService,
  ) { }

  ngOnInit() {
  }
  get voltageItem(): InspectionBaseItem{
    return this.operatorVoltageService.voltageItem
  }

}
