import { Injectable } from '@angular/core';
import { InspectionStaticItem } from '../class/inspection-static-item';
import { BleCurrentStateService } from './ble-current-state.service';
import { BleValidService } from './ble-valid.service';
import { BleInspectionItemService } from './ble-inspection-item.service';

@Injectable({
  providedIn: 'root'
})
export class OperatorVoltageService {
  constructor(
    private bleCurrentStateService: BleCurrentStateService,
    private bleValidService: BleValidService,
    private bleInspectionItemService: BleInspectionItemService,) { }

  public voltageItem: InspectionStaticItem=this.bleInspectionItemService.voltageItem;

  public inspect() {
    this.voltageItem.isInspecting = true
    this.voltageItem.currentState = this.bleCurrentStateService.voltage.toString()
    this.voltageItem.validState = this.bleValidService.VOLTAGE_VALID
    const { result, description } = this.inspectVoltage(this.voltageItem.currentState,this.voltageItem.validState)
    this.voltageItem.isInspected = true
    this.voltageItem.isInspecting = false
    this.voltageItem.inspectionResult = result
    this.voltageItem.description = description
    
  }


  public inspectVoltage(currentVoltage: string, validVoltage: string) {
    if (+currentVoltage >= +validVoltage) {
      return {
        result: true,
        description: "合格，电池电压高于合法值"
      }
    } else {
      return {
        result: false,
        description: `不合格，低于合法电压值${validVoltage}V`
      }
    }
  }

  public clearInspectionItem() {
    this.bleInspectionItemService.voltageItem.isInspected = false
    this.bleInspectionItemService.voltageItem.inspectionResult = null
    this.bleInspectionItemService.voltageItem.description = null
    this.bleInspectionItemService.voltageItem.currentState = null
    this.bleInspectionItemService.voltageItem.validState = null
    this.voltageItem=this.bleInspectionItemService.voltageItem;
  }
}
