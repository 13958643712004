import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


export interface BleDeviceInfo {
  name?: string
  id: string
  showName?: string
  bootCount?: number
}

@Injectable({
  providedIn: 'root'
})
export class BleStateService {

  constructor() { }

  public connectedDevice: BleDeviceInfo = null
  public ready$ = new Subject<boolean>()
  public prevConnectedDevice: BleDeviceInfo = null
  public devices: { [key: string]: BleDeviceInfo } = {}
  public scanning = false
  public flush: number = 0;
  public showDisconnectModal = true

  /**
   * 返回是否已经连接设备
   */
  public get connected() {
    return !!this.connectedDevice
  }
}
