import { Component, OnInit } from '@angular/core';
import { InspectionStaticItem } from '../../class/inspection-static-item';
import { OperatorIdentifyService } from '../../services/operator-identify.service';
import { IdSerial } from '../../services/gql-cube.service';

@Component({
  selector: 'app-identity-item-inspection',
  templateUrl: './identity-item-inspection.component.html',
  styleUrls: ['./identity-item-inspection.component.scss']
})
export class IdentityItemInspectionComponent implements OnInit {

  constructor(

    private operatorIdentifyService: OperatorIdentifyService

  ) { }



  get idSerial(): IdSerial {
    return this.operatorIdentifyService.idSerial
  }

  get identityItem(): InspectionStaticItem {
    return this.operatorIdentifyService.identityItem
  }

  ngOnInit() { }


}
