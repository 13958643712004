import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { BleStateService } from './ble-state.service';
import { BleInspectionItemService } from './ble-inspection-item.service';
import { Subscription } from 'rxjs';
import { OperatorOadService } from './operator-oad.service';
import { Info, CubeNotPassReason } from '../../generated/graphql';
import { OperatorVoltageService } from './operator-voltage.service';
import { OperatorPinmapService } from './operator-pinmap.service';
import { OperatorSensorsService } from './operator-sensors.service';
import { OperatorFilterService } from './operator-filter.service';
import { OperatorIdentifyService } from './operator-identify.service';
import { OperatorCstateService } from './operator-cstate.service';
import { OperatorAttitudeService } from './operator-attitude.service';
import { SignUpService } from './sign-up.service';
import { OperatorAxisService } from './operator-axis.service';
import { OperatorAxisSignalService } from './operator-axis-signal.service';

@Injectable({
    providedIn: 'root'
})

export class WorkPositionService {
    constructor(
        private bleStateService: BleStateService,
        private bleInspectionItemService: BleInspectionItemService,
        private voltageService: OperatorVoltageService,
        private oadService: OperatorOadService,
        private pinmapService: OperatorPinmapService,
        private sensorsService: OperatorSensorsService,
        private filterService: OperatorFilterService,
        private cstateService: OperatorCstateService,
        private identifyService: OperatorIdentifyService,
        private operatorAttitudeService: OperatorAttitudeService,
        public signUpService: SignUpService,
        private operatorAxisService:OperatorAxisService,
        private operatorAxisSignalService:OperatorAxisSignalService,
    ) {
        this.info = {};
        this.info.reason = [];
    }

    public info: Info;
    //订阅对象
    public readyfalseSubscription: Subscription
    public inspectedAllSubscription: Subscription
    public inspectedAllSubject = new Subject<any>();


    getInspectedAllSubject() {
        return this.inspectedAllSubject.asObservable()
    }

    public workRes: boolean = true
    public connect$Subscription: Subscription
    initTodo() {
        this.connect$Subscription = this.bleStateService.ready$.subscribe((ready: boolean) => {
            if (ready) {  //有设备连接后自动开始检测所有项目
                console.log('连接后开始检测')
                this.inspectAll();
            } else {  //断开连接后清空所有检查项目的内容并复原Icon
                console.log('断开连接')
                this.clearAll()
            }
        })
    }

    public async inspectAll() {
        console.log('检测所有项')
        await this.inspectVoltage()
        // await this.inspectAttitude()
        await this.inspectOad()
        if (this.signUpService.workPosition === 'forth') {
            await this.inspectPinMap()
            await this.inspectCstate()
            await this.inspectFilter()
            await this.inspectSensors()
            await this.inspectIdentity()
        }
    }

    public clearAll() {
        this.voltageService.clearInspectionItem()
        this.oadService.clearInspectionItem()
        this.pinmapService.clearInspectionItem()
        this.cstateService.clearInspectionItem()
        this.filterService.clearInspectionItem()
        this.sensorsService.clearInspectionItem()
        this.identifyService.clearInspectionItem()
        // this.operatorAttitudeService.clearInspectionItem()
        this.operatorAttitudeService.readySubject.next(null)
        if(this.signUpService.workPosition==='third'||this.signUpService.workPosition==='forth') this.operatorAxisService.clearInspectionItem()
        if(this.signUpService.workPosition==='first'||this.signUpService.workPosition==='second') this.operatorAxisSignalService.clearInspectionItem()
        this.info = {};
        this.info.reason = [];
    }

    public async inspectVoltage() {
        await this.voltageService.inspect()
        if (this.bleInspectionItemService.voltageItem.inspectionResult) {
            console.log(this.readyfalseSubscription);
        } else {
            this.bleInspectionItemService.setInfoItem('voltage', this.voltageService.voltageItem.currentState);
            this.bleInspectionItemService.setInfoItem('reason', CubeNotPassReason.Voltage);
            this.workRes = false
        }
    }
    public async inspectOad() {
        await this.oadService.inspect()
        if (!this.bleInspectionItemService.oadItem.inspectionResult) {
            this.bleInspectionItemService.setInfoItem('oad', this.oadService.oadItem.currentState)
            this.bleInspectionItemService.setInfoItem('reason', CubeNotPassReason.Oad)
            this.workRes = false
        }
    }
    public async inspectAttitude() {
        await this.operatorAttitudeService.initTodo()

    }
    public async inspectPinMap() {
        await this.pinmapService.inspect();
    }
    public async inspectCstate() {
        await this.cstateService.inspect()

        if (!this.bleInspectionItemService.cstateItem.inspectionResult) {
            this.bleInspectionItemService.setInfoItem('cstate', this.cstateService.cubeState)
            this.bleInspectionItemService.setInfoItem('reason', CubeNotPassReason.Cstate)
        }
    }
    public async inspectSensors() {
        await this.sensorsService.inspect()

        if (!this.bleInspectionItemService.sensorsItem.inspectionResult) {
            this.bleInspectionItemService.setInfoItem('sensor', this.sensorsService.sensor)
            this.bleInspectionItemService.setInfoItem('reason', CubeNotPassReason.Sensor)
        }
    }

    public async inspectFilter() {
        await this.filterService.inspect()
        if (!this.bleInspectionItemService.filterItem.inspectionResult) {
            //暂时没有滤波
            this.bleInspectionItemService.setInfoItem('filter', '')
            this.bleInspectionItemService.setInfoItem('reason', CubeNotPassReason.Filter)
        }
    }

    public async inspectIdentity() {
        await this.identifyService.inspect()

        if (!this.bleInspectionItemService.identityItem.inspectionResult) {
            this.bleInspectionItemService.setInfoItem('identify', this.identifyService.idSerial)
            this.bleInspectionItemService.setInfoItem('reason', CubeNotPassReason.Identify)
        }
    }

    destroyTodo() {
        if (this.connect$Subscription) {
            this.connect$Subscription.unsubscribe()
        }
        if (this.inspectedAllSubscription)
            this.inspectedAllSubscription.unsubscribe()
        if (this.readyfalseSubscription)
            this.readyfalseSubscription.unsubscribe()
    }
}
