import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  // 特殊Token
  public SP_TOKEN = 'eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVUaW1lIjoiTW9uIERlYyAzMCAyMDE5IDEwOjE4OjIzIEdNVCswODAwIChHTVQrMDg6MDApIiwiaWF0IjoxNTc3NjcyMzAzfQ.AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAALsAJl7iNKSiZB0xSLV7lEfRCX05BU6WgM1jRHHbr2hUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMGrLCk2VFFTsShY_CjIWXAgDxEKDK3WlJVRhSfuWzaZ';
  // 线上地址 https 地址
  public path = 'https://api-ssl.magicheartcube.com'
  // 线上地址 http 地址
  // public path = 'http://api.magicheartcube.com:3000'
  // 线上测试 https 地址
  // public path = 'https://api-ssl.mheartcube.com'
  // 线上测试 http 地址
  // public path = 'http://api.mheartcube.com:3000';
  // 测试地址
  // public path = 'http://localhost:3000'

  constructor() { }

}
