import { Injectable } from '@angular/core';
import { GqlUserService } from './gql-user.service'
import { Subject, Subscription } from "rxjs";
export type WORKPOSITION='first'|'second'|'third'|'forth'
@Injectable({
  providedIn: 'root'
})
export class SignUpService {


  constructor(
    public gqlUserService: GqlUserService,
  ) { }
  public userName: string;
  public userId: number;
  public isSignIned: boolean = false
  public hasLoginError: boolean = false;
  public errorMsg: string;
  public isSignInedSubject = new Subject<any>();
  public isSignInedSubscription: Subscription;
  public isSigningVisible:boolean=false;
  public workPosition:WORKPOSITION='first'

  getisSignInedSubject() {
    return this.isSignInedSubject.asObservable()
  }
  async signUp(uName, pWord): Promise<void> {
    this.userName = uName;
    const loginRes = await this.gqlUserService.loginByPwd(uName, pWord);
    if (loginRes.exception) {
      this.errorMsg = loginRes.exception.message;
      this.isSignIned = false;
      this.hasLoginError = true;
    } else {
      const role = await this.gqlUserService.getUserRole(loginRes.data.user.userInfo.id);
      if (role === 'PRODUCER' || role === 'SUPER') {
        console.log('登录成功，结果是:', loginRes.data.user.userInfo.id)
        this.userId = loginRes.data.user.userInfo.id;
        this.isSignIned = true
        this.hasLoginError = false;
        this.isSignInedSubject.next(this.isSignIned);
      } else {
        this.isSignIned = false
        this.hasLoginError = true;
        this.errorMsg = '非生产工人无法登录'
      }
    }
  }
  signOut() {
    this.isSignIned = false
    this.hasLoginError = false
    // await this.connectionService.disconnect()
  }
  destroyTodo() {
    if (this.isSignInedSubscription) this.isSignInedSubscription.unsubscribe()
  }
}
