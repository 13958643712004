import { Injectable, NgZone } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { CoderFilter, CoderState, RotateCommand, mapPinsStateToCoderState } from 'src/libs/coder-filter';
import { Buffer } from 'buffer'
import { SkProtocolV2, MoveData, GPIOData } from 'src/libs/sk-protocol-v2';
import { flatMap, map, filter } from 'rxjs/operators';
import { Debug } from 'src/libs/debug';
import { Cube, CubeState } from 'src/libs/cube-state';
import { BleCommandService } from './ble-command.service';
import { Move } from 'src/libs/cube-command';
import { timeout } from 'promise-timeout';

export interface MoveDataDetail {
  raw: MoveData,
  move: Move
}
@Injectable({
  providedIn: 'root'
})
export class CubeRotateService {
  public cube: Cube;
  private _rotate$: Subject<MoveDataDetail> = new Subject<MoveDataDetail>()
  private _reset$: Subject<void> = new Subject<void>()

  private cubeRotateDetector: CoderFilter = null
  private skProtocalV2 = SkProtocolV2.getInstance()

  private moveDataSubscription: Subscription = null
  private gpioDataSubscription: Subscription = null

  public get rotate$(): Observable<MoveDataDetail> {
    return this._rotate$
  }

  public get reset$(): Observable<void> {
    return this._reset$
  }

  constructor(
    private bleCommandService: BleCommandService,
    private ngZone: NgZone
  ) {
    this.resetCube();
  }

  public resetCube() {
    this._reset$.next()
    this.cube = new Cube()
  }
  public connectMoveData($: Observable<Buffer>) {
    this.disconnectAll()

    this.moveDataSubscription = $.pipe(
      flatMap(i => this.skProtocalV2.resovleMoveData(i))
    ).subscribe(i => {
      this.ngZone.run(() => {
        const move = this.cube.runMove(i.face, i.circle)
        if (!move) {
          console.log(i)
        };
        this._rotate$.next({
          raw: i,
          move
        })
      })
    }, err => {
      console.log(`rotate$ err: ${err}`)
      this.disconnectAll()
    }, () => {
      console.log(`rotate$ finish!`)
    })
  }

  public disconnectMoveData() {
    if (this.moveDataSubscription) {
      this.moveDataSubscription.unsubscribe()
      this.moveDataSubscription = null
    }
  }

  async resetHardwareCubeColor(cubeState: CubeState = Cube.ORIGIN_CUBE, pause: Array<number> = [0, 0, 0, 0, 0, 0]) {
    await this.bleCommandService.setCubeState({ state: cubeState, pause })
    await this.syncHardwareCubeColor()
    console.log('reseted cube Color!')
  }

  public conectGPIOData($: Observable<Buffer>) {
    this.disconnectAll();
    (window as any).GPIOData = []
    this.moveDataSubscription = $.pipe(
      flatMap(i => this.skProtocalV2.resolveGPIOData(i)),
      filter(i => i.second !== 0 && i.counter !== 0)
    ).subscribe(i => {
      Debug.gpioData(JSON.stringify(i));
      (window as any).GPIOData.push(i)
    }, err => {
      console.log(`rotate$ err: ${err}`)
      this.disconnectAll()
    }, () => {
      console.log(`rotate$ finish!`)
    })

  }
  public disconnectGPIOData() {
    if (this.gpioDataSubscription) {
      this.gpioDataSubscription.unsubscribe()
      this.gpioDataSubscription = null
      this.cubeRotateDetector = null
    }
  }
  public disconnectAll() {
    this.resetCube()
    this.disconnectMoveData()
    this.disconnectGPIOData()
  }

  /**
   * 
   * @param timeoutMs 超时时间，如果为0或者负数为永不超时
   */
  async syncHardwareCubeColor(timeoutMs: number = 500) {
    const getCubeState$ = this.bleCommandService.getCubeState()
    const { state, pause } =
      timeoutMs > 0 ?
        await timeout(getCubeState$, timeoutMs) :
        await getCubeState$

    console.log('get hardware color')
    console.log(state, pause)
    this.cube.cubeState = state
    this.cube.pauseCounts = pause
  }
}
