import { Assets } from '../../Assets';
import { SceneContainer } from '../../GLObject';
import * as THREE from 'three';
import { Debug } from '../../Util/Debug';
import { ShaderRepo } from '../../Shaders';
import { Pass } from 'three/examples/jsm/postprocessing/Pass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';

const isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
const [E_START, E_MOVE, E_END, E_LEAVE] = isMobile
    ? ['touchstart', 'touchmove', 'touchend', 'touchleave']
    : ['mousedown', 'mousemove', 'mouseup', 'mouseleave'];

export enum MouseStatus {
    DOWN = 0,
    UP = 1
}

export abstract class BaseScene implements SceneContainer {

    protected container: Element;     //  窗口
    protected scene: THREE.Scene;
    protected camera: THREE.PerspectiveCamera;  // 相机
    protected renderer: THREE.WebGLRenderer;   // 渲染器
    protected frameId: number;
    protected mobileMoveIndex: number; // 用于定位移动端的第一次移动事件，因为第一次移动事件数据会不准
    protected mouseStatus: MouseStatus;
    protected isMobile: boolean;
    protected active: boolean = true;
    constructor(container: Element) {
        this.container = container;
        const width = container.clientWidth;
        const height = container.clientHeight;
        this.scene = new THREE.Scene();
        // this.scene.background = new THREE.Color(0x000000);
        this.scene.fog = new THREE.Fog(0xa0a0a0, 200, 1000);

        this.camera = new THREE.PerspectiveCamera(30, width / height, 0.01, 1000);
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(width, height, true);
        this.renderer.setClearColor(0x000000, 0);
        this.renderer.setClearAlpha(0);
        this.renderer.shadowMap.enabled = true;
        this.renderer.autoClear = true;

        const pixelRatio = window.devicePixelRatio || 1;
        const canvasDom = this.renderer.domElement;
        this.container.appendChild(this.renderer.domElement);
        // 兼容移动端事件
        canvasDom.addEventListener(E_START, this.onMouseDown, false);
        canvasDom.addEventListener(E_END, this.onMouseUp, false);
        canvasDom.addEventListener(E_LEAVE, this.onMouseUp, false);
        canvasDom.addEventListener(E_MOVE, this.onMouseMove, false);
        this.isMobile = isMobile;
        this.mouseStatus = MouseStatus.UP;
        this.active = true;
    }

    public SetScene(scene: THREE.Scene): void {
        this.scene = scene;
    }

    public GetScene(): THREE.Scene {
        return this.scene;
    }

    public abstract animate(): void;

    public resize(width: number, height: number): void {
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(width, height);
    }

    private disposeObject(object: THREE.Object3D) {
        if (object.children != undefined && object.children.length > 0) {
            let childCount = object.children.length;
            for (let i = 0; i < childCount; i++) {
                this.disposeObject(object.children[0]);
                object.remove(object.children[0])
            }
        }


        if (object instanceof THREE.Mesh) {
            const mesh = object as THREE.Mesh;
            mesh.geometry.dispose();
            (mesh.material as THREE.Material).dispose();
        }
    }

    public dispose(): void {
        if (this.frameId) {
            cancelAnimationFrame(this.frameId);
            this.disposeObject(this.scene);
            this.scene = null;
            this.camera = null;
            this.container.removeChild(this.renderer.domElement);
            this.renderer.setSize(0, 0);
            this.renderer.dispose();
            this.renderer = null;
            THREE.Cache.clear();
        }
    }

    protected abstract OnMouseDown(event: any);

    protected abstract OnMouseUp(event: any);

    protected abstract OnMouseMove(event: any);

    private onMouseDown = (event) => {
        // 兼容移动端事件
        event.preventDefault();
        Debug.Log(event);
        event = isMobile ? event.touches[0] : event;
        this.mouseStatus = MouseStatus.DOWN;
        this.OnMouseDown(event);
    }

    private onMouseUp = (event) => {
        // 兼容移动端事件
        event.preventDefault();
        event = isMobile ? event.touches[0] : event;
        this.mouseStatus = MouseStatus.UP;
        this.mobileMoveIndex = 0;
        this.OnMouseUp(event);
    }

    private onMouseMove = (event) => {
        // 兼容移动端事件
        event.preventDefault();
        event = isMobile ? event.touches[0] : event;
        if (this.mobileMoveIndex === MouseStatus.UP && isMobile) {  // 过滤掉移动端第一次点击时移动距离可能会过大的问题
            this.mobileMoveIndex++;
            return;
        }
        this.OnMouseMove(event);
    }


}
