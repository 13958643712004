import { Injectable } from '@angular/core';
import * as QRCode from 'qrcode'

@Injectable({
  providedIn: 'root'
})
export class LabelService {
  async genLable(mhcId, prefix = "",
    height = 140, width = 300,
    offsetX = 0, offsetY = 0,
    debug = false
  ) {
    const printIframe = document.getElementById("print-iframe")
    const doc = (printIframe as any).contentWindow.document
    const qrDataUrl = await QRCode.toDataURL(mhcId, { margin: 0 })
    const html = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <style>
      body {
        margin: 0;
        font-family: 'SimSun','FangSong','STSong';
      }
      * {
        box-sizing: border-box;
      }
    </style>
  </head>
  <body>
    <div style="
      padding: 10px;
      height: ${height}px;
      width: ${width}px;
      margin-top: ${offsetY}px;
      margin-left: ${offsetX}px;
      border: ${debug ? "4px solid black" : "none"};
      display: flex;
      align-items: center;
      justify-content: space-between;
    ">
        <div style="
          font-size: 18px; width: 160px; height: 100px; font-weight: 900; text-align: center;
          display: flex; flex-direction: column; justify-content: space-around;
        ">
        <div>${prefix + mhcId}</div>
        <div style="font-size: 50px; font-weight: 900;">${mhcId.slice(12)}</div>
        </div>
        <img
          style="width: 100px; height: 100px;"
          src="${qrDataUrl}"
          alt=""
        />
    </div>
  </body>
</html>`
    // console.log(html)
    doc.write(html)
    doc.close()

  }
  clearLabel(){
    (document.getElementById('print-iframe') as any)?.contentWindow.document.write('')
  }
  public isIframeBlank(){
    return (document.getElementById('print-iframe') as any)?.contentWindow.document.body
  }
  async print() {
    const printIframe = document.getElementById("print-iframe");
    (printIframe as any).contentWindow.print()
    console.log('打印完了')
  }
}
