<nz-card class="card-wrapper" nzTitle="电池信息">
    <div class="list">
            <div class="check-item"><span>电压/合法电压:</span><span>{{voltageItem.currentState}}/{{voltageItem.validState}}</span></div>
            <nz-divider></nz-divider>
            <!-- <div class="check-item" *ngIf='voltageItem.inspectionResult'>检查结果: <span class="ok">合格</span></div>
            <div class="check-item" *ngIf='voltageItem.inspectionResult===false'>检查结果: <span class="not-ok">不合格</span></div>
            <div class="check-item" *ngIf='voltageItem.inspectionResult===null'>检查结果: <span>未检测</span></div>
            <nz-divider></nz-divider> -->
            <div class="check-item" *ngIf='voltageItem.inspectionResult'><span>检查结果:</span><span class="ok">{{voltageItem.description}}</span></div>
            <div class="check-item" *ngIf='voltageItem.inspectionResult===false'><span>检查结果:</span><span class="not-ok">{{voltageItem.description}}</span></div>
            <div class="check-item" *ngIf='voltageItem.inspectionResult===null'><span>检查结果:</span><span>未检测</span></div>
    </div>
</nz-card>