import * as THREE from 'three';
import { Tools } from '../Util/Tools';

export class AnyCubeRotateAxesGroup {
    private rotateYAxes: Array<THREE.Group>;
    private rotateXAxes: Array<THREE.Group>;
    private rotateZAxes: Array<THREE.Group>;
    constructor(parent: THREE.Group, step: number) {
        this.rotateYAxes = new Array<THREE.Group>();
        this.rotateXAxes = new Array<THREE.Group>();
        this.rotateZAxes = new Array<THREE.Group>();
        this.generateRotateAxes(this.rotateYAxes, parent, step, Tools.MakeVector3(0, 1, 0));
        this.generateRotateAxes(this.rotateXAxes, parent, step, Tools.MakeVector3(1, 0, 0));
        this.generateRotateAxes(this.rotateZAxes, parent, step, Tools.MakeVector3(0, 0, 1));
    }
    private generateRotateAxes(array: Array<THREE.Group>, parent: THREE.Group, step: number, direction: THREE.Vector3) {
        const start = -step / 2.0;
        for (let i = 0; i < step; i++) {
            const rotateAxis = new THREE.Group();
            array.push(rotateAxis);
            parent.add(rotateAxis);
            rotateAxis.position.copy(direction.clone().multiplyScalar(start + i));
        }
    }
    public GetRotateYAxes(): Array<THREE.Group> {
        return this.rotateYAxes;
    }
    public GetRotateXAxes(): Array<THREE.Group> {
        return this.rotateXAxes;
    }
    public GetRotateZAxes(): Array<THREE.Group> {
        return this.rotateZAxes;
    }
    public GetRotateYAxis(layer: number): THREE.Group {
        return this.rotateYAxes[layer];
    }
    public GetRotateXAxis(layer: number): THREE.Group {
        return this.rotateXAxes[layer];
    }
    public GetRotateZAxis(layer: number): THREE.Group {
        return this.rotateZAxes[layer];
    }

    public dispose() {
        this.rotateXAxes = null;
        this.rotateYAxes = null;
        this.rotateZAxes = null;
    }
}
