import { AnyCubeBaseScene } from '../AnyCubeBaseScene';
import { Debug } from '../../Util/Debug';
import * as THREE from 'three';
import { Tools } from '../../Util/Tools';
import { Assets } from '../../Assets';
import { CameraViewState } from '../../CameraViewState';


export class AnyCubeFreemodeScene extends AnyCubeBaseScene {

    // tslint:disable-next-line:max-line-length
    constructor(container: Element, step: number, viewState: CameraViewState, assets: Assets, dragEnable: boolean = true) {
        super({ container, step, viewState, assets, dragEnable });
    }

    protected Update() {
    }
}
