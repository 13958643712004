export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type AddFollowInput = {
  userId: Scalars['Int'];
  friendId: Scalars['Int'];
};

export type AddReductionInput = {
  id: Scalars['Int'];
};

export type AgmParameters = {
  accelerometer: ParamsInput;
  gyroscope: ParamsInput;
  magnetometer: ParamsInput;
};

export type Attitude = {
  attBefore: AttitudeData;
  attAfter: AttitudeData;
};

export type AttitudeData = {
  w: Scalars['Float'];
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
  second: Scalars['Int'];
  counter: Scalars['Int'];
};

export type AwardInput = {
  userId: Scalars['Int'];
  limitedId: Scalars['Int'];
};

export type AwardRecordEntity = {
  __typename?: 'AwardRecordEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  awardType?: Maybe<AwardType>;
  userType?: Maybe<AwardUserType>;
  limitedId?: Maybe<Scalars['Int']>;
  seasonId?: Maybe<Scalars['Int']>;
  addrRegion?: Maybe<Scalars['String']>;
  addrState?: Maybe<Scalars['String']>;
  addrCity?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<CommunicationState>;
  expressType?: Maybe<ExpressType>;
  expressId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export enum AwardType {
  LimitedTimeChallenge = 'LimitedTimeChallenge',
  Rank = 'Rank'
}

export enum AwardUserType {
  Ranking = 'Ranking',
  Lucky = 'Lucky'
}

export type Axis = {
  currRotateFace?: Maybe<Scalars['Int']>;
  beforecoderErrorCount?: Maybe<Scalars['Int']>;
  aftercoderErrorCount?: Maybe<Scalars['Int']>;
  beforeaxisInterfereCount?: Maybe<Scalars['Int']>;
  afteraxisInterfereCount?: Maybe<Scalars['Int']>;
  allPulseCount?: Maybe<Scalars['Int']>;
};

export type BanRecordEntity = {
  __typename?: 'BanRecordEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  freeTime: Scalars['Date'];
  banReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type BaseInfoInput = {
  userId: Scalars['Int'];
  nickName?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  homePicture?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
  msgSet?: Maybe<MsgSet>;
  pkSet?: Maybe<PkSet>;
};

export type BindCubeInput = {
  mgcId: Scalars['String'];
  serial: Scalars['String'];
  mgcName: Scalars['String'];
  mgcLocalName?: Maybe<Scalars['String']>;
  bootCount?: Maybe<Scalars['Int']>;
  coderErrorCount?: Maybe<Scalars['Int']>;
  axisInterfereCount?: Maybe<Scalars['Int']>;
  customData?: Maybe<Scalars['String']>;
  bindUserId: Scalars['Int'];
  agmParameters?: Maybe<AgmParameters>;
  moveCount?: Maybe<Scalars['Int']>;
};

export enum BleType {
  BrBase = 'BR_BASE'
}

export type BroadcastEntity = {
  __typename?: 'BroadcastEntity';
  id: Scalars['Int'];
  serviceId: Scalars['Int'];
  operateId: Scalars['Int'];
  clientsId?: Maybe<Scalars['String']>;
  searchAndFilterJSON?: Maybe<Scalars['String']>;
  optionType: BroadcastOptionType;
  content: Scalars['String'];
  status: BroadcastStatus;
  broadcastType: BroadcastType;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export enum BroadcastOptionType {
  ToAll = 'TO_ALL',
  ById = 'BY_ID',
  BySearch = 'BY_SEARCH'
}

export enum BroadcastStatus {
  Sending = 'SENDING',
  Error = 'ERROR',
  Finished = 'FINISHED'
}

export enum BroadcastType {
  SiteBroadcast = 'SiteBroadcast',
  SmsBroadcast = 'SmsBroadcast'
}

export type Cfop = {
  __typename?: 'Cfop';
  c: PartStatistics;
  f: PartStatistics;
  o: PartStatistics;
  p: PartStatistics;
};

export type CfopInput = {
  c: PartStatisticsData;
  f: PartStatisticsData;
  o: PartStatisticsData;
  p: PartStatisticsData;
};

export enum ChallengeType {
  Three = 'Three'
}

export enum ChaosFormulaType {
  Random = 'Random',
  Designated = 'Designated'
}

export type ChatEntity = {
  __typename?: 'ChatEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  friendId: Scalars['Int'];
  latestMessageId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  friendInfo?: Maybe<UserEntity>;
  latestMessage?: Maybe<MessageEntity>;
};

export type ChatSelectInput = {
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  friendId?: Maybe<Scalars['Int']>;
};

export type ChatUpdateInput = {
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  friendId?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CheckCubeIdInput = {
  mgcId: Scalars['String'];
  serial: Scalars['String'];
};

export type CheckMailInput = {
  userId: Scalars['Int'];
  mail: Scalars['String'];
  mailCode: Scalars['String'];
};

export type CheckPhoneInput = {
  userId: Scalars['Int'];
  areaCode?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  vCode: Scalars['String'];
};

export enum CoderManufacturer {
  SwBase = 'SW_BASE'
}

export type CollectionAddInput = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type CollectionUpdateInput = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export enum CommunicationState {
  MessageNotSend = 'MessageNotSend',
  MessageSend = 'MessageSend',
  AddrChecked = 'AddrChecked',
  AddrUpdated = 'AddrUpdated',
  ExprIdSend = 'ExprIdSend'
}

export type CubeBanRecordEntity = {
  __typename?: 'CubeBanRecordEntity';
  id: Scalars['Int'];
  mgcId: Scalars['String'];
  banReason: Scalars['String'];
  isCanceled?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type CubeBatteryInput = {
  mgcId: Scalars['String'];
  serial: Scalars['String'];
  voltage: Scalars['Float'];
  battery: Scalars['Float'];
};

export type CubeCheckEntity = {
  __typename?: 'CubeCheckEntity';
  id: Scalars['Int'];
  mgcId: Scalars['String'];
  userId: Scalars['Int'];
  isCubeQualified: Scalars['Boolean'];
  cubeMagneticVersion?: Maybe<CubeMagneticVersion>;
  info?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
};

export type CubeCheckFilterInput = {
  mhcManufacturer?: Maybe<MhcManufacturer>;
  mhcType?: Maybe<MhcType>;
  bleType?: Maybe<BleType>;
  coderManufacturer?: Maybe<CoderManufacturer>;
  cubeVersion?: Maybe<CubeVersion>;
  min_createdAt?: Maybe<Scalars['Date']>;
  max_createdAt?: Maybe<Scalars['Date']>;
  equal_isCubeQualified?: Maybe<Scalars['Boolean']>;
  equal_userId?: Maybe<Scalars['Int']>;
  equal_mgcId?: Maybe<Scalars['String']>;
  order_checkId?: Maybe<OrderItem>;
  order_mgcId?: Maybe<OrderItem>;
  order_userId?: Maybe<OrderItem>;
  order_createdAt?: Maybe<OrderItem>;
};

export type CubeCheckInput = {
  userId: Scalars['Int'];
  cubeId: Scalars['String'];
  workPositionId: Scalars['Int'];
  isCubeQualified: Scalars['Boolean'];
  cubeVersion?: Maybe<CubeMagneticVersion>;
  info?: Maybe<Info>;
};

export type CubeFirmware = {
  __typename?: 'CubeFirmware';
  allCubeFirmwares?: Maybe<Array<Maybe<CubeFirmwareEntity>>>;
  firmwareByVersion: CubeFirmwareEntity;
  lowestAvlFirmware: CubeFirmwareEntity;
  latestAvlFirmware: CubeFirmwareEntity;
};


export type CubeFirmwareFirmwareByVersionArgs = {
  major: Scalars['Int'];
  minor: Scalars['Int'];
  patch: Scalars['Int'];
  type: Scalars['String'];
};


export type CubeFirmwareLowestAvlFirmwareArgs = {
  major: Scalars['Int'];
  type: Scalars['String'];
};


export type CubeFirmwareLatestAvlFirmwareArgs = {
  major: Scalars['Int'];
  type: Scalars['String'];
};

export type CubeFirmwareEntity = {
  __typename?: 'CubeFirmwareEntity';
  id: Scalars['Int'];
  major: Scalars['Int'];
  minor: Scalars['Int'];
  patch: Scalars['Int'];
  type: Scalars['String'];
  path: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  lowestAvailable: Scalars['Boolean'];
  latestAvailable: Scalars['Boolean'];
  hashCode: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export enum CubeMagneticVersion {
  Normal = 'NORMAL',
  Magnetic = 'MAGNETIC'
}

export enum CubeNotPassReason {
  Voltage = 'voltage',
  Cstate = 'cstate',
  Sensor = 'sensor',
  Filter = 'filter',
  Identify = 'identify',
  Oad = 'oad',
  Attitude = 'attitude',
  Axis = 'axis',
  Disaccord = 'disaccord',
  Disconnection = 'disconnection'
}

export type CubeSearchData = {
  __typename?: 'CubeSearchData';
  total: Scalars['Int'];
  list?: Maybe<Array<Maybe<MagicCubeEntity>>>;
};

export type CubeSearchInput = {
  serial?: Maybe<Scalars['String']>;
  mgcId?: Maybe<Scalars['String']>;
  mgcLocalName?: Maybe<Scalars['String']>;
  mgcName?: Maybe<Scalars['String']>;
  bindUserId?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export enum CubeType {
  Two = 'TWO',
  Three = 'THREE'
}

export enum CubeVersion {
  Formal = 'FORMAL',
  Test = 'TEST'
}

export type CustomerServiceSessionEntity = {
  __typename?: 'CustomerServiceSessionEntity';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  firstServantId: Scalars['Int'];
  endServantId?: Maybe<Scalars['Int']>;
  state: CustomerServiceSessionState;
  score?: Maybe<CustomerServiceSessionScore>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export enum CustomerServiceSessionScore {
  Bad = 'BAD',
  Normal = 'NORMAL',
  Good = 'GOOD'
}

export enum CustomerServiceSessionState {
  Open = 'OPEN',
  Timeout = 'TIMEOUT',
  Solved = 'SOLVED',
  Nosolved = 'NOSOLVED'
}


export type DelFollowInput = {
  userId: Scalars['Int'];
  friendId: Scalars['Int'];
};

export type ExaminePostInput = {
  postId: Scalars['Int'];
  examineStatus: ExamineStatus;
};

export enum ExamineStatus {
  False = 'FALSE',
  Todo = 'TODO',
  True = 'TRUE'
}

export enum ExpressType {
  ShunFeng = 'ShunFeng',
  YuanTong = 'YuanTong',
  ZhongTong = 'ZhongTong',
  YunDa = 'YunDa',
  ShenTong = 'ShenTong',
  BaiShi = 'BaiShi',
  TianTian = 'TianTian',
  Ems = 'EMS'
}

export type FollowByUserId = {
  __typename?: 'FollowByUserId';
  relationWithOther?: Maybe<Relation>;
  followList?: Maybe<Array<Maybe<FollowEntity>>>;
  fansList?: Maybe<Array<Maybe<FollowEntity>>>;
};


export type FollowByUserIdRelationWithOtherArgs = {
  friendId: Scalars['Int'];
};


export type FollowByUserIdFollowListArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};


export type FollowByUserIdFansListArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};

export type FollowEntity = {
  __typename?: 'FollowEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  followId: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  userInfo?: Maybe<UserEntity>;
  followUserInfo?: Maybe<UserEntity>;
  isFriend?: Maybe<Scalars['Boolean']>;
};

export type FollowInput = {
  userId: Scalars['Int'];
  friendId: Scalars['Int'];
};

export type FreshBestInput = {
  id: Scalars['Int'];
  time: Scalars['Float'];
};

export type GameInfo = {
  __typename?: 'GameInfo';
  id: Scalars['Int'];
  usersId: Scalars['String'];
  vid?: Maybe<Scalars['Int']>;
  seasonId?: Maybe<Scalars['Int']>;
  type: GameType;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isDeleted: Scalars['Boolean'];
  isRobot: Scalars['Boolean'];
  cubeType: CubeType;
  chaosFormula?: Maybe<Scalars['String']>;
  isWin: Scalars['Boolean'];
  rivalPkData?: Maybe<WholePkData>;
};

export enum GameType {
  NormMatch = 'NORM_MATCH',
  FtfMatch = 'FTF_MATCH',
  ChallengeMatch = 'CHALLENGE_MATCH',
  RankMatch = 'RANK_MATCH'
}

export type GetGameJwtVo = {
  __typename?: 'GetGameJWTVo';
  jwt: Scalars['String'];
  usersInfo?: Maybe<Array<UserEntity>>;
};

export type IdSerial = {
  mgcId: Scalars['String'];
  currSerial: Scalars['String'];
};

export type GpioData = {
  g0: Scalars['Int'];
  g1: Scalars['Int'];
  g2: Scalars['Int'];
  g3: Scalars['Int'];
  g4: Scalars['Int'];
  g5: Scalars['Int'];
}

export type Info = {
  reason?: Maybe<Array<CubeNotPassReason>>;
  voltage?: Maybe<Scalars['String']>;
  cstate?: Maybe<Scalars['String']>;
  sensor?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  identify?: Maybe<IdSerial>;
  oad?: Maybe<Scalars['String']>;
  attitude?: Maybe<Attitude>;
  axis?: Maybe<Array<Axis>>;
  moveData?: Maybe<Array<Maybe<MoveData>>>;
  gpioData?: Maybe<GpioData>;
};

export type InitInfoByJwtInput = {
  userId: Scalars['Int'];
  nickName?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  jwt?: Maybe<Scalars['String']>;
};

export type LikeAddInput = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type LikeSearchData = {
  __typename?: 'LikeSearchData';
  total: Scalars['Int'];
  list?: Maybe<Array<Maybe<PostLikeEntity>>>;
};

export type LikeSearchInput = {
  postId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  status?: Maybe<MessageStatus>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LikeUpdateInput = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
  status?: Maybe<MessageStatus>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type LimitedRecordCfopInput = {
  c?: Maybe<PartLimitedRecordStatisticsData>;
  f?: Maybe<PartLimitedRecordStatisticsData>;
  o?: Maybe<PartLimitedRecordStatisticsData>;
  p?: Maybe<PartLimitedRecordStatisticsData>;
};

export type LimitedRecordStatisticsInput = {
  timeUse?: Maybe<Scalars['Float']>;
  stepNum?: Maybe<Scalars['Int']>;
  observation?: Maybe<Scalars['Float']>;
  rollBack?: Maybe<Scalars['Float']>;
  smoothness?: Maybe<Scalars['Float']>;
  tps?: Maybe<Scalars['Float']>;
};

export type LimitedTimeChallenge = {
  __typename?: 'LimitedTimeChallenge';
  recordList?: Maybe<Array<LimitedTimeChallengeRecordEntity>>;
  bestRank?: Maybe<LimitedTimeChallengeRankEntity>;
};


export type LimitedTimeChallengeRecordListArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};

export type LimitedTimeChallengeAddInput = {
  userId: Scalars['Int'];
  limitedId: Scalars['Int'];
  initState: Array<Array<Scalars['Int']>>;
  attitudeData: Array<AttitudeData>;
  moveData: Array<MoveData>;
  totData: LimitedRecordStatisticsInput;
  cfopData: LimitedRecordCfopInput;
};

export type LimitedTimeChallengeEntity = {
  __typename?: 'LimitedTimeChallengeEntity';
  id: Scalars['Int'];
  challengeType: ChallengeType;
  openAt: Scalars['Date'];
  duration: Scalars['Int'];
  chaosFormulaType?: Maybe<ChaosFormulaType>;
  chaosFormula: Scalars['String'];
  useAppMessage: Scalars['Boolean'];
  appMessageContent?: Maybe<Scalars['String']>;
  appMessageTime?: Maybe<Scalars['Int']>;
  useSMS: Scalars['Boolean'];
  SMSContent?: Maybe<Scalars['String']>;
  SMSTime?: Maybe<Scalars['Int']>;
  messageReciverType: MessageReciverType;
  rewardedUserNum: Scalars['Int'];
  rewardedUserList?: Maybe<Scalars['String']>;
  luckyUserNum: Scalars['Int'];
  reductionNum: Scalars['Int'];
  luckyUserList?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  recordList?: Maybe<Array<LimitedTimeChallengeRecordEntity>>;
  state?: Maybe<Scalars['String']>;
  luckyUserInfoList?: Maybe<Array<Maybe<UserEntity>>>;
  bestRankOf?: Maybe<LimitedTimeChallengeRankEntity>;
  rankList?: Maybe<Array<LimitedTimeChallengeRankEntity>>;
};


export type LimitedTimeChallengeEntityRecordListArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
};


export type LimitedTimeChallengeEntityBestRankOfArgs = {
  userId: Scalars['Int'];
};


export type LimitedTimeChallengeEntityRankListArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};

export type LimitedTimeChallengeRankEntity = {
  __typename?: 'LimitedTimeChallengeRankEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  limitedId: Scalars['Int'];
  recordId: Scalars['Int'];
  bestTime: Scalars['Float'];
  rank: Scalars['Int'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  record: LimitedTimeChallengeRecordEntity;
};

export type LimitedTimeChallengeRecordEntity = {
  __typename?: 'LimitedTimeChallengeRecordEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  limitedId: Scalars['Int'];
  playbackId: Scalars['Int'];
  timeUse?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  userInfo?: Maybe<UserEntity>;
  playback?: Maybe<PlaybackEntity>;
  rank?: Maybe<Scalars['Int']>;
};

export type ListPvpChallengeInfo = {
  __typename?: 'ListPvpChallengeInfo';
  id: Scalars['Int'];
  challenger: Scalars['Int'];
  target: Scalars['Int'];
  gameId: Scalars['Int'];
  content: Scalars['String'];
  isAccepted: Scalars['Boolean'];
  reason: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  otherInfo?: Maybe<UserEntity>;
};

export type LoadTraceEntity = {
  __typename?: 'LoadTraceEntity';
  userId: Scalars['Int'];
  userIp: Scalars['String'];
  timeLoad: Scalars['Float'];
  referrer?: Maybe<Scalars['String']>;
};

export type LoginByPwdInput = {
  areaCode?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  password: Scalars['String'];
};

export type LoginByVCodeInput = {
  areaCode?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  vCode?: Maybe<Scalars['String']>;
};

export type LoginRecordEntity = {
  __typename?: 'LoginRecordEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  loginIp: Scalars['String'];
  loginProvince?: Maybe<Scalars['String']>;
  loginCity?: Maybe<Scalars['String']>;
  loginType: Scalars['Int'];
  createdAt: Scalars['Date'];
};

export type LoginVo = {
  __typename?: 'LoginVo';
  user?: Maybe<User>;
  isFirstLogin?: Maybe<Scalars['Boolean']>;
  jwt?: Maybe<Scalars['String']>;
  authToken?: Maybe<Scalars['String']>;
};

export type MagicCube = {
  __typename?: 'MagicCube';
  cubesInfo?: Maybe<Array<MagicCubeEntity>>;
};

export type MagicCubeEntity = {
  __typename?: 'MagicCubeEntity';
  id: Scalars['Int'];
  mgcId: Scalars['String'];
  mgcLocalName: Scalars['String'];
  mgcName: Scalars['String'];
  bootCount: Scalars['Int'];
  coderErrorCount: Scalars['Int'];
  axisInterfereCount: Scalars['Int'];
  rotationCount: Scalars['Int'];
  customData: Scalars['String'];
  bindCount: Scalars['Int'];
  bindUserId?: Maybe<Scalars['Int']>;
  agmParameters?: Maybe<Scalars['String']>;
  latestBindTime?: Maybe<Scalars['Float']>;
  earliestBindTime?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  voltage?: Maybe<Scalars['Float']>;
  battery?: Maybe<Scalars['Float']>;
  moveCount?: Maybe<Scalars['Int']>;
  latestUploadBatteryTime?: Maybe<Scalars['Date']>;
  isBanned: Scalars['Boolean'];
  checkRecord?: Maybe<CubeCheckEntity>;
};

export type MailCodeEntity = {
  __typename?: 'MailCodeEntity';
  id: Scalars['Int'];
  mailCode: Scalars['String'];
  mail: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isDeleted: Scalars['Boolean'];
};

export type Media = {
  __typename?: 'Media';
  allMedias: Array<MediaEntity>;
  mediasOfType: Array<MediaEntity>;
};


export type MediaMediasOfTypeArgs = {
  type: Scalars['Int'];
};

export type MediaEntity = {
  __typename?: 'MediaEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  type: MediaType;
  url: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isPublic: Scalars['Boolean'];
};

export enum MediaType {
  Image = 'IMAGE',
  Video = 'VIDEO',
  Avatar = 'AVATAR'
}

export type Message = {
  __typename?: 'Message';
  messagesWithFriend?: Maybe<Array<Maybe<MessageEntity>>>;
  messagesWithCS?: Maybe<Array<Maybe<MessageEntity>>>;
  unreadCount?: Maybe<Scalars['Int']>;
  messagesList?: Maybe<Array<Maybe<ChatEntity>>>;
  currentCustomerSession?: Maybe<CustomerServiceSessionEntity>;
};


export type MessageMessagesWithFriendArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
  messageId?: Maybe<Scalars['Int']>;
  isBackward?: Maybe<Scalars['Boolean']>;
  friendId: Scalars['Int'];
};


export type MessageMessagesWithCsArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
  messageId?: Maybe<Scalars['Int']>;
  isBackward?: Maybe<Scalars['Boolean']>;
};


export type MessageMessagesListArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};

export type MessageEntity = {
  __typename?: 'MessageEntity';
  id: Scalars['Int'];
  senderId: Scalars['Int'];
  receiverId: Scalars['Int'];
  type: MessageType;
  content: Scalars['String'];
  status: MessageStatus;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isReceived: Scalars['Boolean'];
};

export enum MessageReciverType {
  AllUser = 'AllUser',
  RestUser = 'RestUser'
}

export enum MessageStatus {
  Unread = 'UNREAD',
  Read = 'READ'
}

export enum MessageType {
  ChatMessage = 'CHAT_MESSAGE',
  CustomerService = 'CUSTOMER_SERVICE'
}

export type MessageUpdateById = {
  messageId?: Maybe<Scalars['Int']>;
  status: MessageStatus;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type MessageUpdateByTwoUsers = {
  userId?: Maybe<Scalars['Int']>;
  friendId?: Maybe<Scalars['Int']>;
  type?: Maybe<MessageType>;
  status: MessageStatus;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type MessageUpdateByUserId = {
  userId?: Maybe<Scalars['Int']>;
  type?: Maybe<MessageType>;
  status: MessageStatus;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type MgcIdFilterInput = {
  mhcManufacturer?: Maybe<MhcManufacturer>;
  mhcType?: Maybe<MhcType>;
  bleType?: Maybe<BleType>;
  coderManufacturer?: Maybe<CoderManufacturer>;
  cubeVersion?: Maybe<CubeVersion>;
};

export enum MhcManufacturer {
  Moyu = 'MOYU'
}

export enum MhcType {
  Two = 'TWO',
  Three = 'THREE',
  Four = 'FOUR',
  Five = 'FIVE'
}

export type ModifyCubeInput = {
  mgcId: Scalars['String'];
  serial: Scalars['String'];
  bindUserId: Scalars['Int'];
  mgcName?: Maybe<Scalars['String']>;
  mgcLocalName?: Maybe<Scalars['String']>;
  bootCount?: Maybe<Scalars['Int']>;
  coderErrorCount?: Maybe<Scalars['Int']>;
  axisInterfereCount?: Maybe<Scalars['Int']>;
  customData?: Maybe<Scalars['String']>;
  agmParameters?: Maybe<AgmParameters>;
  moveCount?: Maybe<Scalars['Int']>;
};

export type MoveData = {
  second: Scalars['Int'];
  counter: Scalars['Int'];
  face: Scalars['Int'];
  circle: Scalars['Int'];
};

export enum MsgSet {
  EachConcern = 'EACH_CONCERN',
  Everyone = 'EVERYONE',
  MyConcern = 'MY_CONCERN',
  MyFan = 'MY_FAN'
}

export type Mutation = {
  __typename?: 'Mutation';
  confirmAwardAddr: Scalars['Boolean'];
  addrUpd: UserAddrEntity;
  userUpd: UserEntity;
  userLogin: LoginVo;
  userDel: Scalars['Boolean'];
  trainingDataAdd: TrainingDataEntity;
  messageUpd: Array<MessageEntity>;
  chatUpd?: Maybe<ChatEntity>;
  cubeUpd: MagicCubeEntity;
  checkCubeId: Scalars['Boolean'];
  postAdd: PostEntity;
  postUpd: PostEntity;
  likeAdd: PostLikeEntity;
  likeUpd: PostLikeEntity;
  collectionAdd: PostCollectionEntity;
  collectionUpd: PostCollectionEntity;
  replyAdd: PostReplyEntity;
  replyUpd: PostReplyEntity;
  markReadPost: Scalars['Boolean'];
  examinePost: Scalars['Boolean'];
  followAdd: FollowEntity;
  followDel: Scalars['Boolean'];
  notificationUpd?: Maybe<Array<Maybe<NotificationEntity>>>;
  mediaDel: Scalars['Boolean'];
  sendSms: Scalars['Boolean'];
  checkNormalVCode: Scalars['String'];
  sendMailCode: Scalars['Boolean'];
  checkNormalMailCode: Scalars['String'];
  challengeMessageUpd: MessageEntity;
  cubeCheck: Scalars['Boolean'];
  report: Scalars['Boolean'];
  addLimitedTimeChallengeRecord?: Maybe<LimitedTimeChallengeRecordEntity>;
  cubeChargeCheck: Scalars['Boolean'];
};


export type MutationConfirmAwardAddrArgs = {
  awardId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationAddrUpdArgs = {
  addrInput: NewAddrInput;
};


export type MutationUserUpdArgs = {
  baseInfo?: Maybe<BaseInfoInput>;
  initInfoByJwt?: Maybe<InitInfoByJwtInput>;
  pwdByPwd?: Maybe<NewPwdByPwdInput>;
  pwdByVCode?: Maybe<NewPwdByVCodeInput>;
  phoneByVCodeAndJwt?: Maybe<NewPhoneByVCodeAndJwtInput>;
  addReduction?: Maybe<AddReductionInput>;
  freshBestInput?: Maybe<FreshBestInput>;
  emailByMailCodeAndJwt?: Maybe<NewMailByMailCodeAndJwtInput>;
  pwdByMailCode?: Maybe<NewPwdByMailCodeInput>;
};


export type MutationUserLoginArgs = {
  byPwd?: Maybe<LoginByPwdInput>;
  byVCode?: Maybe<LoginByVCodeInput>;
};


export type MutationUserDelArgs = {
  userId: Scalars['Int'];
};


export type MutationTrainingDataAddArgs = {
  input: PostSingleTrainingInput;
};


export type MutationMessageUpdArgs = {
  byId?: Maybe<MessageUpdateById>;
  byTwoUsers?: Maybe<MessageUpdateByTwoUsers>;
  byUserId?: Maybe<MessageUpdateByUserId>;
};


export type MutationChatUpdArgs = {
  updateInput: ChatUpdateInput;
};


export type MutationCubeUpdArgs = {
  bindCubeInput?: Maybe<BindCubeInput>;
  unbindCubeInput?: Maybe<UnbindCubeInput>;
  modifyCubeInput?: Maybe<ModifyCubeInput>;
  cubeBatteryInput?: Maybe<CubeBatteryInput>;
};


export type MutationCheckCubeIdArgs = {
  magicCubeId: CheckCubeIdInput;
};


export type MutationPostAddArgs = {
  postInput: PostInput;
};


export type MutationPostUpdArgs = {
  updateInput: PostUpdateInput;
};


export type MutationLikeAddArgs = {
  addInput: LikeAddInput;
};


export type MutationLikeUpdArgs = {
  updateInput: LikeUpdateInput;
};


export type MutationCollectionAddArgs = {
  addInput: CollectionAddInput;
};


export type MutationCollectionUpdArgs = {
  updateInput: CollectionUpdateInput;
};


export type MutationReplyAddArgs = {
  reply: ReplyInput;
};


export type MutationReplyUpdArgs = {
  updateInput: ReplyUpdateInput;
};


export type MutationMarkReadPostArgs = {
  postId: Scalars['Int'];
};


export type MutationExaminePostArgs = {
  examineInput: ExaminePostInput;
};


export type MutationFollowAddArgs = {
  addFollowInput: AddFollowInput;
};


export type MutationFollowDelArgs = {
  delFollowInput: DelFollowInput;
};


export type MutationNotificationUpdArgs = {
  byUserAndPost?: Maybe<NotUpdateInputByUserAndPost>;
  byId?: Maybe<NotUpdateInputById>;
  byUser?: Maybe<NotUpdateInputByUser>;
};


export type MutationMediaDelArgs = {
  userId: Scalars['Int'];
  url: Scalars['String'];
};


export type MutationSendSmsArgs = {
  areaCode?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
};


export type MutationCheckNormalVCodeArgs = {
  checkPhoneInput: CheckPhoneInput;
};


export type MutationSendMailCodeArgs = {
  mail: Scalars['String'];
};


export type MutationCheckNormalMailCodeArgs = {
  checkMailInput: CheckMailInput;
};


export type MutationChallengeMessageUpdArgs = {
  updateInput: PvpChallengeMessageUpdateInput;
};


export type MutationCubeCheckArgs = {
  cubeCheckInput: CubeCheckInput;
};


export type MutationReportArgs = {
  user?: Maybe<ReportUserInput>;
  post?: Maybe<ReportPostInput>;
  pk?: Maybe<ReportPkInput>;
};


export type MutationAddLimitedTimeChallengeRecordArgs = {
  addInput: LimitedTimeChallengeAddInput;
};

export type NewAddrInput = {
  userId: Scalars['Int'];
  name: Scalars['String'];
  phone: Scalars['String'];
  region: Scalars['String'];
  state: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
};

export type NewMailByMailCodeAndJwtInput = {
  userId: Scalars['Int'];
  newMail: Scalars['String'];
  mailCode: Scalars['String'];
  jwt?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type NewPhoneByPwdInput = {
  userId: Scalars['Int'];
  password: Scalars['String'];
  phone: Scalars['String'];
  newPhone: Scalars['String'];
};

export type NewPhoneByVCodeAndJwtInput = {
  userId: Scalars['Int'];
  newPhone: Scalars['String'];
  vCode?: Maybe<Scalars['String']>;
  jwt?: Maybe<Scalars['String']>;
};

export type NewPwdByMailCodeInput = {
  userId: Scalars['Int'];
  mail: Scalars['String'];
  newpwd: Scalars['String'];
  mailCode?: Maybe<Scalars['String']>;
};

export type NewPwdByPwdInput = {
  userId: Scalars['Int'];
  password: Scalars['String'];
  newpwd: Scalars['String'];
};

export type NewPwdByVCodeInput = {
  userId: Scalars['Int'];
  phone: Scalars['String'];
  newpwd: Scalars['String'];
  vCode?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  normalNotificationList?: Maybe<Array<Maybe<NotificationEntity>>>;
};


export type NotificationNormalNotificationListArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};

export type NotificationEntity = {
  __typename?: 'NotificationEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  friendId: Scalars['Int'];
  nickName: Scalars['String'];
  photo: Scalars['String'];
  type: NotificationType;
  postId?: Maybe<Scalars['Int']>;
  replyId?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  replyContent?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  status: MessageStatus;
  count?: Maybe<Scalars['Int']>;
  nickNameConcat?: Maybe<Scalars['String']>;
};

export enum NotificationType {
  ChatMessage = 'CHAT_MESSAGE',
  LikeMessage = 'LIKE_MESSAGE',
  ReplyMessage = 'REPLY_MESSAGE',
  ChallengeMessage = 'CHALLENGE_MESSAGE',
  CustomerService = 'CUSTOMER_SERVICE'
}

export type NotUpdateInputById = {
  id: Scalars['Int'];
  status?: Maybe<MessageStatus>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type NotUpdateInputByUser = {
  userId: Scalars['Int'];
  status: MessageStatus;
};

export type NotUpdateInputByUserAndPost = {
  userId: Scalars['Int'];
  postId: Scalars['Int'];
  status: MessageStatus;
};

export type OrderItem = {
  order?: Maybe<Scalars['Int']>;
  way?: Maybe<OrderWay>;
};

export type OrderItemObject = {
  __typename?: 'OrderItemObject';
  order?: Maybe<Scalars['Int']>;
  way?: Maybe<OrderWay>;
};

export enum OrderWay {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ParamsInput = {
  x0: Scalars['Float'];
  y0: Scalars['Float'];
  z0: Scalars['Float'];
  a: Scalars['Float'];
  b: Scalars['Float'];
  c: Scalars['Float'];
};

export type PartLimitedRecordStatisticsData = {
  timeUse?: Maybe<Scalars['Float']>;
  stepNum?: Maybe<Scalars['Int']>;
  observation?: Maybe<Scalars['Float']>;
  rollBack?: Maybe<Scalars['Float']>;
  smoothness?: Maybe<Scalars['Float']>;
  tps?: Maybe<Scalars['Float']>;
  steps?: Maybe<Scalars['String']>;
};

export type PartStatistics = {
  __typename?: 'PartStatistics';
  timeUse: Scalars['Float'];
  stepNum: Scalars['Int'];
  observation: Scalars['Float'];
  smoothness: Scalars['Float'];
  tps: Scalars['Float'];
  steps: Scalars['String'];
};

export type PartStatisticsData = {
  timeUse: Scalars['Float'];
  stepNum: Scalars['Int'];
  observation: Scalars['Float'];
  rollBack: Scalars['Float'];
  smoothness: Scalars['Float'];
  tps: Scalars['Float'];
  steps: Scalars['String'];
};

export type PkAverageVo = {
  __typename?: 'PkAverageVo';
  bestAO5?: Maybe<Scalars['Float']>;
  bestAO12?: Maybe<Scalars['Float']>;
  bestAO100?: Maybe<Scalars['Float']>;
  latestAO5?: Maybe<Scalars['Float']>;
  latestAO12?: Maybe<Scalars['Float']>;
  latestAO100?: Maybe<Scalars['Float']>;
};

export type PkAvgDataVo = {
  __typename?: 'PkAvgDataVo';
  timeUse?: Maybe<Scalars['Float']>;
  stepNum?: Maybe<Scalars['Float']>;
  observation?: Maybe<Scalars['Float']>;
  smoothness?: Maybe<Scalars['Float']>;
  tps?: Maybe<Scalars['Float']>;
};

export type PkData = {
  __typename?: 'PkData';
  dataList?: Maybe<Array<Maybe<WholePkData>>>;
  statisticsData?: Maybe<PkStatisticsVo>;
  pkStatistic?: Maybe<PkStatistic>;
  averageData?: Maybe<PkAvgDataVo>;
  bestAO?: Maybe<PkAverageVo>;
};


export type PkDataDataListArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};


export type PkDataStatisticsDataArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};

export type PkDataEntity = {
  __typename?: 'PkDataEntity';
  id: Scalars['Int'];
  gameId: Scalars['Int'];
  userId: Scalars['Int'];
  playbackId: Scalars['Int'];
  createdAt: Scalars['Date'];
  scoreChange: Scalars['Int'];
  updatedAt: Scalars['Date'];
  isFinished: Scalars['Boolean'];
  type: Scalars['Int'];
};

export type PkResultEntity = {
  __typename?: 'PkResultEntity';
  id: Scalars['Int'];
  usersId: Scalars['String'];
  vid?: Maybe<Scalars['Int']>;
  seasonId?: Maybe<Scalars['Int']>;
  type: GameType;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isDeleted: Scalars['Boolean'];
  isRobot: Scalars['Boolean'];
  cubeType: CubeType;
  chaosFormula?: Maybe<Scalars['String']>;
};

export type PkSearchData = {
  __typename?: 'PkSearchData';
  total: Scalars['Int'];
  list?: Maybe<Array<Maybe<PkDataEntity>>>;
};

export enum PkSet {
  EachConcern = 'EACH_CONCERN',
  Everyone = 'EVERYONE',
  MyConcern = 'MY_CONCERN',
  MyFan = 'MY_FAN'
}

export type PkStatistic = {
  __typename?: 'PkStatistic';
  pkCount?: Maybe<Scalars['Int']>;
  winCount?: Maybe<Scalars['Int']>;
  bestSecond?: Maybe<Scalars['Float']>;
};

export type PkStatisticsVo = {
  __typename?: 'PkStatisticsVo';
  id: Array<Scalars['Int']>;
  timeUse: Array<Maybe<Scalars['Float']>>;
  stepNum: Array<Maybe<Scalars['Int']>>;
  tps: Array<Maybe<Scalars['Float']>>;
};

export type PlaybackEntity = {
  __typename?: 'PlaybackEntity';
  id: Scalars['Int'];
  type?: Maybe<PlaybackType>;
  timeUse?: Maybe<Scalars['Float']>;
  stepNum: Scalars['Int'];
  rollBack: Scalars['Int'];
  tps: Scalars['Float'];
  smoothness: Scalars['Float'];
  observation: Scalars['Float'];
  cfopDetail?: Maybe<Cfop>;
  rawData: Scalars['String'];
  ao5?: Maybe<Scalars['Float']>;
  ao12?: Maybe<Scalars['Float']>;
  ao100?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export enum PlaybackType {
  Train = 'TRAIN',
  Pk = 'PK',
  LimitedChallenge = 'LIMITED_CHALLENGE'
}

export type Post = {
  __typename?: 'Post';
  postsOfLike?: Maybe<Array<Maybe<PostEntity>>>;
  postsOfCollection?: Maybe<Array<Maybe<PostEntity>>>;
  postsOfRecommend?: Maybe<Array<Maybe<PostEntity>>>;
  postsOfFollow?: Maybe<Array<Maybe<PostEntity>>>;
  postsOfSelf?: Maybe<Array<Maybe<PostEntity>>>;
};


export type PostPostsOfLikeArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};


export type PostPostsOfCollectionArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};


export type PostPostsOfRecommendArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};


export type PostPostsOfFollowArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};


export type PostPostsOfSelfArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};

export type PostCollectionEntity = {
  __typename?: 'PostCollectionEntity';
  id: Scalars['Int'];
  postId: Scalars['Int'];
  userId: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isDeleted: Scalars['Boolean'];
};

export type PostEntity = {
  __typename?: 'PostEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  content: Scalars['String'];
  sharingId: Scalars['Int'];
  likesCount?: Maybe<Scalars['Int']>;
  repliesCount?: Maybe<Scalars['Int']>;
  collectionsCount?: Maybe<Scalars['Int']>;
  visible: PostVisible;
  location: Scalars['String'];
  editedAt: Scalars['Date'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isDeleted: Scalars['Boolean'];
  examineStatus: ExamineStatus;
  isLike?: Maybe<Scalars['Boolean']>;
  hasPicture?: Maybe<Scalars['Boolean']>;
  hasFormula?: Maybe<Scalars['Boolean']>;
  hasVideo?: Maybe<Scalars['Boolean']>;
  hasLink?: Maybe<Scalars['Boolean']>;
  relation?: Maybe<Relation>;
  likes?: Maybe<Array<Maybe<PostLikeEntity>>>;
  replies?: Maybe<Array<Maybe<PostReplyEntity>>>;
  isCollected?: Maybe<Scalars['Boolean']>;
  userInfo?: Maybe<UserEntity>;
};


export type PostEntityLikesArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};


export type PostEntityRepliesArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};

export type PostExamineRecordEntity = {
  __typename?: 'PostExamineRecordEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  postId: Scalars['Int'];
  csUserId: Scalars['Int'];
  content: Scalars['String'];
  isBanned: Scalars['Boolean'];
  createdAt: Scalars['Date'];
};

export type PostInput = {
  userId: Scalars['Int'];
  content?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  visible?: Maybe<PostVisible>;
};

export type PostLikeEntity = {
  __typename?: 'PostLikeEntity';
  id: Scalars['Int'];
  postId: Scalars['Int'];
  userId: Scalars['Int'];
  status: MessageStatus;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isDeleted: Scalars['Boolean'];
  userInfo?: Maybe<UserEntity>;
};

export type PostReplyEntity = {
  __typename?: 'PostReplyEntity';
  id: Scalars['Int'];
  postId: Scalars['Int'];
  userId: Scalars['Int'];
  parentId: Scalars['Int'];
  replyId: Scalars['Int'];
  content: Scalars['String'];
  status: MessageStatus;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isDeleted: Scalars['Boolean'];
  replies?: Maybe<Array<Maybe<PostReplyEntity>>>;
  userInfo?: Maybe<UserEntity>;
};


export type PostReplyEntityRepliesArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};

export type PostSearchData = {
  __typename?: 'PostSearchData';
  total: Scalars['Int'];
  list?: Maybe<Array<Maybe<PostEntity>>>;
};

export type PostSearchInput = {
  userId?: Maybe<Scalars['Int']>;
  sharingId?: Maybe<Scalars['Int']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  examineStatus?: Maybe<ExamineStatus>;
};

export type PostSingleTrainingInput = {
  userId: Scalars['Int'];
  initState: Array<Array<Scalars['Int']>>;
  attitudeData: Array<AttitudeData>;
  moveData: Array<MoveData>;
  totData: StatisticsInput;
  cfopData: CfopInput;
  type: TrainingType;
};

export type PostUpdateInput = {
  postId: Scalars['Int'];
  content?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  visible?: Maybe<PostVisible>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export enum PostVisible {
  EachConcern = 'EACH_CONCERN',
  Everyone = 'EVERYONE',
  MyConcern = 'MY_CONCERN',
  MyFan = 'MY_FAN',
  Self = 'SELF'
}

export type PvpChallenge = {
  __typename?: 'PvpChallenge';
  challengesOfSend?: Maybe<Array<Maybe<ListPvpChallengeInfo>>>;
  challengesOfReceived?: Maybe<Array<Maybe<ListPvpChallengeInfo>>>;
};


export type PvpChallengeChallengesOfSendArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};


export type PvpChallengeChallengesOfReceivedArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};

export type PvpChallengeEntity = {
  __typename?: 'PvpChallengeEntity';
  id: Scalars['Int'];
  challenger: Scalars['Int'];
  target: Scalars['Int'];
  gameId: Scalars['Int'];
  content: Scalars['String'];
  isAccepted: Scalars['Boolean'];
  reason: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type PvpChallengeMessageUpdateInput = {
  messageId: Scalars['Int'];
  challengeId: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  user?: Maybe<User>;
  userAddr?: Maybe<UserAddrEntity>;
  awardState?: Maybe<CommunicationState>;
  scoreRankList: Array<UserEntity>;
  curSeason: SeasonEntity;
  trainingDetail?: Maybe<TrainingDataEntity>;
  cubeInfoById?: Maybe<MagicCubeEntity>;
  postInfoById?: Maybe<PostEntity>;
  replyInfoById?: Maybe<PostReplyEntity>;
  cubeFirmware: CubeFirmware;
  pkDataDetail?: Maybe<WholePkData>;
  pkDataByChallengeId?: Maybe<Array<PkDataEntity>>;
  cubeCheckFilterList?: Maybe<Array<CubeCheckEntity>>;
  getLimitedTimeChallengeById: LimitedTimeChallengeEntity;
  historyLimitedTimeChallengeList: Array<LimitedTimeChallengeEntity>;
  currentChallengeList?: Maybe<Array<Maybe<LimitedTimeChallengeEntity>>>;
  getLimitedTimeChallengeRecordById: LimitedTimeChallengeRecordEntity;
};


export type QueryUserArgs = {
  userId: Scalars['Int'];
};


export type QueryUserAddrArgs = {
  userId: Scalars['Int'];
};


export type QueryAwardStateArgs = {
  awardId: Scalars['Int'];
};


export type QueryScoreRankListArgs = {
  seasonId?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};


export type QueryTrainingDetailArgs = {
  trainingId: Scalars['Int'];
};


export type QueryCubeInfoByIdArgs = {
  magicCubeId: Scalars['String'];
};


export type QueryPostInfoByIdArgs = {
  postId: Scalars['Int'];
};


export type QueryReplyInfoByIdArgs = {
  replyId: Scalars['Int'];
};


export type QueryPkDataDetailArgs = {
  pkDataId: Scalars['Int'];
};


export type QueryPkDataByChallengeIdArgs = {
  challengeId: Scalars['Int'];
};


export type QueryCubeCheckFilterListArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
  filterInput: CubeCheckFilterInput;
};


export type QueryGetLimitedTimeChallengeByIdArgs = {
  limitedId: Scalars['Int'];
};


export type QueryHistoryLimitedTimeChallengeListArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};


export type QueryCurrentChallengeListArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};


export type QueryGetLimitedTimeChallengeRecordByIdArgs = {
  limitedRecordId: Scalars['Int'];
};

export enum Relation {
  Stranger = 'STRANGER',
  MyConcern = 'MY_CONCERN',
  MyFan = 'MY_FAN',
  MyFriend = 'MY_FRIEND',
  Self = 'SELF'
}

export type ReplyInput = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
  replyId: Scalars['Int'];
  content: Scalars['String'];
};

export type ReplySearchData = {
  __typename?: 'ReplySearchData';
  total: Scalars['Int'];
  list?: Maybe<Array<Maybe<PostReplyEntity>>>;
};

export type ReplySearchInput = {
  postId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
  replyId?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  status?: Maybe<MessageStatus>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ReplyUpdateInput = {
  replyId: Scalars['Int'];
  content?: Maybe<Scalars['String']>;
  status?: Maybe<MessageStatus>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export enum ReportHandleType {
  DoNothing = 'DO_NOTHING',
  BanUser = 'BAN_USER',
  BanPost = 'BAN_POST',
  BanUserAndBanPost = 'BAN_USER_AND_BAN_POST'
}

export type ReportPkInput = {
  userId: Scalars['Int'];
  reportGameId: Scalars['Int'];
  reportUserId?: Maybe<Scalars['Int']>;
  reportReason: Scalars['String'];
};

export type ReportPostInput = {
  userId: Scalars['Int'];
  reportPostId: Scalars['Int'];
  reportUserId?: Maybe<Scalars['Int']>;
  reportReason: Scalars['String'];
};

export type ReportRecordEntity = {
  __typename?: 'ReportRecordEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  reportType: ReportType;
  reportHandleType?: Maybe<ReportHandleType>;
  reportUserId?: Maybe<Scalars['Int']>;
  reportPostId?: Maybe<Scalars['Int']>;
  reportGameId?: Maybe<Scalars['Int']>;
  reportReason: Scalars['String'];
  isHandled?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  csUserId?: Maybe<Scalars['Int']>;
};

export enum ReportType {
  User = 'USER',
  Post = 'POST',
  Pk = 'PK'
}

export type ReportUserInput = {
  userId: Scalars['Int'];
  reportUserId: Scalars['Int'];
  reportReason: Scalars['String'];
};

export type SearchData = {
  __typename?: 'SearchData';
  total: Scalars['Int'];
};

export type SeasonEntity = {
  __typename?: 'SeasonEntity';
  id: Scalars['Int'];
  name: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
};

export type SelectCollection = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type SelectLike = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

export enum Sex {
  Woman = 'WOMAN',
  Man = 'MAN'
}

export type Statistics = {
  __typename?: 'Statistics';
  timeUse: Scalars['Float'];
  stepNum: Scalars['Int'];
  observation: Scalars['Float'];
  smoothness: Scalars['Float'];
  tps: Scalars['Float'];
};

export type StatisticsInput = {
  timeUse: Scalars['Float'];
  stepNum: Scalars['Int'];
  observation: Scalars['Float'];
  rollBack: Scalars['Float'];
  smoothness: Scalars['Float'];
  tps: Scalars['Float'];
};

export type TimeAndRank = {
  __typename?: 'TimeAndRank';
  time: Scalars['Float'];
  rank: Scalars['Int'];
};

export type TrainingAverageVo = {
  __typename?: 'TrainingAverageVo';
  bestAO5?: Maybe<Scalars['Float']>;
  bestAO12?: Maybe<Scalars['Float']>;
  bestAO100?: Maybe<Scalars['Float']>;
  latestAO5?: Maybe<Scalars['Float']>;
  latestAO12?: Maybe<Scalars['Float']>;
  latestAO100?: Maybe<Scalars['Float']>;
};

export type TrainingAvgDataVo = {
  __typename?: 'TrainingAvgDataVo';
  timeUse?: Maybe<Scalars['Float']>;
  stepNum?: Maybe<Scalars['Float']>;
  observation?: Maybe<Scalars['Float']>;
  smoothness?: Maybe<Scalars['Float']>;
  tps?: Maybe<Scalars['Float']>;
};

export type TrainingData = {
  __typename?: 'TrainingData';
  dataList?: Maybe<Array<Maybe<TrainingDataEntity>>>;
  averageData?: Maybe<TrainingAvgDataVo>;
  statisticsData?: Maybe<TrainingStatisticsVo>;
  bestAO?: Maybe<TrainingAverageVo>;
};


export type TrainingDataDataListArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};


export type TrainingDataStatisticsDataArgs = {
  offset?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
};

export type TrainingDataEntity = {
  __typename?: 'TrainingDataEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  playbackId: Scalars['Int'];
  type?: Maybe<TrainingType>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  detailData?: Maybe<PlaybackEntity>;
  user?: Maybe<User>;
};

export type TrainingStatisticsVo = {
  __typename?: 'TrainingStatisticsVo';
  id: Array<Scalars['Int']>;
  timeUse: Array<Scalars['Float']>;
  stepNum: Array<Scalars['Int']>;
  tps: Array<Scalars['Float']>;
};

export enum TrainingType {
  ClassicalTrain = 'CLASSICAL_TRAIN',
  FreedomTrain = 'FREEDOM_TRAIN',
  ChallengeTrain = 'CHALLENGE_TRAIN'
}

export type TrainSearchData = {
  __typename?: 'TrainSearchData';
  total: Scalars['Int'];
  list?: Maybe<Array<Maybe<TrainingDataEntity>>>;
};

export type TrainSearchInput = {
  userId?: Maybe<Scalars['Int']>;
  type?: Maybe<TrainingType>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UnbindCubeInput = {
  userId: Scalars['Float'];
  mgcId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  userInfo?: Maybe<UserEntity>;
  messages: Message;
  magicCube: MagicCube;
  post: Post;
  trainingData: TrainingData;
  follow: FollowByUserId;
  notification: Notification;
  media: Media;
  challenge: PvpChallenge;
  pkData: PkData;
  jwt: Verification;
  limitedTimeChallenge: LimitedTimeChallenge;
  role: Scalars['String'];
};


export type UserPkDataArgs = {
  type?: Maybe<GameType>;
};


export type UserLimitedTimeChallengeArgs = {
  limitedId: Scalars['Int'];
};

export type UserAddrEntity = {
  __typename?: 'UserAddrEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  phone: Scalars['String'];
  name: Scalars['String'];
  region: Scalars['String'];
  state: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserEntity = {
  __typename?: 'UserEntity';
  id: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  areaCode?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  loginName?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
  email?: Maybe<Scalars['String']>;
  qq?: Maybe<Scalars['String']>;
  weChat?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['String']>;
  homePicture?: Maybe<Scalars['String']>;
  followersCount?: Maybe<Scalars['Int']>;
  followsCount?: Maybe<Scalars['Int']>;
  likedCount?: Maybe<Scalars['Int']>;
  repliedCount?: Maybe<Scalars['Int']>;
  collectedCount?: Maybe<Scalars['Int']>;
  msgSet?: Maybe<MsgSet>;
  pkSet?: Maybe<PkSet>;
  score?: Maybe<Scalars['Int']>;
  bestTime?: Maybe<Scalars['Float']>;
  method?: Maybe<Scalars['Int']>;
  reduction?: Maybe<Scalars['Int']>;
  gameRoomId?: Maybe<Scalars['Int']>;
  pkStateNum?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isDeleted: Scalars['Boolean'];
  isBanned?: Maybe<Scalars['Boolean']>;
  lastLoginTime?: Maybe<Scalars['Date']>;
  role?: Maybe<Scalars['String']>;
  rankInfo?: Maybe<UserScoreRankEntity>;
};


export type UserEntityRankInfoArgs = {
  seasonId?: Maybe<Scalars['Int']>;
};

export type UserScoreRankEntity = {
  __typename?: 'UserScoreRankEntity';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  seasonId: Scalars['Int'];
  score: Scalars['Int'];
  rank?: Maybe<Scalars['Int']>;
  bestScore: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type UserSearchData = {
  __typename?: 'UserSearchData';
  total: Scalars['Int'];
  list?: Maybe<Array<Maybe<UserEntity>>>;
};

export type UserUpdByServantInput = {
  userId: Scalars['Int'];
  nickName?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  homePicture?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
  msgSet?: Maybe<MsgSet>;
  pkSet?: Maybe<PkSet>;
  phone?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type Verification = {
  __typename?: 'Verification';
  clientJwt: Scalars['String'];
  gameJwt: GetGameJwtVo;
  matchJwt: Scalars['String'];
  ftfMatchJwt: Scalars['String'];
  rankMatchJwt: Scalars['String'];
  challengeJwt: Scalars['String'];
  pushJwt: Scalars['String'];
};


export type VerificationMatchJwtArgs = {
  robot?: Maybe<Scalars['Int']>;
};


export type VerificationFtfMatchJwtArgs = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};


export type VerificationRankMatchJwtArgs = {
  robot?: Maybe<Scalars['Int']>;
};


export type VerificationChallengeJwtArgs = {
  targetId: Scalars['Int'];
};

export type VerificationCodeEntity = {
  __typename?: 'VerificationCodeEntity';
  id: Scalars['Int'];
  vCode: Scalars['String'];
  areaCode?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  isDeleted: Scalars['Boolean'];
};

export type WholePkData = {
  __typename?: 'WholePkData';
  id: Scalars['Int'];
  gameId: Scalars['Int'];
  userId: Scalars['Int'];
  playbackId: Scalars['Int'];
  createdAt: Scalars['Date'];
  scoreChange: Scalars['Int'];
  updatedAt: Scalars['Date'];
  isFinished: Scalars['Boolean'];
  type: Scalars['Int'];
  detailData?: Maybe<PlaybackEntity>;
  user?: Maybe<User>;
  gameInfo?: Maybe<GameInfo>;
};
