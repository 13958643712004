import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
import { InspectionStaticItem } from '../class/inspection-static-item';
import { InspectionDynamicItem } from '../class/inspection-dynamic-item';
import { Info } from '../../generated/graphql';

export enum InspRes {
  VALID = "valid",
  INVALID = "invalid",
  NOTINSPECTED = "",
}

export enum InspItemIcon {
  VALID = "#icon-chenggong",
  INVALID = '#icon-shibai',
  NOTINSPECTED = "#icon-dengdaiqueren",
}

export enum InspStep {
  STA_STEP = 0,
  DYN_STEP = 1,
  RES_STEP = 2,
  GPIO_STEP = 3,
}

export enum InspDynStep {
  ATTI_STEP = 0,
  AXIS_STEP = 1,
}

@Injectable({
  providedIn: 'root'
})
export class BleInspectionItemService {

  constructor() { }

  //6个侧轴检查项
  public faceItem0: InspectionDynamicItem = new InspectionDynamicItem(0, '黄')
  public faceItem1: InspectionDynamicItem = new InspectionDynamicItem(1, '橘')
  public faceItem2: InspectionDynamicItem = new InspectionDynamicItem(2, '蓝')
  public faceItem3: InspectionDynamicItem = new InspectionDynamicItem(3, '红')
  public faceItem4: InspectionDynamicItem = new InspectionDynamicItem(4, '绿')
  public faceItem5: InspectionDynamicItem = new InspectionDynamicItem(5, '白')

  //电压检查项
  public voltageItem: InspectionStaticItem = new InspectionStaticItem(6, '电压')
  //初始化逻辑魔方姿态
  public cstateItem: InspectionStaticItem = new InspectionStaticItem(7, '初始化逻辑魔方姿态')
  //九轴参数校准
  public sensorsItem: InspectionStaticItem = new InspectionStaticItem(8, '九轴参数校准')
  //初始化滤波参数
  public filterItem: InspectionStaticItem = new InspectionStaticItem(9, '初始化滤波算法')
  //ID与序列号合法性
  public identityItem: InspectionStaticItem = new InspectionStaticItem(10, 'ID与序列号合法性')
  //OAD版本
  public oadItem: InspectionStaticItem = new InspectionStaticItem(11, 'OAD版本号')

  //姿态检测
  public attitudeItem: InspectionDynamicItem = new InspectionDynamicItem(12, '魔方姿态检测')

  //stepper Subject，静态检查执行完后next相应的ID，提示打开动态检查；动态检查完后同样next相应的ID，提示打开检查结果
  private _stepInspectItem$: Subject<number> = new Subject<number>()
  //动态检查中的折叠面板Subject，每当一个折叠面板打开，next相应的面板ID
  private _dynInspStep$: Subject<number> = new Subject<number>()
  //info Subject
  private _inspectInfoItem$: Subject<Info> = new Subject<Info>()
  //info object
  private _infoItem: Info = { reason: [], moveData: [] }

  public getInfoItem() {
    return this._infoItem;
  }

  public setInfoItem(key: string, value: any) {
    if (key === 'connect') {
      this.clearInfoItem()
      // this._inspectInfoItem$.next(this._infoItem);
      return;
    }
    console.log('改变inspection-item信息', key, value);
    if (key == 'reason' || key == 'moveData') {
      if (value instanceof Array) {
        for (let item of value) {
          this._infoItem[key].push(item);
        }
      } else {
        this._infoItem[key].push(value);
      }
    } else {
      this._infoItem[key] = value;
    }
    this._inspectInfoItem$.next(this._infoItem);
  }

  public clearInfoItem() {
    this._infoItem = { moveData: [], reason: [] }
    this._inspectInfoItem$.next(this._infoItem);
  }

  public get stepInspectItem$() {
    return this._stepInspectItem$
  }
  public get dynInspStep$() {
    return this._dynInspStep$
  }
  public get inspectInfoItem$() {
    return this._inspectInfoItem$
  }
}
