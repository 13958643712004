import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { zh_CN } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { from } from 'rxjs';
// imports
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzProgressModule } from 'ng-zorro-antd/progress'
import { IonicStorageModule } from '@ionic/storage';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDividerModule } from 'ng-zorro-antd/divider'
import { NzGridModule } from 'ng-zorro-antd/grid'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
// declarations
import { BleSignalInspectionComponent } from './components/ble-signal-inspection/ble-signal-inspection.component';
import { OadItemInspectionComponent } from './components/oad-item-inspection/oad-item-inspection.component';
import { BatteryInspectionComponent } from './components/battery-inspection/battery-inspection.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { GraphQLModule } from './graphql.module';
import { CommonModule } from '@angular/common';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzNotificationModule } from 'ng-zorro-antd/notification'
// providers
import { WorkPositionService } from './services/work-position.service';
import { AxisSignalInspectionComponent } from './components/axis-signal-inspection/axis-signal-inspection.component';
import { AxisItemInspectionComponent } from './components/axis-item-inspection/axis-item-inspection.component';
import { AttitudeItemInspectionComponent } from './components/attitude-item-inspection/attitude-item-inspection.component';
import { PinmapItemInspectionComponent } from './components/pinmap-item-inspection/pinmap-item-inspection.component';
import { CstateItemInspectionComponent } from './components/cstate-item-inspection/cstate-item-inspection.component';
import { SensorsItemInspectionComponent } from './components/sensors-item-inspection/sensors-item-inspection.component';
import { FilterItemInspectionComponent } from './components/filter-item-inspection/filter-item-inspection.component';
import { IdentityItemInspectionComponent } from './components/identity-item-inspection/identity-item-inspection.component';
import { CubeContainerComponent } from './components/cube-container/cube-container.component';
import { CubeComponent } from './components/cube/cube.component';
import { WorkPositionInspectionComponent } from './components/work-position-inspection/work-position-inspection.component';

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent,
    BleSignalInspectionComponent,
    OadItemInspectionComponent,
    BatteryInspectionComponent,
    SignInComponent,
    AxisSignalInspectionComponent,
    AxisItemInspectionComponent,
    AttitudeItemInspectionComponent,
    PinmapItemInspectionComponent,
    CstateItemInspectionComponent,
    SensorsItemInspectionComponent,
    FilterItemInspectionComponent,
    IdentityItemInspectionComponent,
    CubeContainerComponent,
    CubeComponent,
    WorkPositionInspectionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzButtonModule,
    NzToolTipModule,
    NzSelectModule,
    NzCardModule,
    NzProgressModule,
    GraphQLModule,
    NzMessageModule,
    NzModalModule,
    NzFormModule,
    NzInputModule,
    NzDividerModule,
    NzGridModule,
    CommonModule,
    NzSpinModule,
    NzNotificationModule,
    IonicStorageModule.forRoot({
      name: '__mydb',
      driverOrder: ['sqlite', 'websql', 'indexeddb']
    }),
  ],
  // providers: [{ provide: NZ_I18N, useValue: zh_CN }],
  providers: [
    WorkPositionService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
