import { CubeCommand, normalizeCubeCommands, RotateDirection, Move, MoveStage } from "./cube-command";

export enum Color {
  UNKNOW = -2,
  Y = 0,
  O = 1,
  B = 2,
  R = 3,
  G = 4,
  W = 5,
}

export enum Axis {
  U = 0,
  L = 1,
  F = 2,
  R = 3,
  B = 4,
  D = 5
}

export type FaceState = [Color, Color, Color, Color, Color, Color, Color, Color, Color]
/** CubeState is
 *
 *             |************|
 *             |*U1**U2**U3*|
 *             |*U4**U5**U6*|
 *             |*U7**U8**U9*|
 *             |************|
 * ************|************|************|************|
 * *L1**L2**L3*|*F1**F2**F3*|*R1**R2**R3*|*B1**B2**B3*|
 * *L4**L5**L6*|*F4**F5**F6*|*R4**R5**R6*|*B4**B5**B6*|
 * *L7**L8**L9*|*F7**F8**F9*|*R7**R8**R9*|*B7**B8**B9*|
 * ************|************|************|************|
 *             |************|
 *             |*D1**D2**D3*|
 *             |*D4**D5**D6*|
 *             |*D7**D8**D9*|
 *             |************|
 *
 * -> [[U1, U2, ..., U9], [L1, U2, ..., L9], ...,[B1, B2, ..., B9], [D1, D2, ..., D9]]
 */
export type CubeState = [FaceState, FaceState, FaceState, FaceState, FaceState, FaceState]

export class Cube {

  // 还原状态的魔方颜色状态
  public static readonly ORIGIN_CUBE: CubeState = [
    [Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y, Color.Y],
    [Color.O, Color.O, Color.O, Color.O, Color.O, Color.O, Color.O, Color.O, Color.O],
    [Color.B, Color.B, Color.B, Color.B, Color.B, Color.B, Color.B, Color.B, Color.B],
    [Color.R, Color.R, Color.R, Color.R, Color.R, Color.R, Color.R, Color.R, Color.R],
    [Color.G, Color.G, Color.G, Color.G, Color.G, Color.G, Color.G, Color.G, Color.G],
    [Color.W, Color.W, Color.W, Color.W, Color.W, Color.W, Color.W, Color.W, Color.W]
  ]
  public get originCube():CubeState{
    return JSON.parse(JSON.stringify(Cube.ORIGIN_CUBE))
  }
  public static getNewMadCube(): Cube {
    const cube = new Cube()
    for (let i = 0; i < 400; i++) {
      cube.rotate(
        Math.floor(Math.random() * 6),
        Math.random() > 0.5 ? 1 : -1
      )
    }
    console.log(cube)
    return cube
  }
  private _cubeState: CubeState = JSON.parse(JSON.stringify(Cube.ORIGIN_CUBE))

  private _pauseCounts: Array<number> = [0, 0, 0, 0, 0, 0]

  // 避免外界改变造成魔方状态出错
  public set cubeState(value: CubeState) {
    this._cubeState = JSON.parse(JSON.stringify(value))
  }

  // 避免外界改变造成魔方状态出错
  public get cubeState(): CubeState {
    return JSON.parse(JSON.stringify(this._cubeState))
  }

  // 避免外界改变造成魔方状态出错
  public set pauseCounts(value: Array<number>) {
    this._pauseCounts = JSON.parse(JSON.stringify(value))
  }
  // 避免外界改变造成魔方状态出错
  public get pauseCounts(): Array<number> {
    return JSON.parse(JSON.stringify(this._pauseCounts))
  }
  constructor(cubeState: CubeState = null, pauseCounts: Array<number> = null) {
    if (cubeState) {
      this.resetCubeState(cubeState)
    }
    if (pauseCounts) {
      this._pauseCounts = pauseCounts
    }
  }
  public resetCubeState(cubeState: CubeState) {
    this._cubeState = JSON.parse(JSON.stringify(cubeState))
  }

  public runCommands(commands: Array<CubeCommand> | string) {
    let cmds: Array<CubeCommand> = typeof commands == 'string' ? normalizeCubeCommands(commands) : commands
    console.log(typeof cmds)
    for (let cmd of cmds) {
      switch (cmd[1]) {
        case '\'':
          this.rotate(Axis[cmd[0]], RotateDirection.ANTICLOCKWISE)
          break;
        case '2':
          this.rotate(Axis[cmd[0]], RotateDirection.CLOCKWISE)
          this.rotate(Axis[cmd[0]], RotateDirection.CLOCKWISE)
          break;
        default:
          this.rotate(Axis[cmd[0]], RotateDirection.CLOCKWISE)
          break;
      }
    }
  }

  public rotate(axis: Axis, rd: RotateDirection) {
    if (rd === RotateDirection.CLOCKWISE) {
      switch (axis) {
        case 0:
          this.rotateU()
          break
        case 1:
          this.rotateL()
          break
        case 2:
          this.rotateF()
          break
        case 3:
          this.rotateR()
          break
        case 4:
          this.rotateB()
          break
        case 5:
          this.rotateD()
          break
      }
    } else if (rd === RotateDirection.ANTICLOCKWISE) {
      switch (axis) {
        case 0:
          this.rotateU()
          this.rotateU()
          this.rotateU()
          break
        case 1:
          this.rotateL()
          this.rotateL()
          this.rotateL()
          break
        case 2:
          this.rotateF()
          this.rotateF()
          this.rotateF()
          break
        case 3:
          this.rotateR()
          this.rotateR()
          this.rotateR()
          break
        case 4:
          this.rotateB()
          this.rotateB()
          this.rotateB()
          break
        case 5:
          this.rotateD()
          this.rotateD()
          this.rotateD()
          break
      }
    }
  }

  public runMove(axis: Axis, circle: number): Move {

    if (circle > 0) {
      this._pauseCounts[axis]++
      // 处理转动不满 90 度就转回的特殊情况
      if (this._pauseCounts[axis] === 0) {
        return {
          axis,
          rotateDirection: RotateDirection.NONE,
          moveStage: MoveStage.END
        }
      } else if (this._pauseCounts[axis] === 1) {
        return {
          axis,
          rotateDirection: RotateDirection.CLOCKWISE,
          moveStage: MoveStage.START
        }
      } else if (this._pauseCounts[axis] < 9) {
        return {
          axis,
          rotateDirection: RotateDirection.CLOCKWISE,
          moveStage: MoveStage.MOVING
        }
      } else if (this._pauseCounts[axis] === 9) {
        this.rotate(axis, RotateDirection.CLOCKWISE)
        this._pauseCounts[axis] = 0
        return {
          axis,
          rotateDirection: RotateDirection.CLOCKWISE,
          moveStage: MoveStage.END
        }
      }
    } else if (circle < 0) {
      this._pauseCounts[axis]--
      // 处理转动不满 90 度就转回的特殊情况
      if (this._pauseCounts[axis] === 0) {
        return {
          axis,
          rotateDirection: RotateDirection.NONE,
          moveStage: MoveStage.END
        }
      } else if (this._pauseCounts[axis] === -1) {
        return {
          axis,
          rotateDirection: RotateDirection.ANTICLOCKWISE,
          moveStage: MoveStage.START
        }
      } else if (this._pauseCounts[axis] > -9) {
        return {
          axis,
          rotateDirection: RotateDirection.ANTICLOCKWISE,
          moveStage: MoveStage.MOVING
        }
      } else if (this._pauseCounts[axis] === -9) {
        this.rotate(axis, RotateDirection.ANTICLOCKWISE)
        this._pauseCounts[axis] = 0
        return {
          axis,
          rotateDirection: RotateDirection.ANTICLOCKWISE,
          moveStage: MoveStage.END
        }
      }
    }
  }

  private rotateU() {
    this.swap([0, 0], [0, 2], [0, 8], [0, 6]);
    this.swap([0, 1], [0, 5], [0, 7], [0, 3]);
    this.swap([2, 2], [1, 2], [4, 2], [3, 2]);
    this.swap([2, 1], [1, 1], [4, 1], [3, 1]);
    this.swap([2, 0], [1, 0], [4, 0], [3, 0]);
  }

  private rotateL() {
    this.swap([1, 0], [1, 2], [1, 8], [1, 6]);
    this.swap([1, 1], [1, 5], [1, 7], [1, 3]);
    this.swap([0, 0], [2, 0], [5, 0], [4, 8]);
    this.swap([0, 3], [2, 3], [5, 3], [4, 5]);
    this.swap([0, 6], [2, 6], [5, 6], [4, 2]);
  }
  private rotateF() {
    this.swap([2, 0], [2, 2], [2, 8], [2, 6]);
    this.swap([2, 1], [2, 5], [2, 7], [2, 3]);
    this.swap([0, 6], [3, 0], [5, 2], [1, 8]);
    this.swap([0, 7], [3, 3], [5, 1], [1, 5]);
    this.swap([0, 8], [3, 6], [5, 0], [1, 2]);
  }
  private rotateR() {
    this.swap([3, 0], [3, 2], [3, 8], [3, 6]);
    this.swap([3, 1], [3, 5], [3, 7], [3, 3]);
    this.swap([0, 8], [4, 0], [5, 8], [2, 8]);
    this.swap([0, 5], [4, 3], [5, 5], [2, 5]);
    this.swap([0, 2], [4, 6], [5, 2], [2, 2]);
  }
  private rotateB() {
    this.swap([4, 0], [4, 2], [4, 8], [4, 6]);
    this.swap([4, 1], [4, 5], [4, 7], [4, 3]);
    this.swap([0, 2], [1, 0], [5, 6], [3, 8]);
    this.swap([0, 1], [1, 3], [5, 7], [3, 5]);
    this.swap([0, 0], [1, 6], [5, 8], [3, 2]);
  }
  private rotateD() {
    this.swap([5, 0], [5, 2], [5, 8], [5, 6]);
    this.swap([5, 1], [5, 5], [5, 7], [5, 3]);
    this.swap([2, 6], [3, 6], [4, 6], [1, 6]);
    this.swap([2, 7], [3, 7], [4, 7], [1, 7]);
    this.swap([2, 8], [3, 8], [4, 8], [1, 8]);
  }

  private swap(a: [number, number], b: [number, number], c: [number, number], d: [number, number]): void {
    const e = this._cubeState[d[0]][d[1]];
    this._cubeState[d[0]][d[1]] = this._cubeState[c[0]][c[1]];
    this._cubeState[c[0]][c[1]] = this._cubeState[b[0]][b[1]];
    this._cubeState[b[0]][b[1]] = this._cubeState[a[0]][a[1]];
    this._cubeState[a[0]][a[1]] = e;
  }
  public isOrigin() {
    //console.log(JSON.stringify(this._cubeState) , JSON.stringify(Cube.ORIGIN_CUBE), JSON.stringify(this._pauseCounts))
    return /*this.isCube() &&*/ JSON.stringify(this._cubeState) === JSON.stringify(Cube.ORIGIN_CUBE)
  }
  /**
   * 当前Cube是否为正方体（每个面都已经归位）
   */
  public isCube(): boolean {
    return JSON.stringify(this._pauseCounts) === JSON.stringify([0, 0, 0, 0, 0, 0])
  }
}


export enum LblStep {
  NONE,
  FIRST_LAYER_EDGE, // c 1
  FIRST_LAYER_CORNERS,
  SECOND_LAYER, // f 3
  TOP_EDGE,
  TOP_CORNERS, // o 5
  THIRD_LAYER_CORNERS,
  THIRD_LAYER_EDGE // p 7
}

export interface LblStepResult {
  currStep: LblStep,
  currBottom: Color
}

export function getCurrentLblStep(cube: CubeState): LblStepResult {
  if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8],
      [1, 0], [1, 1], [1, 2], [1, 3], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8],
      [2, 0], [2, 1], [2, 2], [2, 3], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8],
      [3, 0], [3, 1], [3, 2], [3, 3], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8],
      [4, 0], [4, 1], [4, 2], [4, 3], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8],
      [5, 0], [5, 1], [5, 2], [5, 3], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.THIRD_LAYER_EDGE,
      currBottom: Color.UNKNOW
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8],
      [1, 0], [1, 1], [1, 2], [1, 3], [1, 4], [1, 5], [1, 6], [1, 8],
      [2, 0], [2, 1], [2, 2], [2, 3], [2, 4], [2, 5], [2, 6], [2, 8],
      [3, 0], [3, 1], [3, 2], [3, 3], [3, 4], [3, 5], [3, 6], [3, 8],
      [4, 0], [4, 1], [4, 2], [4, 3], [4, 4], [4, 5], [4, 6], [4, 8],
      [5, 0], [5, 1], [5, 2], [5, 3], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.THIRD_LAYER_CORNERS,
      currBottom: Color.Y
    }
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 6], [0, 7], [0, 8],
      [1, 0], [1, 1], [1, 2], [1, 3], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8],
      [2, 0], [2, 1], [2, 2], [2, 3], [2, 4], [2, 6], [2, 7], [2, 8],
      [3, 0], [3, 1], [3, 2], [3, 3], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8],
      [4, 0], [4, 1], [4, 2], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8],
      [5, 0], [5, 1], [5, 2], [5, 3], [5, 4], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.THIRD_LAYER_CORNERS,
      currBottom: Color.O
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 2], [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8],
      [1, 0], [1, 1], [1, 2], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8],
      [2, 0], [2, 1], [2, 2], [2, 3], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8],
      [3, 0], [3, 1], [3, 2], [3, 3], [3, 4], [3, 6], [3, 7], [3, 8],
      [4, 0], [4, 1], [4, 2], [4, 3], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8],
      [5, 0], [5, 1], [5, 2], [5, 3], [5, 4], [5, 5], [5, 6], [5, 8]
    ])) {
    return {
      currStep: LblStep.THIRD_LAYER_CORNERS,
      currBottom: Color.B
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8],
      [1, 0], [1, 1], [1, 2], [1, 3], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8],
      [2, 0], [2, 1], [2, 2], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8],
      [3, 0], [3, 1], [3, 2], [3, 3], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8],
      [4, 0], [4, 1], [4, 2], [4, 3], [4, 4], [4, 6], [4, 7], [4, 8],
      [5, 0], [5, 1], [5, 2], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.THIRD_LAYER_CORNERS,
      currBottom: Color.R
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 5], [0, 6], [0, 8],
      [1, 0], [1, 1], [1, 2], [1, 3], [1, 4], [1, 6], [1, 7], [1, 8],
      [2, 0], [2, 1], [2, 2], [2, 3], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8],
      [3, 0], [3, 1], [3, 2], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8],
      [4, 0], [4, 1], [4, 2], [4, 3], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8],
      [5, 0], [5, 2], [5, 3], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.THIRD_LAYER_CORNERS,
      currBottom: Color.G
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8],
      [1, 0], [1, 2], [1, 3], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8],
      [2, 0], [2, 2], [2, 3], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8],
      [3, 0], [3, 2], [3, 3], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8],
      [4, 0], [4, 2], [4, 3], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8],
      [5, 0], [5, 1], [5, 2], [5, 3], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.THIRD_LAYER_CORNERS,
      currBottom: Color.W
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8],
      [1, 0], [1, 1], [1, 2], [1, 3], [1, 4], [1, 5],
      [2, 0], [2, 1], [2, 2], [2, 3], [2, 4], [2, 5],
      [3, 0], [3, 1], [3, 2], [3, 3], [3, 4], [3, 5],
      [4, 0], [4, 1], [4, 2], [4, 3], [4, 4], [4, 5],
      [5, 0], [5, 1], [5, 2], [5, 3], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.TOP_CORNERS,
      currBottom: Color.Y
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 3], [0, 4], [0, 6], [0, 7],
      [1, 0], [1, 1], [1, 2], [1, 3], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8],
      [2, 0], [2, 1], [2, 3], [2, 4], [2, 6], [2, 7],
      [3, 0], [3, 1], [3, 2], [3, 3], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8],
      [4, 1], [4, 2], [4, 4], [4, 5], [4, 7], [4, 8],
      [5, 0], [5, 1], [5, 3], [5, 4], [5, 6], [5, 7]
    ])) {
    return {
      currStep: LblStep.TOP_CORNERS,
      currBottom: Color.O
    };
  } else if (isRight(cube,
    [
      [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8],
      [1, 1], [1, 2], [1, 4], [1, 5], [1, 7], [1, 8],
      [2, 0], [2, 1], [2, 2], [2, 3], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8],
      [3, 0], [3, 1], [3, 3], [3, 4], [3, 6], [3, 7],
      [4, 0], [4, 1], [4, 2], [4, 3], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8],
      [5, 0], [5, 1], [5, 2], [5, 3], [5, 4], [5, 5]
    ])) {
    return {
      currStep: LblStep.TOP_CORNERS,
      currBottom: Color.B
    };
  } else if (isRight(cube,
    [
      [0, 1], [0, 2], [0, 4], [0, 5], [0, 7], [0, 8],
      [1, 0], [1, 1], [1, 2], [1, 3], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8],
      [2, 1], [2, 2], [2, 4], [2, 5], [2, 7], [2, 8],
      [3, 0], [3, 1], [3, 2], [3, 3], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8],
      [4, 0], [4, 1], [4, 3], [4, 4], [4, 6], [4, 7],
      [5, 1], [5, 2], [5, 4], [5, 5], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.TOP_CORNERS,
      currBottom: Color.R
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 5],
      [1, 0], [1, 1], [1, 3], [1, 4], [1, 6], [1, 7],
      [2, 0], [2, 1], [2, 2], [2, 3], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8],
      [3, 1], [3, 2], [3, 4], [3, 5], [3, 7], [3, 8],
      [4, 0], [4, 1], [4, 2], [4, 3], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8],
      [5, 3], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.TOP_CORNERS,
      currBottom: Color.G
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8],
      [1, 3], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8],
      [2, 3], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8],
      [3, 3], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8],
      [4, 3], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8],
      [5, 0], [5, 1], [5, 2], [5, 3], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.TOP_CORNERS,
      currBottom: Color.W
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8],
      [1, 0], [1, 1], [1, 2], [1, 3], [1, 4], [1, 5],
      [2, 0], [2, 1], [2, 2], [2, 3], [2, 4], [2, 5],
      [3, 0], [3, 1], [3, 2], [3, 3], [3, 4], [3, 5],
      [4, 0], [4, 1], [4, 2], [4, 3], [4, 4], [4, 5],
      [5, 1], [5, 3], [5, 4], [5, 5], [5, 7]
    ])) {
    return {
      currStep: LblStep.TOP_EDGE,
      currBottom: Color.Y
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 3], [0, 4], [0, 6], [0, 7],
      [1, 0], [1, 1], [1, 2], [1, 3], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8],
      [2, 0], [2, 1], [2, 3], [2, 4], [2, 6], [2, 7],
      [3, 1], [3, 3], [3, 4], [3, 5], [3, 7],
      [4, 1], [4, 2], [4, 4], [4, 5], [4, 7], [4, 8],
      [5, 0], [5, 1], [5, 3], [5, 4], [5, 6], [5, 7]
    ])) {
    return {
      currStep: LblStep.TOP_EDGE,
      currBottom: Color.O
    };
  } else if (isRight(cube,
    [
      [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8],
      [1, 1], [1, 2], [1, 4], [1, 5], [1, 7], [1, 8],
      [2, 0], [2, 1], [2, 2], [2, 3], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8],
      [3, 0], [3, 1], [3, 3], [3, 4], [3, 6], [3, 7],
      [4, 1], [4, 3], [4, 4], [4, 5], [4, 7],
      [5, 0], [5, 1], [5, 2], [5, 3], [5, 4], [5, 5]
    ])) {
    return {
      currStep: LblStep.TOP_EDGE,
      currBottom: Color.B
    };
  } else if (isRight(cube,
    [
      [0, 1], [0, 2], [0, 4], [0, 5], [0, 7], [0, 8],
      [1, 1], [1, 3], [1, 4], [1, 5], [1, 7],
      [2, 1], [2, 2], [2, 4], [2, 5], [2, 7], [2, 8],
      [3, 0], [3, 1], [3, 2], [3, 3], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8],
      [4, 0], [4, 1], [4, 3], [4, 4], [4, 6], [4, 7],
      [5, 1], [5, 2], [5, 4], [5, 5], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.TOP_EDGE,
      currBottom: Color.R
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 5],
      [1, 0], [1, 1], [1, 3], [1, 4], [1, 6], [1, 7],
      [2, 1], [2, 3], [2, 4], [2, 5], [2, 7],
      [3, 1], [3, 2], [3, 4], [3, 5], [3, 7], [3, 8],
      [4, 0], [4, 1], [4, 2], [4, 3], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8],
      [5, 3], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.TOP_EDGE,
      currBottom: Color.G
    };
  } else if (isRight(cube,
    [
      [0, 1], [0, 3], [0, 4], [0, 5], [0, 7],
      [1, 3], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8],
      [2, 3], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8],
      [3, 3], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8],
      [4, 3], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8],
      [5, 0], [5, 1], [5, 2], [5, 3], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.TOP_EDGE,
      currBottom: Color.W
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8],
      [1, 0], [1, 1], [1, 2], [1, 3], [1, 4], [1, 5],
      [2, 0], [2, 1], [2, 2], [2, 3], [2, 4], [2, 5],
      [3, 0], [3, 1], [3, 2], [3, 3], [3, 4], [3, 5],
      [4, 0], [4, 1], [4, 2], [4, 3], [4, 4], [4, 5]
    ])) {
    return {
      currStep: LblStep.SECOND_LAYER,
      currBottom: Color.Y
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 3], [0, 4], [0, 6], [0, 7],
      [1, 0], [1, 1], [1, 2], [1, 3], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8],
      [2, 0], [2, 1], [2, 3], [2, 4], [2, 6], [2, 7],
      [4, 1], [4, 2], [4, 4], [4, 5], [4, 7], [4, 8],
      [5, 0], [5, 1], [5, 3], [5, 4], [5, 6], [5, 7]
    ])) {
    return {
      currStep: LblStep.SECOND_LAYER,
      currBottom: Color.O
    };
  } else if (isRight(cube,
    [
      [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8],
      [1, 1], [1, 2], [1, 4], [1, 5], [1, 7], [1, 8],
      [2, 0], [2, 1], [2, 2], [2, 3], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8],
      [3, 0], [3, 1], [3, 3], [3, 4], [3, 6], [3, 7],
      [5, 0], [5, 1], [5, 2], [5, 3], [5, 4], [5, 5]
    ])) {
    return {
      currStep: LblStep.SECOND_LAYER,
      currBottom: Color.B
    };
  } else if (isRight(cube,
    [
      [0, 1], [0, 2], [0, 4], [0, 5], [0, 7], [0, 8],
      [2, 1], [2, 2], [2, 4], [2, 5], [2, 7], [2, 8],
      [3, 0], [3, 1], [3, 2], [3, 3], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8],
      [4, 0], [4, 1], [4, 3], [4, 4], [4, 6], [4, 7],
      [5, 1], [5, 2], [5, 4], [5, 5], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.SECOND_LAYER,
      currBottom: Color.R
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 5],
      [1, 0], [1, 1], [1, 3], [1, 4], [1, 6], [1, 7],
      [3, 1], [3, 2], [3, 4], [3, 5], [3, 7], [3, 8],
      [4, 0], [4, 1], [4, 2], [4, 3], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8],
      [5, 3], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.SECOND_LAYER,
      currBottom: Color.G
    };
  } else if (isRight(cube,
    [
      [1, 3], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8],
      [2, 3], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8],
      [3, 3], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8],
      [4, 3], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8],
      [5, 0], [5, 1], [5, 2], [5, 3], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.SECOND_LAYER,
      currBottom: Color.W
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8],
      [1, 0], [1, 1], [1, 2], [1, 4],
      [2, 0], [2, 1], [2, 2], [2, 4],
      [3, 0], [3, 1], [3, 2], [3, 4],
      [4, 0], [4, 1], [4, 2], [4, 4]
    ])) {
    return {
      currStep: LblStep.FIRST_LAYER_CORNERS,
      currBottom: Color.Y
    };
  } else if (isRight(cube, [
    [0, 0], [0, 3], [0, 4], [0, 6],
    [1, 0], [1, 1], [1, 2], [1, 3], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8],
    [2, 0], [2, 3], [2, 4], [2, 6],
    [4, 2], [4, 4], [4, 5], [4, 8],
    [5, 0], [5, 3], [5, 4], [5, 6]])) {
    return {
      currStep: LblStep.FIRST_LAYER_CORNERS,
      currBottom: Color.O
    };
  } else if (isRight(cube,
    [
      [0, 4], [0, 6], [0, 7], [0, 8],
      [1, 2], [1, 4], [1, 5], [1, 8],
      [2, 0], [2, 1], [2, 2], [2, 3], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8],
      [3, 0], [3, 3], [3, 4], [3, 6],
      [5, 0], [5, 1], [5, 2], [5, 4]
    ])) {
    return {
      currStep: LblStep.FIRST_LAYER_CORNERS,
      currBottom: Color.B
    };
  } else if (isRight(cube,
    [
      [0, 2], [0, 4], [0, 5], [0, 8],
      [2, 2], [2, 4], [2, 5], [2, 8],
      [3, 0], [3, 1], [3, 2], [3, 3], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8],
      [4, 0], [4, 3], [4, 4], [4, 6],
      [5, 2], [5, 4], [5, 5], [5, 8]
    ])) {
    return {
      currStep: LblStep.FIRST_LAYER_CORNERS,
      currBottom: Color.R
    };
  } else if (isRight(cube,
    [
      [0, 0], [0, 1], [0, 2], [0, 4],
      [1, 0], [1, 3], [1, 4], [1, 6],
      [3, 2], [3, 4], [3, 5], [3, 8],
      [4, 0], [4, 1], [4, 2], [4, 3], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8],
      [5, 4], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.FIRST_LAYER_CORNERS,
      currBottom: Color.G
    };
  } else if (isRight(cube,
    [
      [1, 4], [1, 6], [1, 7], [1, 8],
      [2, 4], [2, 6], [2, 7], [2, 8],
      [3, 4], [3, 6], [3, 7], [3, 8],
      [4, 4], [4, 6], [4, 7], [4, 8],
      [5, 0], [5, 1], [5, 2], [5, 3], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8]
    ])) {
    return {
      currStep: LblStep.FIRST_LAYER_CORNERS,
      currBottom: Color.W
    };
  } else if (isRight(cube,
    [
      [0, 1], [0, 3], [0, 5], [0, 7],
      [1, 1],
      [2, 1],
      [3, 1],
      [4, 1]
    ])) {
    return {
      currStep: LblStep.FIRST_LAYER_EDGE,
      currBottom: Color.Y
    };
  } else if (isRight(cube,
    [
      [0, 3],
      [1, 1], [1, 3], [1, 5], [1, 7],
      [2, 3],
      [4, 5],
      [5, 3]
    ])) {
    return {
      currStep: LblStep.FIRST_LAYER_EDGE,
      currBottom: Color.O
    };
  } else if (isRight(cube,
    [
      [0, 7],
      [1, 5],
      [2, 1], [2, 3], [2, 5], [2, 7],
      [3, 3],
      [5, 1]
    ])) {
    return {
      currStep: LblStep.FIRST_LAYER_EDGE,
      currBottom: Color.B
    };
  } else if (isRight(cube,
    [
      [0, 5],
      [2, 5],
      [3, 1], [3, 3], [3, 5], [3, 7],
      [4, 3],
      [5, 5]
    ])) {
    return {
      currStep: LblStep.FIRST_LAYER_EDGE,
      currBottom: Color.R
    };
  } else if (isRight(cube,
    [
      [0, 1],
      [1, 3],
      [3, 5],
      [4, 1], [4, 3], [4, 5], [4, 7],
      [5, 7]
    ])) {
    return {
      currStep: LblStep.FIRST_LAYER_EDGE,
      currBottom: Color.G
    };
  } else if (isRight(cube,
    [
      [1, 7],
      [2, 7],
      [3, 7],
      [4, 7],
      [5, 1], [5, 3], [5, 5], [5, 7]
    ])) {
    return {
      currStep: LblStep.FIRST_LAYER_EDGE,
      currBottom: Color.W
    };
  } else {
    return {
      currStep: LblStep.NONE,
      currBottom: NaN
    };
  }
}
// 判断给定的色块颜色是否正确
function isRight(cube: CubeState, pos: number[][]): boolean {
  for (let i = 0; i < pos.length; i++) {
    if (cube[pos[i][0]][pos[i][1]] !== pos[i][0]) {
      return false;
    }
  }
  return true;
}
