
import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { filter, map, tap, pairwise, sampleTime } from 'rxjs/operators';
import { DfuStage } from 'src/libs/nrf-dfu';
import { InspectionStaticItem } from '../class/inspection-static-item';
import { BleService } from './ble.service';
import { BleInspectionItemService } from './ble-inspection-item.service';
import { BleCurrentStateService } from './ble-current-state.service';
import { CubeInformationService } from './cube-information.service';
import { ConnectionService } from './connection.service';
import { GqlCubeService, Oad } from './gql-cube.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as hash from 'hash.js'
import { Subject } from 'rxjs'
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OperatorOadService {

  constructor(
    private bleService: BleService,
    private bleInspectionItemService: BleInspectionItemService,
    private bleCurrentStateService: BleCurrentStateService,
    private cubeInformationService: CubeInformationService,
    private gqlCubeService: GqlCubeService,
    private message: NzMessageService) { }

  public otaing: boolean = false
  public mtu: number = 250
  // public otaProgressMode: '' | 'success'// = 'success'
  public otaProgressValue: number = 0

  public otaingId

  //固件类型
  //TODO: 现在固件类型还没有确定，等确定后需要修改
  public type: string = "1"
  public oadItem: InspectionStaticItem = this.bleInspectionItemService.oadItem
  public lowestAvlOad: Oad
  public latestAvlOad: Oad
  public latestOadVersion: string
  public otaSpeed: number

  public otaspeedSubscription: Subscription
  public otaspeedSubject = new Subject<any>()
  public otaFinishSubject = new Subject<any>()
  public otaingSubject = new Subject<any>()
  public otaingFinSubject = new Subject<any>()

  public timer: NodeJS.Timer
  public seconds: number = 1

  getOtaingFinSubject() {
    return this.otaingFinSubject.asObservable()
  }

  getOtaingSubject() {
    return this.otaingSubject.asObservable()
  }

  getOadFinSubject() {
    return this.otaFinishSubject.asObservable()
  }

  getOadspeSubject() {
    return this.otaspeedSubject.asObservable()
  }

  initTodo() {
    this.bleService.otaProgress$.subscribe(i => {
      console.log(i)
      this.cubeInformationService.getDeviceInfo()
      console.log(this.cubeInformationService.pid)
      // this.otaProgressMode = i.stage === DfuStage.PREPARE ? '' : 'success'
      this.otaProgressValue = Math.floor(i.sendBytes / i.totalBytes * 100)
      this.otaspeedSubject.next(true)
    })
    this.bleService.otaProgress$.pipe(
      map(i => i.sendBytes),
      sampleTime(100),
      pairwise(),
      map(i => i[1] - i[0]),
      filter(i => i >= 0)
    ).subscribe(i => {
      this.otaSpeed = i / 1024 * 10
      console.log('ota速度', this.otaSpeed)
      this.otaspeedSubject.next(true)
    })
  }


  public async inspect() {
    this.oadItem.isInspecting = true
    let { major, minor, patch } = {
      major: this.bleCurrentStateService.major,
      minor: this.bleCurrentStateService.minor,
      patch: this.bleCurrentStateService.patch,
    }
    let currOad = await this.gqlCubeService.getOadByVersionGQL({ type: this.type, major: major, minor: minor, patch: patch })
    this.lowestAvlOad = await this.gqlCubeService.getLowestAvlOadGQL(major, this.type)
    this.latestAvlOad = await this.gqlCubeService.getLatestAvlOadGQL(major, this.type)
    console.log('hashcode in inspect() after getLatestAvlOadGQL(): ' + this.latestAvlOad.hashCode)

    //当前版本，最低可用版本和最新版本的OAD版本号
    this.oadItem.currentState = currOad ? `${major}.${minor}.${patch}` : "当前版本不存在!"
    this.oadItem.validState = this.lowestAvlOad ? `${this.lowestAvlOad.major}.${this.lowestAvlOad.minor}.${this.lowestAvlOad.patch}` : "最低可用版本不存在！"
    this.latestOadVersion = this.latestAvlOad ? `${this.latestAvlOad.major}.${this.latestAvlOad.minor}.${this.latestAvlOad.patch}` : "无最新可用版本！"
    console.log(currOad, this.lowestAvlOad, this.latestAvlOad);

    //检查OAD版本是否符合要求
    const { result, description } = this.inspectOad(currOad, this.lowestAvlOad, this.latestAvlOad)
    if (result) this.otaProgressValue = 100
    console.log('检查oad结果', result, description)
    this.oadItem.isInspected = true
    this.oadItem.isInspecting = false
    this.oadItem.inspectionResult = result
    this.oadItem.description = description
    this.otaspeedSubject.next(true)
    this.otaFinishSubject.next()
  }
  public inspectOad(currOad: Oad, lowestAvlOad: Oad, latestAvlOad: Oad) {
    if (!currOad) return { result: false, description: "不存在当前版本！" }
    if (!lowestAvlOad) return { result: false, description: "不存在最低可用版本！" }

    if (this.isEqualToNewest(currOad, latestAvlOad)) {
      return { result: true, description: "当前OAD版本是最新版本" }
    } else return { result: false, description: "当前版本过低！" }
  }
  //检查当前版本是否高于最低可用版本
  public isEqualToLatest(currOad: Oad, lowestAvlOad: Oad): boolean {
    return currOad.major === lowestAvlOad.major &&
      (currOad.minor > lowestAvlOad.minor ||
        (currOad.minor === lowestAvlOad.minor && currOad.patch >= lowestAvlOad.patch))
  }
  //检查当前版本是否等于最新版本
  public isEqualToNewest(currOad: Oad, latest: Oad): boolean {
    return currOad.major === latest.major && currOad.minor === latest.minor && currOad.patch === latest.patch
  }
  public clearInspectionItem() {
    this.bleInspectionItemService.oadItem.isInspected = false
    this.bleInspectionItemService.oadItem.inspectionResult = null
    this.bleInspectionItemService.oadItem.description = null
    this.bleInspectionItemService.oadItem.currentState = null
    this.bleInspectionItemService.oadItem.validState = null
    this.otaProgressValue = 0
    this.otaing = false
    this.latestOadVersion = null
    this.otaspeedSubject.next(true)
    this.otaFinishSubject.next()
  }


  //检查OAD固件的哈希编码与数据库是否一致
  public checkHashCode(fileBuff: ArrayBuffer, hashCode: string): boolean {
    var currhashCode = hash.sha256().update(fileBuff).digest('hex')
    console.log('currhashcode = ' + currhashCode)
    console.log('hashCode = ' + hashCode)
    if (currhashCode == hashCode) return true
    else return false
  }

  private getFileBuffer(file: Blob) {
    return new Promise<Buffer>((resolve, reject) => {
      const reader = new FileReader()

      function onLoadEnd(e) {
        if (reader.removeEventListener) {
          reader.removeEventListener('loadend', onLoadEnd, false)
        } else {
          reader.onloadend = null
        }
        if (e.error) reject(e.error)
        else resolve(Buffer.from(reader.result as ArrayBuffer))
      }
      if (reader.addEventListener) {
        reader.addEventListener('loadend', onLoadEnd, false)
      } else {
        reader.onloadend = onLoadEnd
      }
      reader.readAsArrayBuffer(file)
    })
  }

  public async startOTA(file) {
    let fileBuff
    try {
      if (file.size > 100000) {  //文件不超过100k
        return this.message.error('文件太大，请重新选择！')
      }
      fileBuff = await this.getFileBuffer(file)
    } catch (err) {
      return this.message.error('请先选择OTA文件')
    }
    try {
      let hashCodeCheckRes = this.checkHashCode(fileBuff, this.latestAvlOad.hashCode)
      if (!hashCodeCheckRes) return this.message.error('文件损坏，请重新下载！')
    } catch (err) {
      return this.message.error('检测到该文件不可用或不是最新可用版本，无法更新！')
    }
    // const { result, description } = this.inspectOad(this.latestAvlOad, this.lowestAvlOad)

    if (!this.oadItem.inspectionResult) {
      try {
        this.otaing = true
        this.otaingSubject.next(true)
      } catch (err) {
        console.log(err)
        console.log(err.name)
      }
      try {
        await this.bleService.ota(fileBuff, +this.mtu, 20)
        this.message.success('OTA 成功')
        this.otaingFinSubject.next(true);
      } catch (err) {
        console.log(err)
        this.message.error(`OTA 失败，请重试，error: ${err.toString()}`)
        this.otaingFinSubject.next(false);
      } finally {
        // this.otaProgressMode = 'success'
        this.otaProgressValue = 0
        this.otaing = false
        //await this.reconnect()
      }
    } else {
      return this.message.error(this.oadItem.description)
    }
  }

  private ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
  }
  private str2ab(str) {
    var buf = new ArrayBuffer(str.length); // 2 bytes for each char
    var bufView = new Uint8Array(buf);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  public async downloadOAD() {
    try {
      //window.open(this.latestAvlOad.path)
      console.log(this.latestAvlOad.path);
      let key = 'oad-' + this.latestOadVersion
      if (!localStorage.getItem(key)) {
        let load = this.message.loading("正在下载 OAD 固件...").messageId
        fetch(this.latestAvlOad.path)
          .then(function (response) {
            return response.blob();
          })
          .then(async (data) => {
            console.log(data);
            this.getFileBuffer(data).then((ab) => {
              console.log(ab);
              localStorage.setItem('oad-' + this.latestOadVersion, this.ab2str(ab));
            })
            this.message.remove(load);
            await this.startOTA(data);
          });
      } else {
        await this.loadAndStart(key)
      }
    } catch (err) {
      console.log(err)
    }
  }

  async loadAndStart(key) {
    let ab = this.str2ab(localStorage.getItem(key));
    console.log(ab)
    let blob = new Blob([this.str2ab(localStorage.getItem('oad-' + this.latestOadVersion))], {
      type: 'application/zip'
    });
    console.info(blob)
    await this.startOTA(blob);
  }

  destroyTodo() {
    if (this.otaspeedSubscription)
      this.otaspeedSubscription.unsubscribe()
  }

}
