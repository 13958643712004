import { Component, OnInit, Inject } from '@angular/core';
import { AppComponent } from '../../app.component'
import { SignUpService } from '../../services/sign-up.service'
import { Subscription } from 'rxjs';
import { Storage } from '@ionic/storage'
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  public userName: string=''
  public password: string=''
  public isSigning:boolean=false
  constructor(
    public signUpService: SignUpService,
    public storage: Storage,
    private message: NzMessageService,
  ) { }

  async ngOnInit() {
    const un = await this.storage.get('username');
    if (un != null) {
        this.userName = un;
    }
    const pwd = await this.storage.get('password')
    if (pwd != null) {
      this.password = pwd;
    }
    this.signUpService.isSignInedSubscription = this.signUpService.getisSignInedSubject().subscribe((val) => {
      if (val) {
        this.signUpService.isSignIned=true
        this.signUpService.isSigningVisible=false
      }
    });

  }
  
  async signUp() {
    this.isSigning=true;
    await this.storage.set('username', this.userName);
    await this.storage.set('password', this.password);
    // this.message.loading("登录中...")
    await this.signUpService.signUp(this.userName, this.password);
    this.signUpService.isSigningVisible=false
    this.isSigning=false
  }
  cancel(){
    this.signUpService.isSigningVisible=false
  }

  ngOnDestroy() {
    this.signUpService.destroyTodo()
  }

}
