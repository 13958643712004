import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { InspectionDynamicItem } from '../../class/inspection-dynamic-item';
import { InspRes } from '../../services/ble-inspection-item.service';
import { OperatorAxisService } from '../../services/operator-axis.service'
export type CODERMODE = 'period' | 'integrate'

enum Clazz {
  YELLOW = 0,
  ORANGE = 1,
  BLUE = 2,
  RED = 3,
  GREEN = 4,
  WHITE = 5
}

@Component({
  selector: 'app-axis-item-inspection',
  templateUrl: './axis-item-inspection.component.html',
  styleUrls: ['./axis-item-inspection.component.scss']
})
export class AxisItemInspectionComponent implements OnInit, OnDestroy {

  constructor(
    public operatorAxisService: OperatorAxisService,
  ) { }
  Clazz: typeof Clazz = Clazz;
  axisColor: string[] = ["yellow", "orange", "blue", "red", "green", "gray"];
  public faceItems: Array<InspectionDynamicItem> = new Array<InspectionDynamicItem>()
  public clockPercent = 0;
  public clockwisePercent = 0;
  private readySubscription: Subscription
  @Input() public coderMode: CODERMODE

  ngOnInit() {
    (window as any).axis=this.operatorAxisService
    this.readySubscription = this.operatorAxisService.getReadySubject().subscribe((val) => {
      if (val) {
        console.log('初始化轴检测')
        this.operatorAxisService.initTodo(this.coderMode)
        this.faceItems = this.operatorAxisService.faceItems
      } else {
        this.operatorAxisService.clearInspectionItem()
      }
    });
    (window as any).axis=this.operatorAxisService
    // if(this.isInit)
    // this.operatorAxisService.initTodo(this.coderMode)
  }


  public format(percent: number) {
    return Math.round(percent) + '%';
  }

  //重新检查某一个轴
  public reInspectItem(face: number) {
    this.operatorAxisService.reinspectItem(face);
  }

  ngOnDestroy() {
    this.operatorAxisService.destroyTodo()
    if (this.readySubscription) this.readySubscription.unsubscribe()
  }

}
