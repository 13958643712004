import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { InspectionBaseItem } from 'src/app/class/inspection-base-item';
import { Oad } from 'src/app/services/gql-cube.service';
import { OperatorOadService } from 'src/app/services/operator-oad.service';

@Component({
  selector: 'app-oad-item-inspection',
  templateUrl: './oad-item-inspection.component.html',
  styleUrls: ['./oad-item-inspection.component.less']
})
export class OadItemInspectionComponent implements OnInit {
  constructor(
    private operatorOadService: OperatorOadService
  ) { }

  @ViewChild('uploadInput', { static: false })
  private otaFileInput: ElementRef<HTMLInputElement>

  //oad相关
  public otaProgressMode: '' | 'success'// = 'success'
  public otaProgressValue: number = 0
  public mtu: number = 20
  public otaing: boolean = false
  public otaSpeed: number
  public oadResult: boolean = false

  //oad检查项
  public oadItem: InspectionBaseItem

  //固件类型
  //TODO: 现在固件类型还没有确定，等确定后需要修改
  public type: string = "1"

  public lowestAvlOad: Oad
  public latestAvlOad: Oad
  public latestOadVersion: string
  public autoUpgrading: boolean = false;

  public otaSpeedSubscription: Subscription
  public otaFinishSubscription: Subscription
  public otaingSubscription: Subscription

  ngOnInit() {
    this.otaSpeedSubscription = this.operatorOadService.getOadspeSubject().subscribe((val) => {
      this.getServiceData()
    })
    this.otaFinishSubscription = this.operatorOadService.getOadFinSubject().subscribe(async () => {
      this.getServiceData()
      console.log('oad完成检测订阅：', this.oadItem)
      if (!this.autoUpgrading && this.oadItem.inspectionResult === false) {
        await this.downloadOAD()
      }
    })
    this.otaingSubscription = this.operatorOadService.getOtaingFinSubject().subscribe((state) => {
      console.log('otaingSubscription')
      if (state !== null) {
        this.oadResult = state;
        console.log('检测结果', state)
      }
    })
    this.initInspectionItem()
    // this.oadItem.inspectionResult = null;
    this.operatorOadService.initTodo()
  }
  //初始化待检测项目信息
  public initInspectionItem() {
    this.oadItem = this.operatorOadService.oadItem
  }

  getServiceData() {
    this.oadItem = this.operatorOadService.oadItem
    this.mtu = this.operatorOadService.mtu
    this.otaing = this.operatorOadService.otaing
    this.otaProgressValue = this.operatorOadService.otaProgressValue
    // this.otaProgressMode = this.operatorOadService.otaProgressMode
    this.otaSpeed = this.operatorOadService.otaSpeed
    this.latestOadVersion = this.operatorOadService.latestOadVersion
  }

  public async startOTA() {
    let file = this.otaFileInput.nativeElement.files[0]
    console.log(file)
    await this.operatorOadService.startOTA(file)
    this.getServiceData()
  }

  public async downloadOAD() {
    this.autoUpgrading = true;
    console.log('开始升级oad')
    await this.operatorOadService.downloadOAD()
    this.getServiceData()
    console.log('结束升级oad')
    this.autoUpgrading = false;
  }

  ngOnDestroy() {
    this.operatorOadService.destroyTodo()
    this.otaSpeedSubscription.unsubscribe()
    this.otaFinishSubscription.unsubscribe()
  }

}
