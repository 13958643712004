<app-cube-container class="cube"></app-cube-container>
<nz-card class="card-wrapper axis-card-wrapper" nzTitle="9轴传感器检测" style="height: 540px;">
  <div class="dynamic-inspection-main">
    <div class="cube-face-group">
      <div class="face" *ngFor="let faceItem of faceItems">
        <div class="cube-face yellow" [style.opacity]="faceItem.isInspecting? 1: 0.6"
          [class.yellow]="faceItem.itemId===Clazz.YELLOW" [class.orange]="faceItem.itemId===Clazz.ORANGE"
          [class.blue]="faceItem.itemId===Clazz.BLUE" [class.red]="faceItem.itemId===Clazz.RED"
          [class.green]="faceItem.itemId===Clazz.GREEN" [class.white]="faceItem.itemId===Clazz.WHITE">
        </div>
        <span class="progress-wrapper"><span [class.text-yellow]="faceItem.itemId===Clazz.YELLOW"
            [class.text-orange]="faceItem.itemId===Clazz.ORANGE" [class.text-blue]="faceItem.itemId===Clazz.BLUE"
            [class.text-red]="faceItem.itemId===Clazz.RED" [class.text-green]="faceItem.itemId===Clazz.GREEN"
            [class.text-white]="faceItem.itemId===Clazz.WHITE" class="text">顺时针:</span> <span>
            <nz-progress
              [nzPercent]="(faceItem.ClockwiseAngle <= operatorAxisService.standardAngle ? faceItem.ClockwiseAngle : operatorAxisService.standardAngle) * 100 / operatorAxisService.standardAngle"
              [nzStrokeWidth]="14" [nzFormat]="format" [nzStrokeColor]="axisColor[faceItem.itemId]" class="text">
            </nz-progress>
          </span></span>
        <span class="progress-wrapper"> <span [class.text-yellow]="faceItem.itemId===Clazz.YELLOW"
            [class.text-orange]="faceItem.itemId===Clazz.ORANGE" [class.text-blue]="faceItem.itemId===Clazz.BLUE"
            [class.text-red]="faceItem.itemId===Clazz.RED" [class.text-green]="faceItem.itemId===Clazz.GREEN"
            [class.text-white]="faceItem.itemId===Clazz.WHITE" class="text">逆时针:</span> <span>
            <nz-progress
              [nzPercent]="(faceItem.CounterclockwiseAngle <= operatorAxisService.standardAngle ? faceItem.CounterclockwiseAngle : operatorAxisService.standardAngle) * 100 / operatorAxisService.standardAngle"
              [nzStrokeWidth]="14" [nzFormat]="format" [nzStrokeColor]="axisColor[faceItem.itemId]" class="text">
            </nz-progress>
          </span></span>
        <span [class.notChecked]="!faceItem.isInspected" [class.qualified]="faceItem.inspectionResult===true"
          [class.failed]="faceItem.inspectionResult===false" class="text">
          {{faceItem.isInspected? (faceItem.inspectionResult===true? "已合格" : faceItem.inspectionResult===false? "不合格" :
          "未检查") : "未检查"}}
          <span nz-tooltip nzTooltipTitle="{{faceItem.description}}"><i nz-icon nzType="info-circle"
              nzTheme="outline"></i></span>
        </span>
        <span (click)="reInspectItem(faceItem.itemId)" class="respect-btn">重新检查</span>
      </div>
    </div>
    <div class="end-line">
      <button nz-button nzType="primary" (click)="operatorAxisService.reinspectAll()" >重新检查</button>
    </div>
  </div>
</nz-card>