import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FetchResult, ApolloQueryResult } from 'apollo-boost';
import { Mutation, LoginByPwdInput, LoginVo, Query } from 'src/generated/graphql';
import { USER_LOGIN_BY_PWD, GET_USER_ROLE } from '../api-graphQL/authority.gql';
import { map, tap, take } from 'rxjs/operators';
import { ApolloService } from './apollo.service';
import { Result, getResult } from './exception';


@Injectable({
  providedIn: 'root'
})
export class GqlUserService {
  constructor(
    private apollo: Apollo,
    private apolloService: ApolloService
  ) {
  }

  // 单纯用于登录，获取登录结果
  async loginByPwd(phone, password): Promise<Result<LoginVo>> {
    const input: LoginByPwdInput = { phone, password }
    return getResult(this.apollo.mutate<Mutation>({
      mutation: USER_LOGIN_BY_PWD,
      variables: {
        byPwd: input,
      },
    }).pipe(
      // 对获取的结果进行一次Map
      map<FetchResult<Mutation>, LoginVo>(
        ({ data }) => {
          return data.userLogin;

        },
      ),
      // tap运算符是置入的一些额外操作，当登录成功时，重设apollo，将带有认证信息的auth信息放入报文头
      tap(async res => {
        if (res) {
          await this.apolloService.resetApollo(res.authToken);
        }
      }),
    ).toPromise()
    )
  }

  public getUserRole(userId: number): Promise<string> {
    return this.apollo.watchQuery<Query>({
      query: GET_USER_ROLE,
      variables: {
        userId,
      },
      fetchPolicy: 'network-only'
    }).valueChanges.pipe(
      map<ApolloQueryResult<Query>, string>(res => res.data.user.role),
      take(1),
    ).toPromise()
  }

}
