import { Component, OnInit } from '@angular/core';
import { BleService } from 'src/app/services/ble.service';
import { BleStateService, BleDeviceInfo } from 'src/app/services/ble-state.service';
import { Debug } from 'src/libs/debug';
import { LabelService } from './services/label.service'
import { ConnectionService } from './services/connection.service';
import { bufferToHex } from 'src/libs/utils';
import { BleCommandService } from './services/ble-command.service';
import { CubeRotateService } from './services/cube-rotate.service';
import { BleInspectionItemService } from './services/ble-inspection-item.service';
import { SignUpService, WORKPOSITION } from './services/sign-up.service';
import { WorkPositionService } from './services/work-position.service';
import { OperatorAttitudeService } from './services/operator-attitude.service';
import { ORIGIN_CUBE, ResultService } from './services/result.service';
import { CubeNotPassReason, Info } from 'src/generated/graphql';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { OperatorAxisService } from './services/operator-axis.service';
import { CubeStateService } from './services/cube-state.service';
type WORKMODE = 'flow' | 'alone'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  public platform: WORKMODE = 'alone'
  public isUploading: boolean = false
  public user: WORKPOSITION = this.signUpService.workPosition
  public cubeId: string = ''
  public connected: boolean = false
  public enableDebug: boolean = true
  public isSigningIn: boolean = false
  private info: Info
  public spinTip: string = ''
  constructor(
    public bleService: BleService,
    public labelService: LabelService,
    private connectionService: ConnectionService,
    public bleCommandService: BleCommandService,
    private cubeRotateService: CubeRotateService,
    private bleInspectionItemService: BleInspectionItemService,
    public signUpService: SignUpService,
    public workPositionService: WorkPositionService,
    private bleStateService: BleStateService,
    public operatorAttitudeService: OperatorAttitudeService,
    public resultService: ResultService,
    private message: NzMessageService,
    private notification: NzNotificationService,
    private operatorAxisService: OperatorAxisService,
    private cubeStateService:CubeStateService,
  ) { }
  ngOnInit() {
    this.bleStateService.ready$.subscribe()
    this.connectionService.disconnect$.subscribe((state) => {
      if (state) {
        console.log('断开连接')
        this.notification.create(
          'warning',
          '蓝牙断开',
          '您的魔方蓝牙已经断开连接，请重新连接'
        );
        this.workPositionService.clearAll()
      }
    })
    this.resultService.getFinishSubject().subscribe(() => {
      console.log('上传后清空状态')
      this.workPositionService.clearAll()
    })
    //魔方状态不一致检测
    this.bleInspectionItemService.inspectInfoItem$.subscribe(info => {
      console.log('订阅检测项', info)
      // if (info.reason.includes(CubeNotPassReason.Disaccord)) {
        // this.resultService.upLoadUMR(false, info);
      // }
      this.info = info;
    })
    //意外断开连接检测
    this.resultService.initTodo()
    //根据info设置检测结果
    this.bleInspectionItemService.stepInspectItem$.subscribe(curr => {
      this.resultService.checkResult(this.info);
    })
  }

  public async onScanBtnClick() {
    let device: BleDeviceInfo;
    try {
      device = await (await this.bleService.startScan(this.namePrefix)).toPromise();
      console.log('设备信息', device)
    } catch (err) {
      return;
    } finally {
      await this.bleService.stopScan();
    }

    try {
      await this.connect(device.id)
      this.connected = true;
    } catch (err) {
      alert(`连接失败！请重试！原因:${err}`);
      this.connected = false;
      return;
    }
    Debug.info(`连接成功`);
    this.bleService.command$.subscribe(i => {
    }, err => {
      Debug.info(`蓝牙设备断开连接!`);
      this.connected = false
    });
    this.cubeId = device.name.replace('MHC', '').replace('-', '')
    if (this.signUpService.workPosition === 'first') await this.labelService.genLable(this.cubeId.split(' ').reverse().join('').toUpperCase())
  }
  public async connect(id) {
    this.bleInspectionItemService.clearInfoItem();
    this.operatorAxisService.readySubject.next(true)
    await this.connectionService.connect(id);
    await this.cubeRotateService.cube.resetCubeState(this.cubeRotateService.cube.originCube);
    //开始检测各项目，姿态和轴需要单独开启检测
    this.workPositionService.initTodo()
    this.operatorAttitudeService.readySubject.next(true)
  }
  public async onDisconnect() {
    try {
      await this.connectionService.disconnect()
      this.connected = false
      this.cubeId = ''
    } catch (e) {
    }

  }
  public get namePrefix() {
    return 'MHC-' + this.cubeId
  }
  public async changeWorkService() {
    await this.connectionService.disconnect()
    this.signUpService.workPosition = this.user
  }
  //流水线改变工作位
  public async changeWorkPos(position: WORKPOSITION) {
    if (this.connected) {
      await this.onInstallFinish()
    }
    this.signUpService.workPosition = position
    if(this.connected){
      if(position==='third'){
        await this.bleCommandService.setCubeState({
          state: ORIGIN_CUBE,
          pause: [0, 0, 0, 0, 0, 0]
        })
        this.cubeRotateService.resetCube()
      }
      this.bleInspectionItemService.setInfoItem('connect', '')
      //2秒后开始检测
      this.spinTip = '2s后开始检测'
      this.isUploading = true;
      await this.sleep(2000)
      this.isUploading = false;
      if(this.signUpService.workPosition==='first'&&this.labelService.isIframeBlank()) this.labelService.genLable(this.cubeId.split(' ').reverse().join('').toUpperCase())
      this.workPositionService.initTodo()
      this.operatorAxisService.readySubject.next(true)
      this.bleStateService.ready$.next(true)
      this.operatorAttitudeService.readySubject.next(true)
    }

  }
  public async onInstallFinish(isDisconnect: boolean = false) {
    try {
      this.spinTip = '上传数据中'
      this.isUploading = true;
      console.log('开始上传', this.info,isDisconnect)
      await this.resultService.endCheck(this.info)
      if (isDisconnect){
        await this.connectionService.disconnect()
        this.cubeId=''
      }
      this.isUploading = false;
    } catch (e) {
      this.message.create('error', `上传数据发生意外，请重新连接检测`)
    }
  }
  public print(){
    this.labelService.print()
  }
  public async signOut() {
    this.signUpService.signOut();
    await this.connectionService.disconnect();
  }
  private async sleep(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    })
  }
}
