import gql from 'graphql-tag';

// export const CUBE_CHECK = gql`
// mutation($input:CubeCheckInput!){
//     cubeCheck(input:$input)
// }
// `;
export const CUBE_CHECK = gql`
mutation($cubeCheckInput:CubeCheckInput!){
    cubeCheck(cubeCheckInput:$cubeCheckInput)
}
`;
