import gql from 'graphql-tag';

export const USER_LOGIN_BY_PWD = gql`
mutation($byPwd:LoginByPwdInput!){
  userLogin(byPwd:$byPwd){
    authToken,
    user{
      userInfo{
        id,
        phone,
        nickName,
        loginName,
        sex,
        email,
        qq,
        weChat,
        phone,
        photo,
        profile,
        followersCount,
        followsCount,
        msgSet,
        pkSet,
        score,
        rankInfo{
          bestScore
        }
        method,
        reduction,
        gameRoomId,
        createdAt,
        updatedAt,
        isDeleted,
        isBanned
      },
    },
  }
}
`

export const GET_CONNECTION = gql`
query{
  connection
}
`
export const GET_USER_ROLE=gql`
query($userId:Int!){
  user(userId:$userId){
    role
  }

}`
