import { Injectable } from '@angular/core';
import { BleInspectionItemService, } from './ble-inspection-item.service';
import { Subject } from 'rxjs';
import { BleStateService } from './ble-state.service';
import { CubeRotateService } from './cube-rotate.service';
import { Subscription } from 'rxjs';
import { InspectionAxisItem } from '../class/inspection-axis-item';
import { CubeNotPassReason } from 'src/generated/graphql';

@Injectable({
    providedIn: 'root'
})
export class OperatorAxisSignalService {
    constructor(
        private bleInspectionItemService: BleInspectionItemService,
        private bleStateService: BleStateService,
        private cubeRotateService: CubeRotateService,

    ) {
    }
    public faceItems: Array<InspectionAxisItem> = new Array<InspectionAxisItem>();

    //是否为主动断开
    public subjectiveDisconnect = false;

    //滤波器参数
    public rotateParams$Subscription: Subscription;
    public readySubject = new Subject<any>();
    public readySubscription: Subscription;
    public disconnectSubscription: Subject<void> = new Subject<void>();

    getReadySubject() {
        return this.readySubject.asObservable();
    }

    initTodo() {
        this.initInspectionItem();  //初始化6个侧面轴检查项
        this.startRotateCheck()
        this.bleStateService.ready$.subscribe((ready: boolean) => {
            if (!ready) {  //若断开连接，清空检查项的内容
                this.clearInspectionItem();
                this.cubeRotateService.resetCube();
            }
        });
    }
    //开启旋转订阅
    public startRotateCheck() {
        this.clearInspectionItem();
        if (!this.rotateParams$Subscription) {
            this.rotateParams$Subscription = this.cubeRotateService.rotate$.subscribe(rotateParams => {
                this.inspect(rotateParams.move.axis, rotateParams.raw.circle);
            });
        }
    }
    //取消旋转订阅
    public endRotateCheck() {
        if (this.rotateParams$Subscription) {
            this.rotateParams$Subscription.unsubscribe();
            // this.readySubscription=null;
        }
    }
    //初始化待检测项目信息
    public initInspectionItem() {
        this.faceItems = [
            this.bleInspectionItemService.faceItem0,
            this.bleInspectionItemService.faceItem1,
            this.bleInspectionItemService.faceItem2,
            this.bleInspectionItemService.faceItem3,
            this.bleInspectionItemService.faceItem4,
            this.bleInspectionItemService.faceItem5
        ];
        this.readySubject.next(false);
    }

    public clearInspectionItem() {
        for (let i = 0; i < 6; i++) {
            this.faceItems[i].isClockwiseSignalWell = null;
            this.faceItems[i].isCounterSignalWell = null;
        }
        this.readySubject.next(null);
    }

    public async inspect(currRotateFace: number, currRotateCircle: number) {
        let finInspected = new Array(6).fill(false);
        if (currRotateCircle > 0) {
            this.faceItems[currRotateFace].isClockwiseSignalWell = true;
        } else if (currRotateCircle < 0) {
            this.faceItems[currRotateFace].isCounterSignalWell = true;
        }
        this.faceItems.forEach((item, i) => {
            if (item.isClockwiseSignalWell && item.isCounterSignalWell) finInspected[i] = true;
        });
        //如果六个面信号都良好，发布通知
        if (finInspected.every(value => value === true)) {
            this.readySubject.next(true);
        }
    }


    destroyTodo() {
        if (this.rotateParams$Subscription) {
            this.rotateParams$Subscription.unsubscribe();
        }
        if (this.readySubscription) {
            this.readySubscription.unsubscribe();
        }
    }
}
