import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Assets } from './libs/3d-cube/Assets';

if (environment.production) {
  enableProdMode();
}
async function main() {
  (window as any).assets = await Assets.LoadDDAllAssets()
  platformBrowserDynamic().bootstrapModule(AppModule)
}
main().catch(err => console.error(err));
