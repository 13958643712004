import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import {  OperatorAxisSignalService } from '../../services/operator-axis-signal.service'
import { InspectionDynamicItem } from 'src/app/class/inspection-dynamic-item';

@Component({
  selector: 'app-axis-signal-inspection',
  templateUrl: './axis-signal-inspection.component.html',
  styleUrls: ['./axis-signal-inspection.component.scss']
})

export class AxisSignalInspectionComponent implements OnInit {
  constructor(
    public axisSignalService: OperatorAxisSignalService,
  ) { }
  public checkResult: boolean = null
  public faceItems: Array<InspectionDynamicItem> = new Array<InspectionDynamicItem>()

  ngOnInit() {
    this.axisSignalService.readySubscription = this.axisSignalService.getReadySubject().subscribe((val) => {
      this.faceItems = this.axisSignalService.faceItems
      this.checkResult=val
    })

    this.axisSignalService.initTodo()
  }

}