import { Injectable } from '@angular/core';
import { InspectionStaticItem } from '../class/inspection-static-item';
import { BleInspectionItemService } from './ble-inspection-item.service';


@Injectable({
  providedIn: 'root'
})
export class OperatorFilterService {

  constructor(private bleInspectionItemService: BleInspectionItemService) { }
  //初始化滤波算法检查项
  public filterItem: InspectionStaticItem=this.bleInspectionItemService.filterItem

  public async inspect() {
    this.filterItem.isInspecting = true
    const { result, description } = await this.inspectFilter()
    this.filterItem.isInspected = true
    this.filterItem.isInspecting = false
    this.filterItem.inspectionResult = result
    this.filterItem.description = description
  }
  public inspectFilter(){
    //TODO: 现在还没有滤波算法
    let result = true
    let description = "合格，已初始化滤波算法！"
    return ({result, description})
  }
  public clearInspectionItem() {
    this.bleInspectionItemService.filterItem.isInspected = false
    this.bleInspectionItemService.filterItem.inspectionResult = null
    this.bleInspectionItemService.filterItem.description = null
    this.filterItem=this.bleInspectionItemService.filterItem
  } 
}
