import { Injectable } from '@angular/core';
import { BleCommandService } from './ble-command.service';
const PIN='313021200100515041401110'//暂定使用pinmap
@Injectable({
  providedIn: 'root'
})
export class OperatorPinmapService {

  private plusePerLogicRotate: number;
  private rotatePerRound: number;
  private adsorbentPulse: number;
  private coderErrorCount: number;
  private axisInterfereCount: number;
  private pinMap: string;
  private pinMapFin: number[][];
  private moveCount: number;
  public pinResult:boolean=null;

  constructor(
    private bleCommandService: BleCommandService,
  ) { }

  private init() {
    // this.pinMap = "313021200100515041401110";
    if(this.pinMap){
      const tempPinMap = this.pinMap.trim().split('');
      this.pinMapFin = tempPinMap.map((item, index) =>
        index * 2 < tempPinMap.length ? [+tempPinMap[index * 2], +tempPinMap[index * 2 + 1]] : [NaN, NaN]
      ).slice(0, tempPinMap.length / 2);
    }
    
  }

  private async getCoderFilterParam() {
    const {
      plusePerLogicRotate,
      rotatePerRound,
      adsorbentPulse,
      coderErrorCount,
      axisInterfereCount,
      pinMap,
      moveCount
    } = await this.bleCommandService.getCoderFilterParam();
    this.plusePerLogicRotate = plusePerLogicRotate;
    this.rotatePerRound = rotatePerRound;
    this.adsorbentPulse = adsorbentPulse;
    this.coderErrorCount = coderErrorCount;
    this.axisInterfereCount = axisInterfereCount;
    this.moveCount = moveCount;
    console.log(pinMap)
    this.pinMap=pinMap.join('').replace(/,/g,'')
    console.log(this.pinMap)

  }

  private async setCoderFilterParam() {
    await this.bleCommandService.setCoderFilterParam({
      plusePerLogicRotate: this.plusePerLogicRotate,
      rotatePerRound: this.rotatePerRound,
      adsorbentPulse: this.adsorbentPulse,
      coderErrorCount: this.coderErrorCount,
      axisInterfereCount: this.axisInterfereCount,
      pinMap: this.pinMapFin,
      moveCount: this.moveCount,
    });
  }

  public get pinMap$(){
    return this.pinMap
  }

  public async inspect() {
    this.init();
    await this.getCoderFilterParam();
    if(this.pinMap===PIN) this.pinResult=true;
    else this.pinResult=false;
    // await this.setCoderFilterParam();
  }
  public clearInspectionItem(){
    this.pinMap=''
  }
}
